import { ColumnDef } from '@tanstack/react-table';
import { Eye } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBaseCategories } from 'src/app/_api_adb2c/product/category/hooks/use-base-categories';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { ManifestItemModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import { VirtualTable } from 'src/app/components-v2/virtual-table';
import { Button } from 'src/components/ui/button';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';

interface Props {
    purchaseId: string;
}

export function PurchaseManifest({ purchaseId }: Props) {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const navigate = useNavigate();

    const { data: purchase } = usePurchase(purchaseId, delegateId);
    const { data: categories } = useBaseCategories();

    const memoizedItems = useMemo<ManifestItemModel[]>(() => {
        const version = purchase?.versions[purchase.versions.length - 1];

        if (!version) return [];

        return version.manifest.map((x) => {
            let categoryName = x.product?.category?.name;

            if (!categoryName) {
                const category = categories?.find(
                    (y) => y.code === x.product?.category?.code
                );

                categoryName = category?.name.locales[0].text || '';
            }

            return {
                ...x,
                product: {
                    ...x.product,
                    category: {
                        ...x?.product?.category,
                        name: categoryName,
                    },
                },
            };
        });
    }, [purchase, categories]);

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            if (delegateId) {
                navigate({
                    pathname: `/products/${id}`,
                    search: `?delegateId=${delegateId}`,
                });

                return;
            }

            navigate(`/products/${id}`);
        },
        [delegateId, navigate]
    );

    const columns: ColumnDef<ManifestItemModel>[] = useMemo(() => {
        const columns: ColumnDef<ManifestItemModel>[] = [
            {
                id: 'name',
                header: 'Name',
                accessorFn: (row) => row.product.name,
            },
            {
                id: 'itemNo',
                header: 'Item No',
                accessorFn: (row) => {
                    const itemNo = row.product.externalReferences?.find(
                        (x) => x.source === 'externalDataId'
                    )?.value;

                    return itemNo || '--';
                },
            },
            {
                id: 'code',
                header: 'Code',
                accessorFn: (row) => {
                    const customerItemNo = row.product.externalReferences?.find(
                        (x) => x.source === 'customer'
                    )?.value;

                    return customerItemNo || '--';
                },
            },
            {
                id: 'category',
                header: 'Category',
                accessorFn: (row) => row.product.category.name,
                cell: ({ row }) => {
                    return row.original.product.category.name;
                },
            },
            {
                id: 'uom',
                header: 'UOM',
                accessorFn: (row) => {
                    return row.unit
                        ? ProductUomLabels[row.unit as ProductUom]
                        : '--';
                },
            },
            {
                id: 'quantity',
                header: 'Quantity',
                accessorFn: (row) => row.quantity,
            },
            {
                id: 'shippedQuantity',
                header: 'Shipped Quantity',
                accessorFn: (row) => row.shippedQuantity || '--',
            },
            {
                id: 'price',
                header: 'Price',
                accessorFn: (row) => row.ppu,
            },
            {
                id: 'remarks',
                header: 'Remarks',
                cell: ({ row }) => {
                    const maxLength = 30;
                    const description =
                        row.original.product.description || '--';

                    return (
                        <div>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger>
                                        {description.slice(0, maxLength)}
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {description}
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                    );
                },
            },
            {
                id: 'lastUpdatedOn',
                header: 'Last Updated On',
                cell: ({ row }) => {
                    const date = row.original.product.lastUpdatedOn
                        ? new Date(row.original.product.lastUpdatedOn)
                        : undefined;

                    return date
                        ? DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
                        : '--';
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                size: 10,
                cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-2'>
                            <Eye
                                size={16}
                                className='cursor-pointer hover:scale-125'
                                onClick={() =>
                                    handleRedirectToDetails(
                                        row.original.product._id
                                    )
                                }
                            />
                        </div>
                    );
                },
            },
        ];

        return columns;
    }, [handleRedirectToDetails]);

    return (
        <div className='flex h-full flex-col '>
            <div className='flex w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-1'>
                <span className='text-sm font-bold text-white'>
                    Manifest Items
                </span>

                <div className='flex gap-4'>
                    <Button
                        className='text-white hover:bg-red-700 hover:text-white hover:opacity-80 hover:shadow'
                        variant='ghost'
                        size='sm'
                        // onClick={() => setMode('upload')}
                    >
                        {/* <CloudUpload size={16} /> */}
                    </Button>
                </div>
            </div>

            <VirtualTable
                columns={columns}
                data={memoizedItems}
                parentClassName='flex-1 max-h-full'
            />
        </div>
    );
}
