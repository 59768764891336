import axios from 'axios';
import { saveAs } from 'file-saver';
import { useMemo } from 'react';
import { toast } from 'sonner';
import { useAttachmentDownload } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-download';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { useFileService } from 'src/app/_api_adb2c/workspace/file/hooks/use-file-service';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useContextStore } from 'src/app/stores/context-store';
import {
    CustomSubstance,
    generateSubstanceColumns,
    generateSubstanceFromProduct,
    SubstanceDownloadButton,
} from 'src/app/utils/substance';

interface Props {
    purchaseId: string;
}

export function useDownloadWithFile() {
    const context = useContextStore();
    const { service } = useFileService();
    const { mutateAsync } = useAttachmentDownload();

    const download = async (id: string, autoDownload = true) => {
        if (!id) {
            toast.error('No file to download');
            return;
        }

        const response = await service.batchGet(context.workspace?.id || '', [
            id,
        ]);

        if (response.length === 0) return;

        const file = response?.[0];
        if (file) {
            const token = await mutateAsync({
                body: { id },
            });

            const blob = await axios.get(token.token, {
                responseType: 'blob',
            });

            saveAs(blob.data, file.name);
        }

        return response;
    };

    return { download };
}

export function PurchaseSubstanceV2({ purchaseId }: Props) {
    const { data: purchase } = usePurchase(purchaseId);

    const memoizedItems = useMemo(() => {
        const latestPurchaseVersion =
            purchase?.versions?.[purchase?.versions.length - 1];

        if (!latestPurchaseVersion) return [];

        const items: CustomSubstance[] = [];

        latestPurchaseVersion.manifest.forEach((manifest) => {
            const substances = generateSubstanceFromProduct(manifest.product);

            if (substances && substances.length > 0) {
                items.push({
                    isCustom: true,
                    customName: manifest.product.name,
                    substanceName: manifest.product.name,
                    subCompositions: substances,
                });
            }
        });

        return items;
    }, [purchase]);

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                columns={generateSubstanceColumns(({ row }) => (
                    <div className='flex items-center gap-2'>
                        <SubstanceDownloadButton substance={row.original} />
                    </div>
                ))}
                data={memoizedItems}
                label='Bill of Substance'
                subRowsField='subCompositions'
                defaultExpanded
                disabledTooltipColumns={['actions']}
            />
        </div>
    );
}
