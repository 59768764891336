import { z } from 'zod';
import { WorkspaceFileModel } from '../Documents/workspace-documents';
import { useUpdateDocument } from 'src/app/_api_adb2c/workspace/documents/hooks/use-update-document';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { Form } from 'src/components/ui/form';
import { TextInput } from 'src/app/components/Form/TextInput';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { DateInput } from 'src/app/components/Form/DateInput';
import { DocumentUpdateDataObject } from 'src/app/_api_adb2c/workspace/documents/document-update-data-object';

interface Props {
    open: boolean;
    onClose: () => void;
    selectedItem: WorkspaceFileModel;
    documents: WorkspaceFileModel[];
}

const formSchema = z.object({
    name: z.string(),
    type: z.string(),
    serial: z.string(),
    source: z.string(),
    issued: z.date(),
});

export const EditCertificate = ({
    open,
    onClose,
    selectedItem,
    documents,
}: Props) => {
    const { mutateAsync } = useUpdateDocument();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: selectedItem.name,
            type: selectedItem.type,
            serial: selectedItem.serial,
            source: selectedItem.source,
            issued: selectedItem.issued
                ? new Date(selectedItem.issued)
                : undefined,
        },
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const existing = documents?.find((x) => x._id === selectedItem._id);

        if (!existing) return;

        const body: DocumentUpdateDataObject = {
            documents: {
                _id: existing?._id,
                file: existing.file._id,
                type: data.type,
                isPrivate: false,
                serial: data.serial,
                source: data.source,
                issued: data.issued,
            },
            extensions: {
                isCertificate: true,
                name: existing.name,
                translatedName: [existing.name],
                translatedDescription: [],
            },
        };

        await mutateAsync({
            id: existing._id,
            body,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <BaseDialog open={open} onClose={reset} title='Edit Document'>
            <Form {...form}>
                <form id='my-form' onSubmit={form.handleSubmit(onSubmit)}>
                    <TextInput label='Name' inputName='name' />

                    <SelectInput label='Type' name='type' options={[]} />

                    <TextInput label='Serial' inputName='serial' />

                    <TextInput label='Source' inputName='source' />

                    <DateInput label='Issued' name='issued' />
                </form>
            </Form>
        </BaseDialog>
    );
};
