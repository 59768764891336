import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { ProductModel } from 'src/app/_api_adb2c/purchase/purchase/models/manifest-item.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { WorkspaceRulesetModel } from 'src/app/_api_adb2c/workspace/ruleset/ruleset.model';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { useContextStore } from 'src/app/stores/context-store';
import { SalesOrderModel } from '../models/sales-order.model';
import { SALES_ORDERS_QUERY_KEY } from './sales-order-query-keys';
import { useSalesOrderService } from './use-sales-order-service';

export type PurchaseOrderLink = PurchaseModel & {
    links: [
        PurchaseModel & {
            depth: number;
            cottonWeight?: number;
            cottonProducts?: ProductModel[];
        }
    ];
};

// Extending the base sales order with enriched data
export interface EnrichedSalesOrder extends Omit<SalesOrderModel, 'workspace'> {
    links: PurchaseOrderLink[]; // Replace with proper LinkModel when available
    purchase?: PurchaseModel;
    workspace: WorkspaceModel;
    rulesets: WorkspaceRulesetModel[];
    traces?: PurchaseOrderTraceModel[];
}

export function useSalesOrdersV2(delegateId?: string) {
    const { service } = useSalesOrderService();
    const context = useContextStore();

    const workspaceId = delegateId || context.workspace?._id || '';

    return useQuery<EnrichedSalesOrder[], Error>({
        queryKey: SALES_ORDERS_QUERY_KEY(workspaceId),
        queryFn: async () => {
            const orders = await service.enrichedList(workspaceId);

            return orders;
        },
        onError: (error: Error) => {
            toast.error(`Failed to fetch sales orders: ${error.message}`);
        },
    });
}
