import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { ProductMaterialDataObject } from './request/product-material-data-object';

export class MaterialApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'materials-v2');
    }

    getCategories() {
        console.log(`client<material>| getCategories(): Enter`);

        return super.get('categories');
    }

    getMaterialsByCategories(category: string) {
        console.log(`client<material>| getMaterialsByCategories(): Enter`);
        console.log(
            `client<material>| getMaterialsByCategories(): $category = ${category}`
        );

        return super.get('getMaterialsByCategories', category);
    }

    list() {
        console.log(`client<material>| list(): Enter`);

        return super.get('list');
    }

    create(workspace: string, body: any) {
        console.log(`client<material>| create(): Enter`);
        console.log(`client<material>| create(): $workspace = ${workspace}`);
        console.log(
            `client<material>| create(): $body = ${JSON.stringify(body)}`
        );

        return super.post('create', workspace, body);
    }

    listByWorkspace(workspace: string) {
        console.log(`client<material>| listByWorkspace(): Enter`);
        console.log(
            `client<material>| listByWorkspace(): $workspace = ${workspace}`
        );

        return super.get('listByWorkspace', workspace);
    }

    remove(workspace: string, item: string) {
        console.log(`client<material>| remove(): Enter`);
        console.log(`client<material>| remove(): $workspace = ${workspace}`);
        console.log(`client<material>| remove(): $item = ${item}`);

        return super.delete('remove', workspace, item);
    }

    update(workspace: string, item: string, body: any) {
        console.log(`client<material>| update(): Enter`);
        console.log(`client<material>| update(): $workspace = ${workspace}`);
        console.log(`client<material>| update(): $item = ${item}`);
        console.log(
            `client<material>| update(): $body = ${JSON.stringify(body)}`
        );

        return super.patch('update', workspace, item, body);
    }

    get(workspace: string, item: string) {
        console.log(`client<material>| get(): Enter`);
        console.log(`client<material>| get(): $workspace = ${workspace}`);
        console.log(`client<material>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    clone(workspace: string, item: string, body: ProductMaterialDataObject) {
        console.log(`client<material>| clone(): Enter`);
        console.log(`client<material>| clone(): $workspace = ${workspace}`);
        console.log(`client<material>| clone(): $item = ${item}`);
        console.log(
            `client<material>| clone(): $body = ${JSON.stringify(body)}`
        );

        return super.post('clone', workspace, item, body);
    }

    cloneComposition(
        workspace: string,
        item: string,
        body: { material: string }
    ) {
        console.log(`client<material>| cloneComposition(): Enter`);
        console.log(
            `client<material>| cloneComposition(): $workspace = ${workspace}`
        );
        console.log(`client<material>| cloneComposition(): $item = ${item}`);
        console.log(
            `client<material>| cloneComposition(): $body = ${JSON.stringify(
                body
            )}`
        );

        return super.post('cloneComposition', workspace, item, body);
    }
}
