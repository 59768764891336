import { Settings } from 'lucide-react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from '../../components/ui/dialog';
import { Button } from '../../components/ui/button';
import { ColumnDefinition } from '../hooks/useColumnConfiguration';
import { useForm } from 'react-hook-form';
import { CheckboxInput } from '../../components/ui/checkbox-input';
import { useEffect, useState } from 'react';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical } from 'lucide-react';

interface ColumnConfigurationModalProps {
    isOpen: boolean;
    onClose: () => void;
    columns: { key: string; label: string; visible: boolean }[];
    onSave: (columns: { [key: string]: boolean }) => void;
    onReorder?: (startIndex: number, endIndex: number) => void;
    onReset?: () => void;
}

interface FormValues {
    columns: { [key: string]: boolean };
}

// Sortable item component
function SortableColumnItem({ column, control }: any) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: column.key });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`flex items-center gap-2 rounded-md border p-2 ${
                isDragging ? 'bg-muted/50' : 'hover:bg-muted/30'
            }`}
        >
            <div {...attributes} {...listeners}>
                <GripVertical className='h-4 w-4 cursor-grab text-muted-foreground' />
            </div>
            <CheckboxInput
                name={`columns.${column.key}`}
                control={control}
                label={column.label}
                className='flex-1'
            />
        </div>
    );
}

export function ColumnConfigurationModal({
    isOpen,
    onClose,
    columns,
    onSave,
    onReorder,
    onReset,
}: ColumnConfigurationModalProps) {
    const [orderedColumns, setOrderedColumns] = useState(columns);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const defaultValues = {
        columns: columns.reduce(
            (acc, col) => ({ ...acc, [col.key]: col.visible }),
            {}
        ),
    };

    const { control, handleSubmit, reset } = useForm<FormValues>({
        defaultValues,
    });

    useEffect(() => {
        setOrderedColumns(columns);
        reset(defaultValues);
    }, [columns, reset]);

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            const oldIndex = orderedColumns.findIndex(
                (col) => col.key === active.id
            );
            const newIndex = orderedColumns.findIndex(
                (col) => col.key === over.id
            );

            const newOrder = arrayMove(orderedColumns, oldIndex, newIndex);
            setOrderedColumns(newOrder);
            onReorder?.(oldIndex, newIndex);
        }
    };

    const handleReset = () => {
        reset(defaultValues);
        setOrderedColumns(columns);
        onReset?.();
    };

    const onSubmit = (data: FormValues) => {
        onSave(data.columns);
        onClose();
    };

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='sm:max-w-[425px]'>
                <DialogHeader>
                    <DialogTitle>Configure Columns</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
                    <div className='grid gap-2 py-4'>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={orderedColumns.map((col) => col.key)}
                                strategy={verticalListSortingStrategy}
                            >
                                {orderedColumns.map((column) => (
                                    <SortableColumnItem
                                        key={column.key}
                                        column={column}
                                        control={control}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </div>
                    <div className='flex justify-end space-x-2'>
                        <Button
                            type='button'
                            variant='outline'
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                        <Button
                            type='button'
                            variant='outline'
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button type='submit'>Save Changes</Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
}
