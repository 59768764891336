import { ColumnDef } from '@tanstack/react-table';
import { Download, Edit, Plus, Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDocuments } from 'src/app/_api_adb2c/workspace/documents/hooks/use-documents';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useFileDownloader } from 'src/app/hooks/use-file-downloader';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';
import { WorkspaceFileModel } from '../Documents/workspace-documents';
import { CreateCertificate } from './create-certification';
import { DeleteCertificate } from './delete-certification';
import { EditCertificate } from './edit-certification';

export const WorkspaceCertifications = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data, isLoading } = useDocuments(delegateId);

    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedItem, setSelectedItem] = useState<WorkspaceFileModel>();

    const { downloadFiles } = useFileDownloader();

    const memoizedData = useMemo(() => {
        const documents: WorkspaceFileModel[] = [];

        data?.extensions.forEach((ext, index) => {
            const document = data.documents?.[index];

            if (!document) return;

            documents.push({
                ...document,
                ...ext,
            });
        });

        return documents.filter((x) => x.isCertificate);
    }, [data]);

    const columns: ColumnDef<WorkspaceFileModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'auditOrganization',
            header: 'Audit Organization',
            cell: ({ row }) => {
                return row.original.source;
            },
        },
        {
            id: 'certification',
            header: 'Certification',
            cell: ({ row }) => {
                return row.original.type;
            },
        },
        {
            id: 'certificateNumber',
            header: 'Certificate Number',
            cell: ({ row }) => {
                return row.original.serial;
            },
        },
        {
            id: 'issuedDate',
            header: 'Issued Date',
            cell: ({ row }) => {
                return row.original.issued
                    ? DateTime.fromJSDate(
                          new Date(row.original.issued)
                      ).toFormat('yyyy-MM-dd')
                    : '';
            },
        },
        {
            id: 'actions',
            size: 10,
            header: () => {
                return (
                    <Button variant='ghost' className='text-xs'>
                        <span>Actions</span>
                    </Button>
                );
            },
            cell: ({ row }) => {
                return (
                    <div className='flex'>
                        <Button
                            variant='ghost'
                            className='text-xs'
                            onClick={() => {
                                setIsEdit(true);
                                setSelectedItem(row.original);
                            }}
                        >
                            <Edit size={16} />
                        </Button>

                        <Button
                            variant='ghost'
                            className='text-xs'
                            onClick={() =>
                                downloadFiles([row.original.file._id], {
                                    fileName: row.original.name,
                                })
                            }
                        >
                            <Download size={16} />
                        </Button>

                        <Button
                            variant='ghost'
                            className='text-xs'
                            onClick={() => {
                                setIsDelete(true);
                                setSelectedItem(row.original);
                            }}
                        >
                            <Trash size={16} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Certifications'
                columns={columns}
                disabledTooltipColumns={['actions']}
                data={memoizedData}
                isLoading={isLoading}
                controls={{
                    enableSearch: true,
                }}
                actions={[
                    {
                        id: 'download',
                        icon: <Download size={16} />,
                        onClick: () =>
                            downloadFiles(
                                memoizedData.map((doc) => doc.file._id),
                                {
                                    fileName: 'Workspace Certifications',
                                }
                            ),
                    },
                    {
                        id: 'add',
                        icon: <Plus size={16} />,
                        onClick: () => setIsCreate(true),
                    },
                ]}
            />

            {isCreate && (
                <CreateCertificate
                    open={isCreate}
                    onClose={() => setIsCreate(false)}
                />
            )}

            {isEdit && selectedItem && (
                <EditCertificate
                    open={isEdit}
                    onClose={() => setIsEdit(false)}
                    selectedItem={selectedItem}
                    documents={memoizedData}
                />
            )}

            {isDelete && selectedItem && (
                <DeleteCertificate
                    open={isDelete}
                    onClose={() => setIsDelete(false)}
                    selectedItem={selectedItem}
                />
            )}
        </div>
    );
};
