import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'src/app/utils/cn';
import { Button } from 'src/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from 'src/components/ui/command';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import { IoIosAlert } from 'react-icons/io';

export const SelectInput = ({
    label,
    name,
    options,
    placeholder,
    disabled = false,
    readOnly = false,
    misMatch,
    library = undefined,
    className,
    optionClassName,
    setCustomSearch,
}: {
    label?: string;
    name: string;
    options: { label: string; value: string; disabled?: boolean }[];
    disabled?: boolean;
    readOnly?: boolean;
    misMatch?: { type: string; field: string; value: string; file: string }[];
    library?: any;
    className?: string;
    placeholder?: string;
    optionClassName?: string;
    setCustomSearch?: (value: string) => void;
}) => {
    const [open, setOpen] = useState(false);
    const { control, setValue } = useFormContext();
    const [allOptions, setAllOptions] = useState(options);
    const [popoverOpen, setPopoverOpen] = useState(false);

    useEffect(() => {
        setAllOptions(options);
    }, [options]);

    const handleOpenChange = (isOpen: boolean) => {
        if (readOnly) return;
        setOpen(isOpen);
    };

    const handleSelect = (value: string) => {
        if (readOnly) return;
        setValue(name, value);
        setOpen(false);
    };

    if (disabled || readOnly) {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => {
                    console.log(`misMatch = ${JSON.stringify(misMatch)}`);
                    return (
                        <FormItem className='flex flex-1 flex-col'>
                            <div>
                                {label && (
                                    <FormLabel className='inline-block text-xs'>
                                        {label}
                                    </FormLabel>
                                )}
                                {misMatch?.find(
                                    (t) =>
                                        t.field.toLowerCase() ===
                                        field.name.toLowerCase()
                                ) ? (
                                    <Popover
                                        open={popoverOpen}
                                        onOpenChange={setPopoverOpen}
                                    >
                                        <PopoverTrigger asChild>
                                            <IoIosAlert
                                                className='inline-block w-5 cursor-pointer'
                                                onMouseOver={() => {
                                                    setTimeout(() => {
                                                        document
                                                            .querySelector(
                                                                '[data-radix-popper-content-wrapper]'
                                                            )
                                                            ?.setAttribute(
                                                                'style',
                                                                'left: unset !important; top: unset !important; transform: translate(30%, -50%) !important;'
                                                            );
                                                    }, 100);
                                                    setPopoverOpen(true);
                                                }}
                                                onMouseLeave={() =>
                                                    setPopoverOpen(false)
                                                }
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            side='top'
                                            align='center'
                                            sideOffset={5}
                                            className='absolute w-[300px]'
                                        >
                                            <span className='text-xs'>
                                                {misMatch?.find(
                                                    (t) =>
                                                        t.field === field.name
                                                )?.field || ''}{' '}
                                                of{' '}
                                                {library?.documents?.find(
                                                    (d: any) =>
                                                        d.file.id ===
                                                        misMatch?.find(
                                                            (m) =>
                                                                m.field ===
                                                                field.name
                                                        )?.file
                                                )?.file.name ||
                                                    'No Document'}{' '}
                                                is{' '}
                                                {misMatch?.find(
                                                    (t) =>
                                                        t.field === field.name
                                                )?.value || ''}
                                            </span>
                                        </PopoverContent>
                                    </Popover>
                                ) : null}
                            </div>
                            <div
                                className={cn(
                                    'flex h-9 w-full items-center rounded-md border border-input bg-transparent px-3 text-sm shadow-sm transition-colors',
                                    readOnly ? 'bg-muted' : '',
                                    className
                                )}
                            >
                                <span className='text-xs'>
                                    {field.value
                                        ? allOptions.find(
                                              (option) =>
                                                  option.value === field.value
                                          )?.label
                                        : '-'}
                                </span>
                            </div>
                        </FormItem>
                    );
                }}
            />
        );
    }

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <>
                    <FormItem className='flex flex-1 flex-col'>
                        {label && (
                            <FormLabel className='text-xs'>{label}</FormLabel>
                        )}

                        <Popover open={open} onOpenChange={handleOpenChange}>
                            <PopoverTrigger asChild>
                                <FormControl>
                                    <Button
                                        variant='outline'
                                        data-testid='select-input'
                                        // role='combobox'
                                        size='sm'
                                        className={cn(
                                            cn(
                                                'w-full pl-3 text-left font-normal capitalize',
                                                className
                                            ),
                                            !field.value &&
                                                'text-muted-foreground'
                                        )}
                                    >
                                        <span className='max-w-[75%] truncate text-xs'>
                                            {field.value
                                                ? allOptions.find(
                                                      (option) =>
                                                          option.value ===
                                                          field.value
                                                  )?.label
                                                : placeholder || '-'}
                                        </span>
                                        <CaretSortIcon className='ml-auto h-4 w-4 opacity-50' />
                                    </Button>
                                </FormControl>
                            </PopoverTrigger>

                            <PopoverContent className='p-0'>
                                <Command
                                    className='max-h-[200px]'
                                    loop
                                    data-testid='select-input-command'
                                    // shouldFilter={false}
                                >
                                    <CommandInput
                                        placeholder='Search...'
                                        className='h-4 text-xs'
                                        data-testid='select-input-command-input'
                                        onValueChange={(value) => {
                                            if (setCustomSearch) {
                                                setCustomSearch(value);
                                            }
                                        }}
                                    />
                                    <CommandEmpty>
                                        No results found.
                                    </CommandEmpty>
                                    <CommandGroup aria-sort='none'>
                                        {allOptions.map((option) => (
                                            <CommandItem
                                                value={option.label}
                                                key={option.value}
                                                data-testid='select-input-command-item'
                                                className={cn(
                                                    cn(
                                                        'text-xs',
                                                        optionClassName
                                                    ),
                                                    option.disabled &&
                                                        'cursor-not-allowed opacity-50'
                                                )}
                                                disabled={option.disabled}
                                                onSelect={() => {
                                                    if (
                                                        option.value ===
                                                        field.value
                                                    ) {
                                                        handleSelect('');
                                                    } else {
                                                        handleSelect(
                                                            option.value
                                                        );
                                                    }
                                                }}
                                            >
                                                {option.label}
                                                <CheckIcon
                                                    className={cn(
                                                        'ml-auto h-4 w-4',
                                                        option.value ===
                                                            field.value
                                                            ? 'opacity-100'
                                                            : 'opacity-0'
                                                    )}
                                                />
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </Command>
                            </PopoverContent>
                        </Popover>

                        <FormMessage />
                    </FormItem>
                </>
            )}
        />
    );
};
