import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ColumnConfigurationApiClient } from './column-configuration.client';
import { ColumnDefinition } from 'src/app/hooks/useColumnConfiguration';

export class ColumnConfigurationService {
    private server: ColumnConfigurationApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new ColumnConfigurationApiClient(config, token);
    }

    create(workspace: string, pageKey: string, columns: ColumnDefinition[]) {
        console.log(`service<column-configuration>| create(): Enter`);
        console.log(
            `service<column-configuration>| create(): $workspace = ${workspace}`
        );
        console.log(
            `service<column-configuration>| create(): $pageKey = ${pageKey}`
        );

        return this.server.create(workspace, {
            pageKey,
            columns,
        });
    }

    getByWorkspace(workspace: string) {
        console.log(`service<column-configuration>| getByWorkspace(): Enter`);
        console.log(
            `service<column-configuration>| getByWorkspace(): $workspace = ${workspace}`
        );

        return this.server.getByWorkspace(workspace);
    }
}
