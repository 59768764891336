import { useState } from 'react';
import { useImpersonations } from 'src/app/_api_adb2c/workspace/impersonate/hooks/use-impersonations';
import { useCreateImpersonation } from 'src/app/_api_adb2c/workspace/impersonate/hooks/use-create-impersonation';
import { useRefreshImpersonation } from 'src/app/_api_adb2c/workspace/impersonate/hooks/use-refresh-impersonation';
import { CreateImpersonationDto } from 'src/app/_api_adb2c/workspace/impersonate/requests/create-impersonation.dto';
import { ImpersonationModel } from 'src/app/_api_adb2c/workspace/impersonate/model/impersonation.model';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from 'src/components/ui/dialog';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { Textarea } from 'src/components/ui/textarea';
import { Skeleton } from 'src/components/ui/skeleton';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
} from 'src/components/ui/card';
import { toast } from 'sonner';
import { formatDistanceToNow } from 'date-fns';
import { CopyIcon, PlusIcon, RefreshCwIcon, UserIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useImpersonationStore } from 'src/app/stores/impersonation-store';

export function Impersonate() {
    const { data, isLoading, error } = useImpersonations();
    const createImpersonation = useCreateImpersonation();
    const refreshImpersonation = useRefreshImpersonation();
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [newImpersonation, setNewImpersonation] =
        useState<CreateImpersonationDto>({
            user: '',
            reason: '',
        });

    const navigate = useNavigate();
    const startImpersonation = useImpersonationStore(
        (state) => state.startImpersonation
    );

    // Function to copy token to clipboard
    const copyTokenToClipboard = (token: string) => {
        navigator.clipboard.writeText(token);
        toast.success('Token copied to clipboard');
    };

    // Function to handle form input changes
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setNewImpersonation((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Function to submit the create impersonation form
    const handleCreateSubmit = () => {
        if (!newImpersonation.user.trim()) {
            toast.error('User ID is required');
            return;
        }

        if (!newImpersonation.reason.trim()) {
            toast.error('Reason is required');
            return;
        }

        createImpersonation.mutate(newImpersonation, {
            onSuccess: () => {
                setIsCreateDialogOpen(false);
                setNewImpersonation({ user: '', reason: '' });
            },
        });
    };

    // Function to check if token is expired
    const isTokenExpired = (expiresOn: Date | string | undefined): boolean => {
        if (!expiresOn) return true;
        const expiryDate = new Date(expiresOn);
        return expiryDate <= new Date();
    };

    // Function to refresh a token
    const handleRefreshToken = (impersonationId: string) => {
        refreshImpersonation.mutate(impersonationId);
    };

    // Function to use/consume an impersonation token
    const handleUseToken = (impersonation: ImpersonationModel) => {
        if (!impersonation.impersonation?.token) {
            toast.error('No valid impersonation token found');
            return;
        }

        if (isTokenExpired(impersonation.impersonation?.expiresOn)) {
            toast.error(
                'This impersonation token has expired. Please refresh it first.'
            );
            return;
        }

        try {
            // Create user data object that matches UserModel structure
            const userData = {
                _id: impersonation._id,
                token: impersonation.impersonation.token,
                contact: {
                    _id: impersonation.contact?._id || '',
                    email: impersonation.contact?.email || '',
                    firstName: impersonation.contact?.firstName || '',
                    lastName: impersonation.contact?.lastName || '',
                    device: impersonation.contact?.device || [],
                },
                activatedOn: new Date(),
                avatar: impersonation.avatar || '',
                createdOn: new Date(),
                enabled: true,
                invitation: {
                    _id: '',
                    createdOn: new Date(),
                },
                isSystemAdmin: false,
                lastUpdatedOn: new Date(),
                preferences: [],
            };

            // Use the impersonation store to start impersonation
            startImpersonation(impersonation.impersonation.token, userData);

            toast.success(
                `Now impersonating ${impersonation.contact?.firstName || ''} ${
                    impersonation.contact?.lastName || ''
                }`
            );

            // Navigate to the dashboard or home page
            navigate('/');

            // Prompt user about the impersonation banner
            setTimeout(() => {
                toast.info(
                    'An impersonation banner will appear at the top of the page. Use it to end impersonation when finished.'
                );
            }, 1500);
        } catch (error) {
            console.error('Error setting impersonation token:', error);
            toast.error('Failed to set impersonation token');
        }
    };

    if (error) {
        return (
            <Card className='w-full'>
                <CardContent className='pt-6'>
                    <div className='text-center text-destructive'>
                        Error loading impersonations:{' '}
                        {error instanceof Error
                            ? error.message
                            : 'Unknown error'}
                    </div>
                </CardContent>
            </Card>
        );
    }

    return (
        <div className='space-y-6'>
            <Card className='w-full'>
                <CardHeader>
                    <div className='flex items-center justify-between'>
                        <div>
                            <CardTitle>User Impersonation</CardTitle>
                            <CardDescription>
                                Create and manage impersonation tokens to access
                                user accounts for support purposes
                            </CardDescription>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    {isLoading ? (
                        <div className='space-y-2'>
                            <Skeleton className='h-10 w-full' />
                            <Skeleton className='h-10 w-full' />
                            <Skeleton className='h-10 w-full' />
                        </div>
                    ) : (
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>User</TableHead>
                                    <TableHead>Reason</TableHead>
                                    <TableHead>Created By</TableHead>
                                    <TableHead>Expires</TableHead>
                                    <TableHead>Token</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {data && data.length > 0 ? (
                                    data.map(
                                        (impersonation: ImpersonationModel) => (
                                            <TableRow key={impersonation._id}>
                                                <TableCell className='font-medium'>
                                                    {impersonation.contact
                                                        ?.firstName ||
                                                        'N/A'}{' '}
                                                    {impersonation.contact
                                                        ?.lastName || ''}
                                                    <div className='text-xs text-muted-foreground'>
                                                        {impersonation.contact
                                                            ?.email ||
                                                            'No email'}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {impersonation.impersonation
                                                        ?.reason ||
                                                        'No reason provided'}
                                                </TableCell>
                                                <TableCell>
                                                    {impersonation.impersonation
                                                        ?.admin || 'N/A'}
                                                </TableCell>
                                                <TableCell>
                                                    {impersonation.impersonation
                                                        ?.expiresOn
                                                        ? formatDistanceToNow(
                                                              new Date(
                                                                  impersonation.impersonation?.expiresOn
                                                              ),
                                                              {
                                                                  addSuffix:
                                                                      true,
                                                              }
                                                          )
                                                        : 'Not set'}
                                                </TableCell>
                                                <TableCell>
                                                    <div className='flex items-center'>
                                                        <div className='max-w-[180px] truncate font-mono text-xs'>
                                                            {impersonation
                                                                .impersonation
                                                                ?.token ||
                                                                'No token'}
                                                        </div>
                                                        <Button
                                                            variant='ghost'
                                                            size='icon'
                                                            onClick={() =>
                                                                copyTokenToClipboard(
                                                                    impersonation
                                                                        .impersonation
                                                                        ?.token ||
                                                                        ''
                                                                )
                                                            }
                                                            disabled={
                                                                !impersonation
                                                                    .impersonation
                                                                    ?.token
                                                            }
                                                        >
                                                            <CopyIcon className='h-4 w-4' />
                                                        </Button>
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='flex space-x-2'>
                                                        {impersonation
                                                            .impersonation
                                                            ?.token ? (
                                                            <>
                                                                <Button
                                                                    variant='outline'
                                                                    size='sm'
                                                                    onClick={() =>
                                                                        handleRefreshToken(
                                                                            impersonation
                                                                                .impersonation
                                                                                ?._id ||
                                                                                ''
                                                                        )
                                                                    }
                                                                    disabled={
                                                                        refreshImpersonation.isLoading
                                                                    }
                                                                >
                                                                    <RefreshCwIcon className='mr-2 h-4 w-4' />
                                                                    Refresh
                                                                </Button>
                                                                <Button
                                                                    variant='default'
                                                                    size='sm'
                                                                    onClick={() =>
                                                                        handleUseToken(
                                                                            impersonation
                                                                        )
                                                                    }
                                                                    disabled={isTokenExpired(
                                                                        impersonation
                                                                            .impersonation
                                                                            ?.expiresOn
                                                                    )}
                                                                >
                                                                    <UserIcon className='mr-2 h-4 w-4' />
                                                                    Use
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <Button
                                                                variant='outline'
                                                                size='sm'
                                                                onClick={() => {
                                                                    setNewImpersonation(
                                                                        {
                                                                            user: impersonation._id,
                                                                            reason: '',
                                                                        }
                                                                    );
                                                                    setIsCreateDialogOpen(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <PlusIcon className='mr-2 h-4 w-4' />
                                                                Create
                                                            </Button>
                                                        )}
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    )
                                ) : (
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            className='py-6 text-center'
                                        >
                                            No impersonation tokens found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                </CardContent>
            </Card>

            {/* Create Impersonation Dialog */}
            <Dialog
                open={isCreateDialogOpen}
                onOpenChange={setIsCreateDialogOpen}
            >
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Create Impersonation Token</DialogTitle>
                    </DialogHeader>
                    <div className='space-y-4 py-4'>
                        <div className='space-y-2'>
                            <Label htmlFor='user'>User</Label>
                            {data && newImpersonation.user && (
                                <div className='mb-2 rounded-md bg-gray-100 p-2 text-sm'>
                                    {data.find(
                                        (user: ImpersonationModel) =>
                                            user._id === newImpersonation.user
                                    )?.contact?.firstName || ''}{' '}
                                    {data.find(
                                        (user: ImpersonationModel) =>
                                            user._id === newImpersonation.user
                                    )?.contact?.lastName || ''}{' '}
                                    <span className='text-xs text-muted-foreground'>
                                        (
                                        {data.find(
                                            (user: ImpersonationModel) =>
                                                user._id ===
                                                newImpersonation.user
                                        )?.contact?.email || ''}
                                        )
                                    </span>
                                </div>
                            )}
                            {!newImpersonation.user && (
                                <Input
                                    id='user'
                                    name='user'
                                    value={newImpersonation.user}
                                    onChange={handleInputChange}
                                    placeholder='Enter user ID to impersonate'
                                />
                            )}
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='reason'>Reason</Label>
                            <Textarea
                                id='reason'
                                name='reason'
                                value={newImpersonation.reason}
                                onChange={handleInputChange}
                                placeholder='Enter reason for impersonation'
                                rows={3}
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button
                            variant='outline'
                            onClick={() => {
                                setIsCreateDialogOpen(false);
                                setNewImpersonation({ user: '', reason: '' });
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleCreateSubmit}
                            disabled={
                                createImpersonation.isLoading ||
                                !newImpersonation.user ||
                                !newImpersonation.reason
                            }
                        >
                            {createImpersonation.isLoading
                                ? 'Creating...'
                                : 'Create Token'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}
