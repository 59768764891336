import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { Checkbox } from './checkbox';
import { cn } from 'src/lib/utils';
interface CheckboxInputProps<T extends FieldValues> {
    name: Path<T>;
    control: Control<T>;
    label?: string;
    disabled?: boolean;
    className?: string;
}

export function CheckboxInput<T extends FieldValues>({
    name,
    control,
    label,
    disabled,
    className,
}: CheckboxInputProps<T>) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <div className={cn('flex items-center space-x-2', className)}>
                    <Checkbox
                        id={name}
                        checked={value}
                        onCheckedChange={onChange}
                        disabled={disabled}
                    />
                    {label && (
                        <label
                            htmlFor={name}
                            className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                        >
                            {label}
                        </label>
                    )}
                </div>
            )}
        />
    );
}
