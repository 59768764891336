import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { DOCUMENTS_QUERY_KEY } from './documents-query-key';
import { useDocumentService } from './use-document-service';

export function useExtractDocument() {
    const client = useQueryClient();
    const context = useContextStore();
    const { service } = useDocumentService();

    return useMutation({
        mutationFn: async ({
            delegateId,
            documentId,
        }: {
            delegateId?: string;
            documentId: string;
        }) => {
            return await service.extract(
                delegateId || context.workspace?._id || '',
                documentId
            );
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries({
                queryKey: DOCUMENTS_QUERY_KEY(
                    delegateId || context.workspace?._id || ''
                ),
            });

            toast.success('Successfully extract document');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to delete document: ${error.message}`);
        },
    });
}
