import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { ImportErrorMessage, useImportBase } from './use-import-base';
import { ExternalReferenceModel } from 'src/app/_api_adb2c/purchase/purchase/models/external-reference.model';

interface SheetData {
    'PO Factory Name'?: string;
    'PO Factory License Number'?: string;
    'PO Factory Country'?: string;
    'PO Factory Address'?: string;
    'PO Factory Contact Name'?: string;
    'PO Factory Contact Email'?: string;
    'Main Category'?: string;
    'PO Number'?: string;
    'Item Number'?: string;
    'Product Description'?: string;
    'In-House / Sub-Contract / Component / Raw Material': string;
    'Production Process': string;
    Subcategory: string;
    Component: string;
    'Raw Material': string;
    'Factory Name (Material Supplier)': string;
    'Factory License Number (Material Supplier)': string;
    'Factory Country (Material Supplier)': string;
    'Factory Address': string;
    'Factory Contact First Name'?: string;
    'Factory Contact Last Name'?: string;
    'Factory Contact Email'?: string;
    __rowNum__: string;
    [key: string]: string | undefined;
}

enum CustomOrderNature {
    PROCESSING_INHOUSE = 'Production - In-house',
    PROCESSING_OUTSOURCE = 'Production - Outsource',
    COMPONENT = 'Component',
    RAW_MATERIAL = 'Raw Material',
}

const sheetName = 'PPMF Form Section B';
const DEBUG_PREFIX = '[ImportAction]';

export function useImportAction() {
    const base = useImportBase();

    const validateExcel = async (
        items: SheetData[],
        errorMessages: ImportErrorMessage[]
    ) => {
        console.debug(
            `${DEBUG_PREFIX} Starting Excel validation with ${items.length} items`
        );

        const uniquePoFactory = Array.from(
            new Set(items.map((item) => item['PO Factory Name']))
        );

        console.debug(
            `${DEBUG_PREFIX} Found ${uniquePoFactory.length} unique PO factories`
        );

        if (uniquePoFactory.length > 1) {
            console.error(
                `${DEBUG_PREFIX} Multiple PO Factory Names found: ${uniquePoFactory.join(
                    ', '
                )}`
            );
            throw new Error(
                'The PO Factory Name must be the same for all items'
            );
        }

        const requiredFields = [
            'Factory Name (Material Supplier)',
            'Factory License Number (Material Supplier)',
            'Factory Country (Material Supplier)',
            'Factory Address',
            'In-House / Sub-Contract / Component / Raw Material',
        ];

        const validationErrors: string[] = [];

        items.forEach(async (item) => {
            console.debug(`${DEBUG_PREFIX} Validating row ${item.__rowNum__}`);

            const requiredError = requiredFields.some(
                (field) => !item[field] || item[field] === ''
            );

            if (requiredError) {
                console.error(
                    `${DEBUG_PREFIX} Missing required fields in row ${item.__rowNum__}`
                );
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required fields for ${
                        item['Factory Name']
                    }: ${requiredFields.join(', ')}`
                );
                return;
            }

            if (
                item['PO Factory Name'] &&
                !(
                    item['PO Factory Country'] &&
                    item['PO Factory License Number']
                )
            ) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required fields for ${item['PO Factory Name']}: PO Factory License Number, PO Factory Country`
                );

                return;
            }

            if (
                item['Factory Contact First Name'] &&
                !(
                    item['Factory Contact Last Name'] &&
                    item['Factory Contact Email']
                )
            ) {
                validationErrors.push(
                    `Row ${item.__rowNum__} is missing required field for ${item['Factory Name']}: Factory Contact Last Name`
                );

                return;
            }
        });

        if (validationErrors.length > 0) {
            console.error(
                `${DEBUG_PREFIX} Validation failed with ${validationErrors.length} errors`
            );
            throw new Error(validationErrors[0]);
        }

        console.debug(
            `${DEBUG_PREFIX} Excel validation completed successfully`
        );
    };

    const processExcel = async (
        items: SheetData[],
        purchase: PurchaseModel,
        workspace: WorkspaceModel
    ) => {
        console.debug(
            `${DEBUG_PREFIX} Starting Excel processing with ${items.length} items`
        );

        // Filter out rows that don't have required data
        const filteredItems = items.filter(
            (data) => data['Factory Name (Material Supplier)']
        );

        let parentPurchaseOrder: PurchaseModel = purchase;

        // Check if there is a PO Factory Name provided.
        const poFactoryItem = filteredItems.find((x) => x['PO Factory Name']);

        if (poFactoryItem) {
            console.debug(
                `${DEBUG_PREFIX} Processing PO Factory: ${poFactoryItem['PO Factory Name']}`
            );

            const poFactoryPartner = await base.findOrCreatePartner(
                purchase.supplier.seller._id,
                poFactoryItem['PO Factory Name']?.toString()?.trim() || '',
                poFactoryItem,
                workspace
            );

            console.debug(
                `${DEBUG_PREFIX} Created/Found PO Factory Partner: ${poFactoryPartner.workspaceId}`
            );

            const traces = await base.services.purchaseService.trace(
                workspace._id,
                purchase._id,
                '1'
            );

            console.debug(
                `${DEBUG_PREFIX} Found ${traces.length} traces for the PO`
            );

            // Check if there are any traces for the PO yet.
            if (traces.length > 0) {
                parentPurchaseOrder = await base.services.purchaseService.get(
                    traces?.[0].workspace || '',
                    traces?.[0]._id || '',
                    '1'
                );

                console.debug(
                    `${DEBUG_PREFIX} Found existing PO: ${parentPurchaseOrder._id}`
                );
            } else {
                // Create the PO Factory
                const purchaseItem = purchase.versions[0].manifest[0];

                console.debug(
                    `${DEBUG_PREFIX} Creating PO Factory: ${purchaseItem.product._id}`
                );

                const product = await base.services.productService.get(
                    purchase.supplier.workspace._id,
                    purchaseItem.product._id,
                    '1'
                );

                console.debug(`${DEBUG_PREFIX} Found product: ${product._id}`);

                // Create the Parent Category (Clone)
                const category = await base.findOrCreateCategory(
                    purchase.supplier.seller._id,
                    product,
                    product?.category?.code,
                    purchase.supplier.seller._id
                );

                console.debug(
                    `${DEBUG_PREFIX} Found category: ${category._id}`
                );

                // Create the Parent Product
                const newProduct = await base.findOrCreateProduct(
                    purchase.supplier.seller._id,
                    {
                        'Product Name': product?.name,
                        'Item Number [Optional]':
                            product?.externalReferences?.[0]?.value,
                    },
                    category._id
                );

                // Create the Parent Purchase Order
                parentPurchaseOrder = await base.createPurchaseOrder(
                    purchase.supplier.seller._id,
                    poFactoryPartner.workspaceId,
                    {},
                    {
                        id: newProduct._id,
                        cost: Number(
                            newProduct.versions?.[0]?.costOfMaterials || 0
                        ),
                    },
                    purchase,
                    purchase
                );
            }
        }

        for (const item of filteredItems) {
            console.debug(
                `${DEBUG_PREFIX} Processing item for factory: ${item['Factory Name']}`
            );

            const workspaceId = poFactoryItem
                ? parentPurchaseOrder.supplier.seller._id
                : workspace._id;

            const factoryPartner = await base.findOrCreatePartner(
                workspaceId,
                item['Factory Name'] || '',
                item,
                workspace,
                {
                    email: item['Factory Contact Email'] || '',
                    firstName: item['Factory Contact First Name'] || '',
                    lastName: item['Factory Contact Last Name'] || '',
                }
            );

            const category = await base.findOrCreateCategory(workspaceId, item);

            const product = await base.findOrCreateProduct(
                workspaceId,
                {
                    'Product Name': getProductName(item),
                    'Item Number [Optional]': item['Item Number'],
                },
                category._id
            );

            const reference: ExternalReferenceModel[] = [];

            if (item['PO Number']) {
                reference.push({
                    source: 'externalDataId',
                    value: item['PO Number'],
                });
            }

            await base.createPurchaseOrder(
                workspaceId,
                factoryPartner.workspaceId,
                {
                    reference,
                },
                {
                    id: product._id,
                    cost: Number(product.versions?.[0]?.costOfMaterials || 0),
                },
                parentPurchaseOrder,
                parentPurchaseOrder
            );

            console.debug(
                `${DEBUG_PREFIX} Created purchase order for ${item['Factory Name']}`
            );
        }

        console.debug(
            `${DEBUG_PREFIX} Excel processing completed successfully`
        );
    };

    const getProductName = (item: SheetData) => {
        const orderNature = item[
            'In-House / Sub-Contract / Component / Raw Material'
        ] as CustomOrderNature;

        if (
            orderNature === CustomOrderNature.PROCESSING_INHOUSE ||
            orderNature === CustomOrderNature.PROCESSING_OUTSOURCE
        ) {
            return `Service - ${item['Production Process']}`;
        }

        const nameMapping = {
            [CustomOrderNature.COMPONENT]: 'Component',
            [CustomOrderNature.RAW_MATERIAL]: 'Raw Material',
        };

        return item[nameMapping[orderNature as keyof typeof nameMapping]] || '';
    };

    const submit = (workspace: string, purchaseId: string, file: File) => {
        return base.processFile<SheetData>(
            file,
            sheetName,
            validateExcel,
            processExcel,
            workspace,
            purchaseId
        );
    };

    return { submit, isLoading: base.isLoading };
}
