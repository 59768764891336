import { Check, LoaderCircle, X } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { WorkspaceModel } from 'src/app/_api_adb2c/product/category/category.model';
import { useApproveApprovalRequest } from 'src/app/_api_adb2c/product/product-approval/hooks/use-approve-approval-request';
import { useProductApprovalByWorkspaceItem } from 'src/app/_api_adb2c/product/product-approval/hooks/use-product-approval-by-workspace-item';
import { useProduct } from 'src/app/_api_adb2c/product/product/hooks/use-product';
import {
    ProductStatusLabels,
    UsageNature,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { usePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase';
import { useSalesOrder } from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-order';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TableV2 } from 'src/app/components-v2/table-v2';
import {
    ConsolidatedComposition,
    VirtualWorkspaceComposition,
} from 'src/app/pages/Products/Details/Substances';
import { generateOrderTitle } from 'src/app/utils/generate-order-title';
import {
    CustomSubstance,
    generateSubstanceColumns,
    generateSubstanceFromProduct,
    SubstanceDownloadButton,
} from 'src/app/utils/substance';
import { Button } from 'src/components/ui/button';
import { cn } from 'src/lib/utils';
import { ProductRejection } from '../../Approval/product-rejection';

export const ProductApprovalsDetails = () => {
    const { id, workspace } = useParams<{ id: string; workspace: string }>();
    const [isRejected, setIsRejected] = useState(false);

    const { data: request } = useProductApprovalByWorkspaceItem(
        id || '',
        workspace
    );
    const { mutateAsync: approve, isLoading } = useApproveApprovalRequest();

    const { data } = useProduct(
        request?.product?._id || '',
        request?.workspace?._id || ''
    );

    const { data: salesOrder } = useSalesOrder(
        request?.salesOrder || '',
        request?.workspace?._id || '',
        '1'
    );

    const { data: purchaseOrder } = usePurchase(
        salesOrder?.purchaseOrderId || ''
    );

    const customerProductQuantity = useMemo(() => {
        const version =
            purchaseOrder?.versions?.[purchaseOrder.versions.length - 1];

        if (!version) return 0;

        const product = version.manifest.find(
            (x) => x?.product?._id === request?.customerProduct?._id
        );

        return product?.quantity || 0;
    }, [purchaseOrder, request]);

    const isCompleted = useMemo(() => {
        if (!request) return false;

        return request.approvedOn || request.rejectedOn;
    }, [request]);

    const externalReference = useMemo(() => {
        const reference = data?.externalReferences?.find((x) => {
            return x.source === 'externalReferenceCode';
        })?.value;

        return reference;
    }, [data]);

    const productNumber = useMemo(() => {
        const reference = data?.externalReferences?.find((x) => {
            return x.source === 'externalDataId';
        })?.value;

        return reference;
    }, [data]);

    const latestVersion = useMemo(() => {
        const version = data?.versions?.[data.versions.length - 1];

        if (!version) {
            return undefined;
        }

        return version;
    }, [data]);

    const grossWeight = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];

        if (!version) return { grossWeight: 0, substanceWeight: 0 };

        const grossWeight = version?.billOfMaterials
            ?.filter((x) => x.usageNature === UsageNature.PRODUCT)
            ?.reduce((acc, x) => {
                return acc + (x.weight || 0) * (customerProductQuantity || 1);
            }, 0);

        return {
            grossWeight: (grossWeight || 0).toFixed(2),
            substanceWeight: version?.billOfMaterials
                ?.filter((x) => x.usageNature === UsageNature.PRODUCT)
                .reduce((acc, x) => {
                    return acc + (x.weight || 0) * (x.consumption || 1);
                }, 0),
        };
    }, [data, customerProductQuantity]);

    const memoizedSubstances = useMemo(() => {
        const version = data?.versions?.[data?.versions.length - 1];

        if (!version) return [];
        if (!version?.billOfMaterials) return [];

        const response =
            version?.billOfMaterials
                .map((x) => x?.material?.compositions)
                .filter((x) => x)
                .flatMap((x) => {
                    return x?.map((y) => {
                        return {
                            ...y,
                        };
                    });
                }) || [];

        return response as VirtualWorkspaceComposition[];
    }, [data]);

    const isMaxRatio = useMemo(() => {
        if (!memoizedSubstances)
            return { isMaxRatio: false, errorSubstances: [] };
        if (!grossWeight) return { isMaxRatio: false, errorSubstances: [] };
        if (!grossWeight.substanceWeight)
            return { isMaxRatio: false, errorSubstances: [] };

        let isMaxRatio = true;
        const errorSubstances: string[] = [];

        const latestProduct = data?.versions?.[data?.versions.length - 1];

        if (!latestProduct) return { isMaxRatio: false, errorSubstances: [] };

        latestProduct.billOfMaterials
            ?.filter((x) => x.usageNature === UsageNature.PRODUCT)
            ?.forEach((x) => {
                if (
                    !x?.material?.compositions ||
                    (x?.material?.compositions &&
                        x?.material?.compositions.length === 0)
                ) {
                    return;
                }

                const totalWeight = x.material?.compositions
                    ?.reduce((acc, curr) => {
                        return acc + (curr.weight || 0);
                    }, 0)
                    .toFixed(2);

                if (
                    Number(totalWeight) !== x.material?.specifications?.weight
                ) {
                    console.debug(
                        `Total weight: ${totalWeight} for ${x.material?.name}`
                    );
                    isMaxRatio = false;
                    if (x.material?.name) {
                        errorSubstances.push(x.material.name);
                    }
                }
            });

        return { isMaxRatio, errorSubstances };
    }, [memoizedSubstances, grossWeight]);

    const memoizedColumns = useMemo(() => {
        return generateSubstanceColumns(
            ({ row }) => (
                <div className='flex items-center gap-2'>
                    <SubstanceDownloadButton substance={row.original} />
                </div>
            ),
            customerProductQuantity
        );
    }, [customerProductQuantity]);

    const collectValidationErrors = useCallback(() => {
        const errors: React.ReactNode[] = [];

        // Check substance ratios
        if (!isMaxRatio.isMaxRatio && isMaxRatio.errorSubstances.length > 0) {
            errors.push(
                <div key='ratio-error'>
                    The following substances have ratios not equal to 100%:
                    {isMaxRatio.errorSubstances.map((substance, index) => (
                        <div key={index} className='ml-4'>
                            • {substance}
                        </div>
                    ))}
                </div>
            );
        }

        return errors;
    }, [isMaxRatio]);

    const triggerApproval = useCallback(() => {
        if (!request) return;

        const errors = collectValidationErrors();

        if (errors.length > 0) {
            toast.error(
                <div className='flex flex-col gap-2'>
                    <div className='font-bold'>
                        Please fix the following errors:
                    </div>
                    <div className='max-h-[200px] overflow-y-auto pr-2'>
                        {errors}
                    </div>
                </div>,
                {
                    duration: 5000,
                    className: 'max-w-md',
                }
            );
            return;
        }

        approve({
            item: request._id,
            delegateId: request.approver,
        });
    }, [approve, request, collectValidationErrors]);

    const substanceData = useMemo(() => {
        if (!data) return [];

        const items: CustomSubstance[] = [];

        items.push(...generateSubstanceFromProduct(data));

        return items;
    }, [data]);

    useEffect(() => {
        const latest = data?.versions?.[data.versions.length - 1];
        if (!latest) return;

        const consolidatedCompositions: Record<
            string,
            ConsolidatedComposition
        > = {};

        latest.billOfMaterials?.forEach((bill) => {
            bill?.material?.compositions?.forEach((composition) => {
                const name = composition.substanceCode || composition.name;
                const supplier =
                    composition.supplier as unknown as WorkspaceModel;

                if (!consolidatedCompositions[name]) {
                    consolidatedCompositions[name] = {
                        ...composition,
                        name: composition.name,
                        weight: composition.weight || 0,
                        actualWeight: composition.actualWeight || 0,
                        supplier: supplier ? [supplier] : undefined,
                        sdsFileId: composition.sdsFileId
                            ? [composition.sdsFileId]
                            : [],
                    };

                    return;
                }

                consolidatedCompositions[name].weight += composition?.weight;
                consolidatedCompositions[name].actualWeight +=
                    composition?.actualWeight;

                if (composition.sdsFileId) {
                    consolidatedCompositions[name].sdsFileId?.push(
                        composition.sdsFileId
                    );
                }
            });
        });
    }, [data]);

    return (
        <>
            <div className=''>
                <div className='grid h-[90%] w-full grid-cols-1 gap-4'>
                    <div className='grid grid-cols-1 justify-between rounded-lg bg-white px-4 py-2 shadow-xl'>
                        <div className='flex items-center justify-between py-2'>
                            <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-2'>
                                Product Information
                            </span>

                            {isCompleted ? (
                                <div>
                                    <span className='text-xs font-bold'>
                                        Status:{' '}
                                        <span
                                            className={cn(
                                                'text-xs font-bold',
                                                request?.approvedOn
                                                    ? 'text-green-600'
                                                    : 'text-red-600'
                                            )}
                                        >
                                            {request?.approvedOn
                                                ? 'Approved'
                                                : 'Rejected'}
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div className='flex gap-2'>
                                    <Button
                                        className='flex items-center gap-2'
                                        variant='ghost'
                                        size='sm'
                                        onClick={
                                            () => triggerApproval()
                                            // triggerApproval({
                                            //     item: request?._id || '',
                                            // })
                                        }
                                    >
                                        {isLoading ? (
                                            <>
                                                <LoaderCircle
                                                    size={16}
                                                    className='animate-spin'
                                                />
                                                <span>Loading...</span>
                                            </>
                                        ) : (
                                            <>
                                                <Check size='16' />
                                                <span>Approve</span>
                                            </>
                                        )}
                                    </Button>

                                    <Button
                                        className='flex items-center gap-2'
                                        variant='ghost'
                                        size='sm'
                                        disabled={isLoading}
                                        onClick={() => setIsRejected(true)}
                                    >
                                        <X size='16' />
                                        <span>Reject</span>
                                    </Button>
                                </div>
                            )}
                        </div>

                        <div className='grid grid-cols-4 gap-4'>
                            <DetailLabel title='Product Number'>
                                {productNumber}
                            </DetailLabel>
                            <DetailLabel title='Unit of Measurement'>
                                {data?.unitOfMeasurement
                                    ? ProductUomLabels[
                                          data.unitOfMeasurement as ProductUom
                                      ]
                                    : '--'}
                            </DetailLabel>
                            <DetailLabel title='External Sys Number'>
                                {externalReference}
                            </DetailLabel>
                            <DetailLabel title='Available Processes'></DetailLabel>

                            <DetailLabel title='Product Name'>
                                {data?.name}
                            </DetailLabel>
                            <DetailLabel title='Gross Weight'>
                                {grossWeight.grossWeight}
                                {/* {latestVersion?.specifications?.weight} */}
                            </DetailLabel>
                            <DetailLabel
                                title='Variant'
                                className='col-span-2'
                            ></DetailLabel>

                            <DetailLabel title='Product Family'></DetailLabel>
                            <DetailLabel title='UPC/EAN'>
                                {data?.upc}
                            </DetailLabel>
                            <DetailLabel title='Season/Collection'>
                                {data?.collection}
                            </DetailLabel>
                            <DetailLabel title='Status'>
                                {data?.status
                                    ? ProductStatusLabels[data?.status]
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='Product Category'>
                                {data?.category?.name || data?.category?.code}
                            </DetailLabel>
                            <DetailLabel title='HS Code'>
                                {data?.hsCode}
                            </DetailLabel>
                            <DetailLabel title='Cost'>
                                {latestVersion?.costOfMaterials}
                            </DetailLabel>
                        </div>
                    </div>

                    <div className='grid grid-cols-1 justify-between rounded-lg bg-white px-4 py-2 shadow-xl'>
                        <div className='flex items-center justify-between py-2'>
                            <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-2'>
                                Purchase Information
                            </span>
                        </div>

                        <div className='grid grid-cols-4 gap-4'>
                            <DetailLabel title='Purchase Order'>
                                {purchaseOrder
                                    ? generateOrderTitle(purchaseOrder)
                                    : '--'}
                            </DetailLabel>

                            <DetailLabel title='PO Quantity'>
                                {customerProductQuantity}
                            </DetailLabel>
                        </div>
                    </div>

                    <TableV2
                        label='Bill of Substance'
                        columns={memoizedColumns}
                        data={substanceData}
                        defaultExpanded={true}
                        subRowsField='subCompositions'
                    />
                </div>
            </div>

            <ProductRejection
                open={isRejected}
                approvalId={request?._id || ''}
                onClose={() => setIsRejected(false)}
                delegateId={request?.approver}
            />
        </>
    );
};
