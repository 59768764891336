import { useImportBase } from './use-import-base';
import { SheetData } from './use-import-cascade';
import { AttachmentCreateParams } from 'src/app/components-v2/dropzone';

export function useImportUpload() {
    const base = useImportBase();

    const submit = (
        workspace: string,
        purchaseId: string,
        file: AttachmentCreateParams
    ) => {
        return base.uploadFile<SheetData>(file, workspace, purchaseId);
    };

    return { submit };
}
