import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { ColumnDefinition } from 'src/app/hooks/useColumnConfiguration';

interface CreateColumnConfigurationRequest {
    pageKey: string;
    columns: ColumnDefinition[];
}

export class ColumnConfigurationApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'column-configuration-v1');
    }

    create(workspace: string, body: CreateColumnConfigurationRequest) {
        console.log(`client<column-configuration>| create(): Enter`);
        console.log(
            `client<column-configuration>| create(): $workspace = ${workspace}`
        );
        console.log(
            `client<column-configuration>| create(): $body = ${JSON.stringify(
                body
            )}`
        );

        return super.post('create', workspace, body);
    }

    getByWorkspace(workspace: string) {
        console.log(`client<column-configuration>| getByWorkspace(): Enter`);
        console.log(
            `client<column-configuration>| getByWorkspace(): $workspace = ${workspace}`
        );

        return super.get('getByWorkspace', workspace);
    }
}
