import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useImpersonationService } from './use-impersonation-service';
import { CreateImpersonationDto } from '../requests/create-impersonation.dto';
import { toast } from 'sonner';
import { impersonationQueryKeys } from './impersonation-query-keys';
import { useContextStore } from 'src/app/stores/context-store';

export function useCreateImpersonation() {
    const { service } = useImpersonationService();
    const client = useQueryClient();
    const context = useContextStore();

    return useMutation({
        mutationFn: (body: CreateImpersonationDto) => service.create(body),
        onSuccess: () => {
            toast.success('Impersonation created successfully');
            client.invalidateQueries({
                queryKey: impersonationQueryKeys.list(
                    context.workspace?._id || ''
                ),
            });
        },
        onError: () => {
            toast.error('Failed to create impersonation');
        },
    });
}
