import { useAccount } from '@azure/msal-react';
import { useMemo } from 'react';
import useAppConfig from 'src/infrastructure/hooks/use-config.hook';
import { ImpersonationService } from '../impersonation.service';

export function useImpersonationService() {
    const account = useAccount();
    const config = useAppConfig();

    const service = useMemo(() => {
        if (account?.idToken) {
            return new ImpersonationService(config, account.idToken);
        } else {
            throw new Error('No account found in context');
        }
    }, [account?.idToken, config]);

    return { service };
}
