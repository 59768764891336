import { useMutation } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { FileCreateDataObject } from '../file-create-data-object';
import { FileResultObject } from '../file-result-object';
import { useAttachmentService } from './use-attachment-service';

export function useAttachmentUpload() {
    const context = useContextStore();
    const { service } = useAttachmentService();

    return useMutation({
        mutationFn: async ({
            file,
            assetType,
            diversion = '1',
            delegateId,
        }: {
            file: FileCreateDataObject;
            assetType: string;
            diversion?: string;
            delegateId?: string;
        }): Promise<FileResultObject> => {
            return await service.upload(
                delegateId || context.workspace?._id || '',
                assetType,
                diversion,
                file
            );
        },
        onSuccess: () => {
            // toast.success('Successfully uploaded file');
        },
        onError: (err) => {
            const error = err as Error;
            toast.error(`Failed to upload file: ${error.message}`);
        },
    });
}
