import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ImpersonationClient } from './impersonation.client';
import { CreateImpersonationDto } from './requests/create-impersonation.dto';

export class ImpersonationService {
    private readonly impersonateClient: ImpersonationClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.impersonateClient = new ImpersonationClient(config, token);
    }

    list() {
        console.log(`service<impersonation>| list(): Enter`);

        return this.impersonateClient.list();
    }

    create(body: CreateImpersonationDto) {
        console.log(`service<impersonation>| create(): Enter`);
        console.log(
            `service<impersonation>| create(): body = ${JSON.stringify(body)}`
        );

        return this.impersonateClient.create(body);
    }

    refresh(id: string) {
        console.log(`service<impersonation>| refresh(): Enter`);
        console.log(`service<impersonation>| refresh(): id = ${id}`);

        return this.impersonateClient.refresh(id);
    }
}
