import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useColumnConfigurationService } from '../_api_adb2c/column-configuration/hooks/use-column-configuration-service';
import { useContextStore } from '../stores/context-store';
import { useSearchParams } from 'react-router-dom';

export interface ColumnDefinition {
    id: string;
    visible: boolean;
    order: number;
    label: string;
}

interface UseColumnConfigurationProps {
    pageKey: string;
    defaultColumns: ColumnDefinition[];
}

export const useColumnConfiguration = ({
    pageKey,
    defaultColumns,
}: UseColumnConfigurationProps) => {
    const queryClient = useQueryClient();
    const { workspace } = useContextStore();
    const { service } = useColumnConfigurationService();
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    // Use delegate ID if present, otherwise use workspace ID
    const effectiveWorkspaceId = delegateId || workspace?._id || '';

    const { data: columnConfig, isLoading } = useQuery({
        queryKey: ['columnConfig', effectiveWorkspaceId, pageKey],
        queryFn: async () => {
            try {
                const response = await service.getByWorkspace(
                    effectiveWorkspaceId
                );

                const configs = response || [];
                const pageConfig = configs.find(
                    (config: any) => config.pageKey === pageKey
                );

                // If no configuration exists for this page, create one with default columns
                if (!pageConfig?.columns) {
                    try {
                        await service.create(
                            effectiveWorkspaceId,
                            pageKey,
                            defaultColumns
                        );
                        return defaultColumns;
                    } catch (error) {
                        console.error('Error creating default config:', error);
                        return defaultColumns;
                    }
                }

                return pageConfig.columns;
            } catch (error) {
                console.error('Error fetching config:', error);
                return defaultColumns;
            }
        },
        enabled: !!effectiveWorkspaceId,
    });

    const { mutate: saveConfiguration } = useMutation({
        mutationFn: async (columns: ColumnDefinition[]) => {
            const response = await service.create(
                effectiveWorkspaceId,
                pageKey,
                columns
            );
            return response.data;
        },
        onMutate: async (newColumns) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries([
                'columnConfig',
                effectiveWorkspaceId,
                pageKey,
            ]);

            // Snapshot the previous value
            const previousColumns = queryClient.getQueryData([
                'columnConfig',
                effectiveWorkspaceId,
                pageKey,
            ]);

            // Optimistically update to the new value
            queryClient.setQueryData(
                ['columnConfig', effectiveWorkspaceId, pageKey],
                newColumns
            );

            // Return a context object with the snapshotted value
            return { previousColumns };
        },
        onError: (err, newColumns, context) => {
            // If the mutation fails, use the context returned from onMutate to roll back
            queryClient.setQueryData(
                ['columnConfig', effectiveWorkspaceId, pageKey],
                context?.previousColumns
            );
        },
        onSettled: () => {
            // Always refetch after error or success to ensure we have the latest data
            queryClient.invalidateQueries([
                'columnConfig',
                effectiveWorkspaceId,
                pageKey,
            ]);
        },
    });

    const resetToDefault = () => {
        saveConfiguration(defaultColumns);
    };

    const updateColumnVisibility = (columnId: string, visible: boolean) => {
        if (!columnConfig) return;

        const updatedColumns = columnConfig.map((col: ColumnDefinition) =>
            col.id === columnId ? { ...col, visible } : col
        );

        saveConfiguration(updatedColumns);
    };

    const updateColumns = (columns: ColumnDefinition[]) => {
        if (!columnConfig) return;
        saveConfiguration(columns);
    };

    const reorderColumns = (startIndex: number, endIndex: number) => {
        if (!columnConfig) return;

        const result = Array.from(columnConfig) as ColumnDefinition[];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        const updatedColumns = result.map((col, index) => ({
            ...col,
            order: index,
        }));

        saveConfiguration(updatedColumns);
    };

    return {
        columns: columnConfig || defaultColumns,
        isLoading,
        updateColumnVisibility,
        updateColumns,
        reorderColumns,
        resetToDefault,
    };
};
