import { CaretSortIcon } from '@radix-ui/react-icons';
import { ColumnDef } from '@tanstack/react-table';
import { Check, DownloadCloud, X } from 'lucide-react';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { WorkspaceModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useSolicitDocuments } from 'src/app/_api_adb2c/workspace/solicit/hooks/use-solicit-documents';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useFileDownloader } from 'src/app/hooks/use-file-downloader';
import { Button } from 'src/components/ui/button';
import { Checkbox } from 'src/components/ui/checkbox';

interface Props {
    supplier?: WorkspaceModel;
}

interface VirtualDocumentModel {
    file: string;
    name: string;
    type: string;
    isPrivate: boolean;
    isVerified: boolean;
}

export const SupplierDocuments = ({ supplier }: Props) => {
    const [searchParams] = useSearchParams({});
    const delegateId = searchParams.get('delegateId') || '';
    const { downloadFiles } = useFileDownloader();

    const { data, isLoading } = useSolicitDocuments(
        supplier?._id || '',
        delegateId
    );

    const handleDownload = async (fileId: string, fileName: string) => {
        try {
            await downloadFiles([fileId], {
                delegateId,
                fileName,
            });
        } catch (error) {
            console.error('Failed to download file:', error);
        }
    };

    const memoizedData = useMemo(() => {
        if (!data?.documents) return [];

        return data.documents.map((doc, index) => {
            const extension = data.extensions?.[index];

            return {
                file: doc.file._id,
                name: extension.name,
                isVerified: doc?.isVerified || false,
                isPrivate: doc?.isPrivate || false,
                type: doc.type,
            } satisfies VirtualDocumentModel;
        });
    }, [data]);

    const columns: ColumnDef<VirtualDocumentModel>[] = [
        {
            id: 'select',
            header: ({ table }) => (
                <Checkbox
                    checked={table.getIsAllPageRowsSelected()}
                    onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(!!value)
                    }
                    aria-label='Select all'
                />
            ),
            cell: ({ row }) => (
                <Checkbox
                    checked={row.getIsSelected()}
                    onCheckedChange={(value) => row.toggleSelected(!!value)}
                    aria-label='Select row'
                />
            ),
            enableSorting: false,
            enableHiding: false,
            size: 0,
        },
        {
            id: 'document name',
            accessorKey: 'documentName',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Document Name</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) => row.name,
        },
        {
            id: 'type',
            accessorKey: 'type',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Type</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            accessorFn: (row) =>
                SupportDocTypeLabel[row.type as SupportDocType],
        },
        {
            id: 'Private',
            accessorKey: 'Private',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Private</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            cell: ({ row }) => {
                return (
                    <span className=''>
                        {row.original.isPrivate ? (
                            <Check size={16} />
                        ) : (
                            <X size={16} />
                        )}
                    </span>
                );
            },
        },
        {
            id: 'verified on',
            accessorKey: 'verified on',
            header: ({ column }) => {
                return (
                    <Button
                        variant='ghost'
                        className='text-xs'
                        onClick={() =>
                            column.toggleSorting(column.getIsSorted() === 'asc')
                        }
                    >
                        <span>Verified</span>
                        <CaretSortIcon
                            className={`inline ${
                                column.getIsSorted() === 'asc'
                                    ? 'rotate-180 transform'
                                    : ''
                            }`}
                        />
                    </Button>
                );
            },
            cell: ({ row }) => {
                return (
                    <span className=''>
                        {row.original.isVerified ? (
                            <Check size={16} />
                        ) : (
                            <X size={16} />
                        )}
                    </span>
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            cell: ({ row }) => {
                return (
                    <div>
                        <DownloadCloud
                            size={16}
                            className='cursor-pointer hover:scale-125'
                            onClick={() => {
                                // download(row.original.file, row.original.name);
                                handleDownload(
                                    row.original.file,
                                    row.original.name
                                );
                            }}
                        />
                    </div>
                );
            },
        },
    ];

    const handleDownloadAll = async () => {
        try {
            const fileIds = memoizedData.map((doc) => doc.file);

            await downloadFiles(fileIds, {
                delegateId,
                fileName: `${supplier?.name || 'supplier'}-documents.zip`,
            });
        } catch (error) {
            console.error('Failed to download all files:', error);
        }
    };

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Documents'
                columns={columns}
                data={memoizedData || []}
                isLoading={isLoading}
                controls={{
                    enableDelegation: false,
                    enableSearch: false,
                }}
                actions={[
                    {
                        label: 'Download All',
                        icon: <DownloadCloud size={16} />,
                        onClick: handleDownloadAll,
                        id: 'download-all',
                    },
                ]}
            />
        </div>
    );
};
