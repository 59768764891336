import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { ChevronDown, ChevronUp, Loader2, Search } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { cn } from 'src/lib/utils';
import { Button } from 'src/components/ui/button';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { toast } from 'sonner';
import { TableDelegationControls } from '../pages/Purchases/reusable-table';
import { Input } from 'src/components/ui/input';

interface TableAction {
    id: string;
    icon?: React.ReactNode;
    label?: string;
    onClick: (e?: React.MouseEvent) => void;
    disabled?: boolean;
    condition?: {
        isAllowed: boolean;
        message?: string;
    };
}

interface TableV3Props<TData> {
    data: TData[];
    columns: ColumnDef<TData, any>[];
    isLoading?: boolean;
    isFetchingNextPage?: boolean;
    hasNextPage?: boolean;
    onLoadMore?: () => void;
    className?: string;
    label?: string;
    actions?: TableAction[];
    controls?: {
        enableDelegation?: boolean;
        enableSearch?: boolean;
    };
    setSearch?: (search: string) => void;
}

const TableHeader = ({
    label,
    actions,
}: {
    label?: string;
    actions?: TableAction[];
}) => {
    if (!label) return null;

    return (
        <div className='flex h-10 w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
            <span className='text-sm font-bold text-white'>{label}</span>
            <div className='flex gap-4'>
                {actions?.map((action) => (
                    <TooltipProvider key={action.id}>
                        <Tooltip>
                            <TooltipTrigger className='bg-red-800'>
                                <Button
                                    variant='ghost'
                                    className='h-full py-2 text-white hover:bg-red-700 hover:text-white hover:opacity-80 hover:shadow'
                                    size='sm'
                                    type='button'
                                    id={action.id}
                                    data-testid={action.id}
                                    disabled={action.disabled}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                            action.condition &&
                                            !action.condition.isAllowed
                                        ) {
                                            toast.error(
                                                action.condition.message ||
                                                    'This action is not allowed'
                                            );
                                            return;
                                        }
                                        action.onClick(e);
                                    }}
                                >
                                    {action.icon}
                                </Button>
                            </TooltipTrigger>
                            {action.label && (
                                <TooltipContent className='border bg-white text-black shadow-lg'>
                                    {action.label}
                                </TooltipContent>
                            )}
                        </Tooltip>
                    </TooltipProvider>
                ))}
            </div>
        </div>
    );
};

const SearchControl = ({
    value,
    onChange,
}: {
    value: string;
    onChange: (value: string) => void;
}) => {
    const [localValue, setLocalValue] = useState(value);
    const debounceTimer = useRef<NodeJS.Timeout>();

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const handleChange = (newValue: string) => {
        setLocalValue(newValue);
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current);
        }
        debounceTimer.current = setTimeout(() => {
            onChange(newValue);
        }, 500);
    };

    return (
        <div className='flex h-8 items-center gap-2 rounded-md border border-gray-200 px-3 py-2 shadow-sm'>
            <Search className='h-4 w-4 text-gray-400' />
            <Input
                value={localValue}
                onChange={(e) => handleChange(e.target.value)}
                placeholder='Search orders...'
                className='border-0 bg-transparent p-0 text-xs shadow-none focus-visible:ring-0'
            />
        </div>
    );
};

export function TableV3<TData>({
    data,
    columns,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    onLoadMore,
    className,
    label,
    actions,
    controls,
    setSearch,
}: TableV3Props<TData>) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [isResizing, setIsResizing] = useState(false);
    const [innerSearch, setInnerSearch] = useState('');

    const tableContainerRef = useRef<HTMLDivElement>(null);
    const observer = useRef<IntersectionObserver>();
    const loadMoreRef = useRef<HTMLDivElement>(null);

    const { enableDelegation, enableSearch } = controls || {};

    const table = useReactTable({
        data,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        defaultColumn: {
            minSize: 80,
            maxSize: 1000,
        },
    });

    useEffect(() => {
        const handleMouseUp = () => setIsResizing(false);
        window.addEventListener('mouseup', handleMouseUp);
        return () => window.removeEventListener('mouseup', handleMouseUp);
    }, []);

    useEffect(() => {
        if (isFetchingNextPage || !hasNextPage) return;

        const element = loadMoreRef.current;
        if (!element) return;

        if (observer.current) observer.current.disconnect();

        observer.current = new IntersectionObserver(
            (entries) => {
                if (
                    entries[0].isIntersecting &&
                    hasNextPage &&
                    !isFetchingNextPage
                ) {
                    onLoadMore?.();
                }
            },
            { threshold: 0.5, rootMargin: '100px' }
        );

        observer.current.observe(element);
        return () => observer.current?.disconnect();
    }, [isFetchingNextPage, hasNextPage, onLoadMore]);

    useEffect(() => {
        setSearch?.(innerSearch);
    }, [innerSearch, setSearch]);

    const { rows } = table.getRowModel();

    return (
        <div className='flex h-full flex-col'>
            {(enableDelegation || enableSearch) && (
                <div id='control-bar' className='mb-4 flex items-center gap-4'>
                    {enableSearch && (
                        <SearchControl
                            value={innerSearch}
                            onChange={setInnerSearch}
                        />
                    )}
                    {enableDelegation && (
                        <TableDelegationControls setSearch={setInnerSearch} />
                    )}
                </div>
            )}

            <div
                className={cn(
                    'relative flex flex-1 flex-col overflow-hidden rounded-md border border-gray-200 bg-white shadow-sm',
                    isResizing && 'cursor-col-resize select-none',
                    className
                )}
            >
                <TableHeader label={label} actions={actions} />

                <div ref={tableContainerRef} className='flex-1 overflow-auto'>
                    <div className='inline-block h-full w-full min-w-[1200px] align-middle'>
                        <div className='sticky top-0 z-10 border-b border-gray-200 bg-gray-50'>
                            <table
                                className='w-full table-fixed caption-bottom text-xs'
                                style={{ width: table.getCenterTotalSize() }}
                            >
                                <colgroup>
                                    {table.getAllColumns().map((column) => (
                                        <col
                                            key={column.id}
                                            style={{
                                                width: column.getSize(),
                                                minWidth: column.getSize(),
                                            }}
                                        />
                                    ))}
                                </colgroup>
                                <thead>
                                    {table
                                        .getHeaderGroups()
                                        .map((headerGroup) => (
                                            <tr
                                                key={headerGroup.id}
                                                className='border-b border-gray-200'
                                            >
                                                {headerGroup.headers.map(
                                                    (header) => (
                                                        <th
                                                            key={header.id}
                                                            className={cn(
                                                                'group relative h-10 select-none whitespace-nowrap bg-gray-100 px-4 text-left align-middle text-xs font-semibold text-gray-900',
                                                                header.column.getCanSort() &&
                                                                    'cursor-pointer hover:bg-gray-200'
                                                            )}
                                                            onClick={header.column.getToggleSortingHandler()}
                                                            style={{
                                                                width: header.getSize(),
                                                            }}
                                                        >
                                                            <div className='flex items-center gap-2'>
                                                                <span className='truncate'>
                                                                    {flexRender(
                                                                        header
                                                                            .column
                                                                            .columnDef
                                                                            .header,
                                                                        header.getContext()
                                                                    )}
                                                                </span>
                                                                {header.column.getCanSort() && (
                                                                    <div className='flex flex-shrink-0 flex-col'>
                                                                        <ChevronUp
                                                                            className={cn(
                                                                                'h-3 w-3 transition-colors',
                                                                                header.column.getIsSorted() ===
                                                                                    'asc'
                                                                                    ? 'text-primary'
                                                                                    : 'text-gray-400'
                                                                            )}
                                                                        />
                                                                        <ChevronDown
                                                                            className={cn(
                                                                                '-mt-1 h-3 w-3 transition-colors',
                                                                                header.column.getIsSorted() ===
                                                                                    'desc'
                                                                                    ? 'text-primary'
                                                                                    : 'text-gray-400'
                                                                            )}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {header.column.getCanResize() && (
                                                                <div
                                                                    onMouseDown={(
                                                                        e
                                                                    ) => {
                                                                        setIsResizing(
                                                                            true
                                                                        );
                                                                        header.getResizeHandler()(
                                                                            e
                                                                        );
                                                                    }}
                                                                    onTouchStart={header.getResizeHandler()}
                                                                    className={cn(
                                                                        'invisible absolute -right-1 top-0 flex h-full w-4 cursor-col-resize touch-none select-none flex-col items-center justify-center gap-1 transition-all',
                                                                        'group-hover:visible group-hover:bg-gray-200/50',
                                                                        header.column.getIsResizing() &&
                                                                            'visible bg-primary/10'
                                                                    )}
                                                                >
                                                                    <div className='h-8 w-[2px] rounded-full bg-gray-300 transition-colors group-hover:bg-gray-400' />
                                                                </div>
                                                            )}
                                                        </th>
                                                    )
                                                )}
                                            </tr>
                                        ))}
                                </thead>
                            </table>
                        </div>

                        <table
                            className='w-full table-fixed caption-bottom text-xs'
                            style={{ width: table.getCenterTotalSize() }}
                        >
                            <colgroup>
                                {table.getAllColumns().map((column) => (
                                    <col
                                        key={column.id}
                                        style={{
                                            width: column.getSize(),
                                            minWidth: column.getSize(),
                                        }}
                                    />
                                ))}
                            </colgroup>
                            <tbody className='[&_tr:last-child]:border-0'>
                                {rows.map((row) => (
                                    <tr
                                        key={row.id}
                                        className='border-b border-gray-200 transition-colors hover:bg-gray-50'
                                    >
                                        {row.getVisibleCells().map((cell) => (
                                            <td
                                                key={cell.id}
                                                className='h-10 whitespace-nowrap px-4 align-middle text-xs text-gray-900'
                                                style={{
                                                    width: cell.column.getSize(),
                                                }}
                                            >
                                                <div className='truncate'>
                                                    {flexRender(
                                                        cell.column.columnDef
                                                            .cell,
                                                        cell.getContext()
                                                    )}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {isLoading && (
                            <div className='absolute inset-0 flex items-center justify-center bg-white/50 backdrop-blur-[1px]'>
                                <Loader2 className='h-8 w-8 animate-spin text-primary' />
                            </div>
                        )}

                        <div ref={loadMoreRef} className='h-4 w-full' />

                        {isFetchingNextPage && (
                            <div className='sticky bottom-0 flex justify-center border-t border-gray-100 bg-white/80 py-2 backdrop-blur-[1px]'>
                                <Loader2 className='h-6 w-6 animate-spin text-primary' />
                            </div>
                        )}
                    </div>
                </div>
                <style>{`
                    .cursor-col-resize * {
                        cursor: col-resize !important;
                    }
                `}</style>
            </div>
        </div>
    );
}
