import { useMemo } from 'react';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { SelectInputV2 } from './SelectInputV2';

interface Props {
    name: string;
    delegateId?: string;
    label?: string;
    readOnly?: boolean;
    tooltip?: string;
}

export const DelegationInput = ({
    name,
    delegateId,
    label,
    readOnly,
    tooltip,
}: Props) => {
    const { data: delegations } = useDelegations(delegateId);

    const delegationOptions = useMemo(
        () =>
            (delegations || [])
                .filter((x) => x?.delegatedTo?._id)
                .map((delegate) => {
                    return {
                        label:
                            delegate?.delegatedTo?.company?.demographics
                                ?.name || '',
                        value: delegate?.delegatedTo?._id || '',
                    };
                }),
        [delegations]
    );

    return (
        <SelectInputV2
            label={label}
            name={name}
            data-testid='delegation-input'
            options={delegationOptions.map((delegate) => ({
                label: delegate.label,
                value: delegate.value,
            }))}
            tooltip={tooltip}
            readOnly={readOnly}
        />
    );
};
