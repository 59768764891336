import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare } from 'lucide-react';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApprovalByApprover } from 'src/app/_api_adb2c/product/product-approval/hooks/use-approval-by-approver';
import { ProductApprovalRequest } from 'src/app/_api_adb2c/product/product-approval/model/product-approval-request';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { TooltipWrapper } from 'src/app/components-v2/tooltip';

interface ProductApprovalItem extends ProductApprovalRequest {
    type: 'Approved' | 'Rejected' | 'Pending';
}

export const ProductApprovals = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const navigate = useNavigate();

    const { data, isLoading } = useApprovalByApprover(delegateId);

    const memoizedData: ProductApprovalItem[] = useMemo(() => {
        return (data || []).map((item) => ({
            ...item,
            type: item.isApproved
                ? 'Approved'
                : item.isRejected
                ? 'Rejected'
                : 'Pending',
        }));
    }, [data]);

    const columns: ColumnDef<ProductApprovalItem>[] = useMemo(() => {
        const columns: ColumnDef<ProductApprovalItem>[] = [
            {
                id: 'createdOn',
                header: 'Requested On',
                accessorFn: (row) => {
                    if (row.lastUpdatedOn) {
                        return DateTime.fromJSDate(
                            new Date(row.lastUpdatedOn)
                        ).toLocaleString(DateTime.DATETIME_MED);
                    }

                    return row.createdOn
                        ? DateTime.fromJSDate(
                              new Date(row.createdOn)
                          ).toLocaleString(DateTime.DATETIME_MED)
                        : '--';
                },
            },
            {
                id: 'productCategory',
                header: 'Product Category',
                accessorFn: (row) => {
                    const isBase = (row.product.category.name as any)?.locales
                        ? true
                        : false;

                    if (isBase) {
                        console.debug('base');
                        const category = row.product.category.name as any;
                        const name = category?.locales?.find(
                            (l: any) => l.localeName === 'en'
                        )?.text;

                        return name || '--';
                    }

                    return row.product.category.name || '--';
                },
            },
            {
                id: 'product',
                header: 'Product',
                accessorFn: (row) => row.product.name,
            },
            {
                id: 'itemNumber',
                header: 'Item Number',
                accessorFn: (row) => {
                    const refernce = row.product?.externalReferences?.find(
                        (ref) => ref.source === 'externalDataId'
                    );
                    return refernce?.value || '--';
                },
            },
            {
                id: 'description',
                header: 'Description',
                accessorFn: (row) => row.product.description || '--',
            },

            {
                id: 'eanNo',
                header: 'EAN No.',
                accessorFn: (row) => row.product.upc || '--',
            },
            {
                id: 'workspaceName',
                header: 'Supplier',
                accessorFn: (row) => row.workspace.name,
            },
            {
                id: 'status',
                header: 'Status',
                accessorFn: (row) =>
                    row.isApproved
                        ? 'Approved'
                        : row.isRejected
                        ? 'Rejected'
                        : 'Pending',
            },
            {
                id: 'reason',
                header: 'Reason',
                accessorFn: (row) => row.rejectReason || '--',
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-4'>
                            <TooltipWrapper
                                triggerClassName='w-auto'
                                content={
                                    <GanttChartSquare
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() => {
                                            navigate(
                                                `/approvals/${row.original.workspace._id}/${row.original._id}`
                                            );
                                        }}
                                    />
                                }
                                tooltipContent='View'
                            ></TooltipWrapper>
                        </div>
                    );
                },
            },
        ];

        return columns;
    }, [navigate]);

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Product Approval Requests'
                columns={columns}
                data={memoizedData || []}
                isLoading={isLoading}
                disabledTooltipColumns={['actions', 'select']}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                groupBy='type'
                groupLabels={{
                    Approved: 'Approved',
                    Rejected: 'Rejected',
                    Pending: 'Pending',
                }}
                actions={[]}
            />
        </div>
    );
};
