import { ColumnDef } from '@tanstack/react-table';
import { Link, Sheet, Trash, Upload } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SalesOrderStatusLabel } from 'src/app/_api_adb2c/sales/sales/enums/sales-order-status.enum';
import {
    EnrichedSalesOrder,
    useSalesOrdersV2,
} from 'src/app/_api_adb2c/sales/sales/hooks/use-sales-orders-v2';
import { SalesOrderModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { TooltipWrapper } from 'src/app/components-v2/tooltip';
import {
    generateOrderTitle,
    generateOrderTitleForList,
    generateOrderTitleForSalesOrder,
} from 'src/app/utils/generate-order-title';
import { Progress } from 'src/components/ui/progress';
import { PurchaseClearCascade } from '../Purchases/DetailsV2/CascadeV2/modals/purchase-clear-cascade';
import { CascadePreview } from './components/CascadePreview';
import { SalesOrderCascadeUpload } from './components/SalesOrderCascadeUpload';
import { SalesOrderLink } from './components/SalesOrderLink';
import { SalesOrderUpload } from './components/SalesOrderUpload';
import { useDocumentCompletion } from './hooks/useDocumentCompletion';
import { useContextStore } from 'src/app/stores/context-store';
import { DateTime } from 'luxon';
import { useRemoveLink } from 'src/app/_api_adb2c/purchase/link/hooks/use-remove-link';

interface DocumentCompletionCellProps {
    order: EnrichedSalesOrder | SalesOrderModel;
}

const DocumentCompletionCell = ({ order }: DocumentCompletionCellProps) => {
    const completion = useDocumentCompletion(order);

    return (
        <TooltipWrapper
            content={
                <div className='w-full'>
                    <Progress value={completion} />
                </div>
            }
            tooltipContent={`${Math.round(completion)}% complete`}
        />
    );
};

export function SalesOrders() {
    const [searchParams, setSearchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data, isLoading, isRefetching } = useSalesOrdersV2(delegateId);

    const isLinkMode = searchParams.get('mode') === 'link';
    const isUploadMode = searchParams.get('mode') === 'upload';
    const isCascadeMode = searchParams.get('mode') === 'cascade';
    const isViewCascadeMode = searchParams.get('mode') === 'view-cascade';
    const isDeleteCascadeMode = searchParams.get('mode') === 'delete-cascade';

    const { workspace: w } = useContextStore();

    const { mutateAsync: removeLink, isLoading: isRemovingLink } =
        useRemoveLink();

    const getOrderTotal = useCallback(
        (order: EnrichedSalesOrder) => {
            const purchase = order.purchase;

            if (purchase) {
                const version = purchase.versions[purchase.versions.length - 1];

                if (version) {
                    const total = version.manifest.reduce((acc, item) => {
                        return acc + item.ppu * item.quantity;
                    }, 0);

                    if (w?.features?.supplyChainLoaderType === 0) {
                        return total.toFixed(2);
                    } else {
                        const formatter = new Intl.NumberFormat('en-US', {
                            style: 'decimal',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });
                        return formatter.format(total);
                    }
                }
            }
        },
        [w?.features?.supplyChainLoaderType]
    );

    const getItemNumber = useCallback((order: EnrichedSalesOrder) => {
        if (!order.purchase) return '--';

        const version =
            order.purchase?.versions[order.purchase?.versions.length - 1];

        if (!version) return '--';
        if (version.manifest.length < 1) return '--';

        const primaryItem = version?.manifest[0];

        const itemNo = primaryItem?.product?.externalReferences?.find(
            (x) => x.source === 'externalDataId'
        )?.value;

        return itemNo || primaryItem?.product?.name;
    }, []);

    const getPurchaseOrderItemCategory = useCallback(
        (order: EnrichedSalesOrder) => {
            if (order.purchase?.versions) {
                const version = order.purchase?.versions.find(
                    (v) => (v.manifest || []).length > 0
                );
                if (version?.manifest) {
                    const manifest = version.manifest[0];
                    if (!manifest) {
                        return '--';
                    } else if (manifest.product) {
                        const purchaseable = manifest.product;
                        if (purchaseable.category) {
                            const categoryBase =
                                w?.features.supplyChainLoaderType === 0
                                    ? purchaseable.category.code
                                          ?.split('::_-')
                                          ?.pop()
                                    : purchaseable.category.code
                                          ?.split('::')
                                          ?.shift();
                            if (categoryBase) {
                                return categoryBase
                                    .split(' ')
                                    .map((i) =>
                                        i
                                            .split('_')
                                            .map(
                                                (j) =>
                                                    j
                                                        .slice(0, 1)
                                                        .toUpperCase() +
                                                    j.slice(1).toLowerCase()
                                            )
                                            .join(' ')
                                    )
                                    .join(' ');
                            } else {
                                return '--';
                            }
                        } else {
                            return '--';
                        }
                    } else {
                        return '--';
                    }
                }
            } else {
                return '--';
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const columns = useMemo<ColumnDef<EnrichedSalesOrder>[]>(
        () => [
            {
                id: 'poNumber',
                header: 'PO Number',
                accessorFn: (row) => generateOrderTitleForSalesOrder(row),
            },
            {
                id: 'case',
                header: 'Case',
                accessorFn: (row) =>
                    row.reference?.find((r) => r.source === 'externalDataId')
                        ?.value,
            },
            {
                id: 'buyer',
                header: 'Buyer',
                accessorFn: (row) => {
                    return row.customer.buyer.name;
                },
            },
            {
                id: 'primaryCategory',
                header: 'Primary Category',
                accessorFn: (row) => {
                    return getPurchaseOrderItemCategory(row);
                },
            },
            {
                id: 'itemNumber',
                header: 'Item Number',
                accessorFn: (row) => {
                    return getItemNumber(row);
                },
            },
            {
                id: 'itemDescription',
                header: 'Item Description',
                accessorFn: (row) => {
                    const version =
                        row.purchase?.versions[
                            row.purchase?.versions.length - 1
                        ];
                    if (!version) return '--';
                    if (version.manifest.length < 1) return '--';

                    const primaryItem = version?.manifest[0];

                    return primaryItem?.product.description || '--';
                },
            },
            {
                id: 'currency   ',
                header: 'Currency',
                accessorFn: (row) => {
                    return row.currency;
                },
            },
            {
                id: 'buyDate',
                header: 'Buy Date',
                accessorFn: (row) => {
                    return DateTime.fromJSDate(
                        new Date(row.createdOn)
                    ).toFormat('yyyy-MM-dd');
                },
            },
            {
                id: 'total',
                header: 'Total',
                accessorFn: (row) => {
                    return getOrderTotal(row);
                },
            },
            {
                id: 'quantity   ',
                header: 'Quantity',
                accessorFn: (row) => {
                    return row.purchase?.versions[
                        row.purchase?.versions.length - 1
                    ]?.manifest.reduce((acc, item) => acc + item.quantity, 0);
                },
            },

            {
                id: 'documentCompletion',
                header: 'Document Completion',
                cell: ({ row }) => (
                    <DocumentCompletionCell order={row.original} />
                ),
            },
            {
                id: 'linkedPurchaseOrders',
                header: 'PO Linked',
                cell: ({ row }) => {
                    return (
                        <TooltipWrapper
                            tooltipContent={
                                <div className='flex min-w-[200px] flex-col gap-2'>
                                    <span className=' text-xs font-bold underline decoration-red-800 underline-offset-4'>
                                        Linked POs
                                    </span>

                                    <div className='flex flex-col gap-1'>
                                        {row.original.links?.map((link) => (
                                            <div
                                                key={link._id}
                                                className='flex items-center justify-between'
                                                onClick={() =>
                                                    removeLink({
                                                        orderId:
                                                            row.original
                                                                .purchaseOrderId,
                                                        purchaseId: link._id,
                                                        delegateId: delegateId,
                                                    })
                                                }
                                            >
                                                {generateOrderTitle(link)}
                                                <Trash
                                                    className='cursor-pointer text-red-500 hover:scale-125'
                                                    size={16}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            content={row.original.links?.length || 0}
                        ></TooltipWrapper>
                    );
                },
            },
            {
                id: 'documents',
                header: 'Documents',
                cell: ({ row }) => {
                    return row.original.documents?.length || 0;
                },
            },
            {
                id: 'cascades',
                header: 'Cascade',
                cell: ({ row }) => {
                    // const traceCount = row.original.traces?.length || 0;
                    const traceCount =
                        row.original.purchase?.relatedPurchaseOrders?.length ||
                        0;
                    return traceCount > 0 ? (
                        <span
                            className='cursor-pointer text-green-500 hover:underline'
                            onClick={() => {
                                setSearchParams((prev) => {
                                    prev.set('mode', 'view-cascade');
                                    prev.set('order', row.original._id);

                                    return prev;
                                });
                            }}
                        >
                            Uploaded
                        </span>
                    ) : (
                        <span className='text-red-500'>Not Uploaded</span>
                    );
                },
            },
            {
                id: 'shipments',
                header: 'Shipments',
                cell: ({ row }) => {
                    return row.original.shippedOn?.length || 0;
                },
            },
            {
                id: 'status',
                header: 'Status',
                accessorFn: (row) => SalesOrderStatusLabel[row.status],
            },
            {
                id: 'actions',
                header: 'Actions',
                enableColumnFilter: false,
                cell: ({ row }) => {
                    return (
                        <div className='flex items-center gap-4'>
                            <TooltipWrapper
                                content={
                                    <Link
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() =>
                                            setSearchParams((prev) => {
                                                prev.set('mode', 'link');
                                                prev.set(
                                                    'order',
                                                    row.original._id
                                                );

                                                return prev;
                                            })
                                        }
                                    />
                                }
                                tooltipContent='Link POs'
                            />

                            <TooltipWrapper
                                content={
                                    <Upload
                                        size={16}
                                        className='cursor-pointer hover:scale-125'
                                        onClick={() =>
                                            setSearchParams((prev) => {
                                                prev.set('mode', 'upload');
                                                prev.set(
                                                    'order',
                                                    row.original._id
                                                );

                                                return prev;
                                            })
                                        }
                                    />
                                }
                                tooltipContent='Upload Documents'
                            />

                            {row.original.traces?.length === 0 ? (
                                <TooltipWrapper
                                    content={
                                        <Sheet
                                            size={16}
                                            className='cursor-pointer hover:scale-125'
                                            onClick={() =>
                                                setSearchParams((prev) => {
                                                    prev.set('mode', 'cascade');
                                                    prev.set(
                                                        'order',
                                                        row.original._id
                                                    );

                                                    return prev;
                                                })
                                            }
                                        />
                                    }
                                    tooltipContent='Upload Cascade'
                                />
                            ) : (
                                <TooltipWrapper
                                    content={
                                        <Trash
                                            size={16}
                                            onClick={() =>
                                                setSearchParams((prev) => {
                                                    prev.set(
                                                        'mode',
                                                        'delete-cascade'
                                                    );
                                                    prev.set(
                                                        'order',
                                                        row.original._id
                                                    );

                                                    return prev;
                                                })
                                            }
                                        />
                                    }
                                    tooltipContent='Delete Cascade'
                                />
                            )}
                        </div>
                    );
                },
            },
        ],
        [
            setSearchParams,
            removeLink,
            delegateId,
            getOrderTotal,
            getPurchaseOrderItemCategory,
            getItemNumber,
        ]
    );

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Received Orders'
                columns={columns}
                data={data || []}
                isLoading={isLoading || isRefetching || isRemovingLink}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                disabledTooltipColumns={[
                    'actions',
                    'documentCompletion',
                    'linkedPurchaseOrders',
                ]}
            />

            {isLinkMode && (
                <SalesOrderLink
                    isOpen={isLinkMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                />
            )}

            {isUploadMode && (
                <SalesOrderUpload
                    isOpen={isUploadMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                />
            )}

            {isCascadeMode && (
                <SalesOrderCascadeUpload
                    isOpen={isCascadeMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                />
            )}

            {isViewCascadeMode && (
                <CascadePreview
                    isOpen={isViewCascadeMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                />
            )}

            {isDeleteCascadeMode && (
                <PurchaseClearCascade
                    open={isDeleteCascadeMode}
                    onClose={() =>
                        setSearchParams((prev) => {
                            prev.delete('mode');
                            prev.delete('order');

                            return prev;
                        })
                    }
                    purchase={
                        data?.find((x) => x._id === searchParams.get('order'))
                            ?.purchase?._id || ''
                    }
                    workspaceId={
                        data?.find((x) => x._id === searchParams.get('order'))
                            ?.purchase?.workspace
                    }
                    deleteParent={false}
                />
            )}
        </div>
    );
}
