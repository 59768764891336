import { zodResolver } from '@hookform/resolvers/zod';
import {
    ColumnDef,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { Plus, Trash } from 'lucide-react';
import { useCallback, useEffect } from 'react';
import { useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { useRulesets } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-rulesets';
import { useUpdateRuleset } from 'src/app/_api_adb2c/workspace/ruleset/hooks/use-update-ruleset';
import {
    WorkspaceRulesetEntryModel,
    WorkspaceRulesetModel,
} from 'src/app/_api_adb2c/workspace/ruleset/ruleset.model';
import {
    SupplyChainNodeType,
    SupplyChainNodeTypeLabel,
} from 'src/app/_api_adb2c/workspace/shared/enum/supply-chain-node-type.enum';
import { DialogV2, StepComponentProps } from 'src/app/components-v2/dialog-v2';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { MultiSelectInputV2 } from 'src/app/components/Form/MultiSelectInputV2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { SelectInputV2 } from 'src/app/components/Form/SelectInputV2';
import { TextInputV2 } from 'src/app/components/Form/TextInputV2';
import { Button } from 'src/components/ui/button';
import { z } from 'zod';

interface Props {
    open: boolean;
    onClose: () => void;
    ruleset: WorkspaceRulesetModel;
}

const documentsSchema = z.object({
    document: z.string(),
    processes: z.array(z.string()),
});

const formSchema = z.object({
    name: z.string(),
    documents: z.array(documentsSchema),
    code: z.string(),
    description: z.string().optional(),
});

export const EditRuleset = ({ open, onClose, ruleset }: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';
    const { data: rulesets } = useRulesets();
    const { mutateAsync, isLoading } = useUpdateRuleset();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: ruleset.name,
            documents: ruleset.rules,
            code: ruleset.code,
            description: ruleset.description,
        },
    });

    const validateRulesetName = (
        ruleset: WorkspaceRulesetModel,
        name: string
    ) => {
        if (ruleset.name === name) {
            return false;
        }

        return rulesets?.find((r) => r.name === name);
    };

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const isExist = validateRulesetName(ruleset, data.name);

        if (isExist) {
            toast.warning(`Ruleset with name ${data.name} already exists`);
            return;
        }

        await mutateAsync({
            id: ruleset._id,
            delegateId: delegateId,
            body: {
                rules: data.documents,
                name: data.name,
                code: data.code,
                description: data.description,
            },
        });

        reset();
    };

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'documents',
    });

    const columns: ColumnDef<WorkspaceRulesetEntryModel>[] = [
        {
            id: 'document',
            header: 'Document Type',
            size: 100,
            cell: ({ row }) => {
                return (
                    <SelectInput
                        name={`documents[${row.index}].document`}
                        options={Object.values(SupportDocType).map((v) => ({
                            label: SupportDocTypeLabel[v],
                            value: v,
                        }))}
                    />
                );
            },
        },
        {
            id: 'processes',
            header: 'Applies To',
            size: 200,
            cell: ({ row }) => {
                return (
                    <MultiSelectInputV2
                        name={`documents[${row.index}].processes`}
                        className='overflow-hidden'
                        options={Object.values(SupplyChainNodeType).map(
                            (value) => ({
                                label: SupplyChainNodeTypeLabel[value],
                                value,
                            })
                        )}
                    />
                );
            },
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 10,
            cell: ({ row }) => {
                return (
                    <Button onClick={() => remove(row.index)} variant='outline'>
                        <Trash className='h-4 w-4' />
                    </Button>
                );
            },
        },
    ];

    // const table = useReactTable({
    //     columns,
    //     data: fields,
    //     getCoreRowModel: getCoreRowModel(),
    // });

    const reset = () => {
        form.reset();
        onClose();
        // setOpen(false);
    };

    return (
        <DialogV2
            open={open}
            onClose={reset}
            title='Edit Ruleset'
            isStepDialog
            onSubmit={onSubmit}
            isLoading={isLoading}
            // size='lg'
            form={form}
            steps={[
                {
                    title: 'Ruleset Information',
                    description: 'Enter the ruleset information',
                    content: (
                        <div>
                            <TextInputV2 name='name' label='Name' />
                            <TextInputV2 name='code' label='Code' />
                            <TextInputV2
                                name='description'
                                label='Description'
                            />
                        </div>
                    ),
                },
                {
                    title: 'Rules',
                    description: 'Enter the rules',
                    content: <RulesetRules />,
                },
            ]}
        ></DialogV2>
    );
};

interface RulesetProps extends Partial<StepComponentProps> {}

export const RulesetRules = ({ setFooterControls }: RulesetProps) => {
    const name = 'documents';
    const form = useFormContext<z.infer<typeof formSchema>>();

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: 'documents',
    });

    console.debug(form.getValues());

    const handleAppendItem = useCallback(() => {
        append({
            document: '',
            processes: [],
        });
    }, [append]);

    useEffect(() => {
        if (!setFooterControls) return;

        setFooterControls(
            <Button
                size='sm'
                variant='outline'
                type='button'
                onClick={handleAppendItem}
            >
                <Plus size={16} />
            </Button>
        );
    }, []);

    const columns: ColumnDef<z.infer<typeof documentsSchema>>[] = [
        {
            id: 'document',
            header: 'Document',
            cell: ({ row }) => {
                return (
                    <SelectInputV2
                        name={`${name}[${row.index}].document`}
                        options={Object.values(SupportDocType).map((v) => ({
                            label: SupportDocTypeLabel[v],
                            value: v,
                        }))}
                    />
                );
            },
        },
        {
            id: 'processes',
            header: 'Applies To',
            cell: ({ row }) => {
                return (
                    <MultiSelectInputV2
                        name={`${name}[${row.index}].processes`}
                        options={Object.values(SupplyChainNodeType).map(
                            (v) => ({
                                label: SupplyChainNodeTypeLabel[v],
                                value: v,
                            })
                        )}
                    />
                );
            },
        },
    ];

    return (
        <TableV2
            columns={columns}
            data={fields}
            // fixedHeight='500px'
            disableTooltips
            tableClassName='table-fixed'
        />
    );
};
