import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';

export class PurchaseStatisticsClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'purchase-statistics');
    }

    list(workspace: string) {
        console.log(`client<purchase-statistics>| list(): Enter`);
        console.log(
            `client<purchase-statistics>| list(): $workspace = ${workspace}`
        );

        return super.get('list', workspace);
    }

    get(workspace: string, item: string) {
        console.log(`client<purchase-statistics>| get(): Enter`);
        console.log(
            `client<purchase-statistics>| get(): $workspace = ${workspace}`
        );
        console.log(`client<purchase-statistics>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    getStatisticsByWorkspace(
        workspace: string,
        startDate: string,
        endDate: string,
        suppliers?: string,
        options?: {
            signal?: AbortSignal;
            substanceCode?: string;
            substanceName?: string;
        }
    ) {
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): Enter`
        );
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): $startDate = ${startDate}`
        );
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): $endDate = ${endDate}`
        );
        console.log(
            `client<purchase-statistics>| getStatisticsByWorkspace(): $suppliers = ${suppliers}`
        );

        if (options?.substanceCode || options?.substanceName) {
            console.log(
                `client<purchase-statistics>| getStatisticsByWorkspace(): filtering for substance: ${
                    options?.substanceCode || options?.substanceName
                }`
            );
        }

        // Prepare query parameters
        const queryParams = [
            workspace,
            startDate,
            endDate,
            suppliers ? encodeURIComponent(suppliers) : '',
            options?.substanceName || '',
            options?.substanceCode || '',
        ];

        // Add substance filtering if provided
        // if (options?.substanceCode) {
        //     queryParams.push(options.substanceCode);
        //     queryParams.push(''); // Empty substanceName
        // } else if (options?.substanceName) {
        //     queryParams.push(''); // Empty substanceCode
        //     queryParams.push(options.substanceName);
        // }

        // if (options?.substanceCode) {
        //     queryParams.push('');
        //     queryParams.push(options.substanceCode); // Empty substanceName
        // } else if (options?.substanceName) {
        //     queryParams.push(options.substanceName);
        //     queryParams.push(''); // Empty substanceCode
        // }

        return super.get('getStatisticsByWorkspace', ...queryParams, {
            signal: options?.signal,
        });
    }

    getSubstanceOrderInformation(
        workspace: string,
        substanceName: string,
        substanceCode: string,
        suppliers?: string[],
        dateRange?: { startDate: string; endDate: string }
    ) {
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): Enter`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $workspace = ${workspace}`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $substanceCode = ${substanceCode}`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $substanceName = ${substanceName}`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $suppliers = ${suppliers?.join(
                ','
            )}`
        );
        console.log(
            `client<purchase-statistics>| getSubstanceOrderInformation(): $dateRange = ${JSON.stringify(
                dateRange
            )}`
        );

        // Construct query parameters for API call
        const queryParams = [];

        // Add suppliers if provided
        if (suppliers && suppliers.length > 0) {
            queryParams.push(encodeURIComponent(suppliers.join(',')));
        } else {
            queryParams.push('');
        }

        // Add date range if provided
        if (dateRange) {
            queryParams.push(dateRange.startDate);
            queryParams.push(dateRange.endDate);
        }

        return super.get(
            'getSubstanceOrderInformation',
            workspace,
            substanceName,
            substanceCode,
            ...queryParams
        );
    }
}
