import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { ApiServer } from 'src/infrastructure/clients/api.server';
import { CreateImpersonationDto } from './requests/create-impersonation.dto';

export class ImpersonationClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'impersonation');
    }

    list() {
        console.log(`client<impersonate>| list(): Enter`);

        return super.get('list');
    }

    create(body: CreateImpersonationDto) {
        console.log(`client<impersonate>| create(): Enter`);
        console.log(
            `client<impersonate>| create(): body = ${JSON.stringify(body)}`
        );

        return super.post('create', body);
    }

    refresh(id: string) {
        console.log(`client<impersonate>| refresh(): Enter`);
        console.log(`client<impersonate>| refresh(): id = ${id}`);

        return super.post(`refresh`, id, {});
    }
}
