import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { WorkspaceModel } from '../_api_adb2c/workspace/workspace/workspace.model';
import { UserModel } from '../_api_adb2c/workspace/users/user.model';

interface ContextState {
    user?: UserModel;
    workspace?: WorkspaceModel;
    token?: string;
    impersonationToken?: string;
    setUser: (user: UserModel) => void;
    setToken: (token: string) => void;
    setWorkspace: (workspace: WorkspaceModel) => void;
    setImpersonationToken: (impersonationToken: string) => void;
}

export const useContextStore = create<ContextState>()(
    persist(
        (set) => ({
            user: undefined,
            workspace: undefined,
            token: undefined,
            impersonationToken: undefined,
            setUser: (user: UserModel) => set({ user }),
            setToken: (token: string) => set({ token }),
            setImpersonationToken: (impersonationToken: string) => {
                set({ impersonationToken });
                sessionStorage.setItem(
                    'impersonationToken',
                    impersonationToken
                );
            },
            setWorkspace: (workspace: WorkspaceModel) => {
                set({ workspace });
                sessionStorage.setItem('workspace', workspace._id);
            },
        }),
        {
            name: 'context-store',
            getStorage: () => sessionStorage,
        }
    )
);
