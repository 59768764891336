import {
    cloneElement,
    isValidElement,
    ReactNode,
    useEffect,
    useState,
    useMemo,
    useCallback,
} from 'react';
import { FieldErrors, UseFormReturn } from 'react-hook-form';
import { Button } from 'src/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from 'src/components/ui/dialog';
import { Form } from 'src/components/ui/form';
import { cn } from 'src/lib/utils';
import ErrorBoundary from './error-boundary';
import { toast } from 'sonner';

interface Props {
    open: boolean;
    onClose: () => void;
    isStepDialog?: boolean;
    title?: ReactNode;
    headerControls?: ReactNode;
    footerControls?: ReactNode;
    hideDefaultFooter?: boolean;
    steps?: StepItem[];
    singleStepContent?: ReactNode;
    onStepChange?: (step: number) => void;
    onFinish?: () => void;
    onSubmit?: (data: any) => void;
    form?: UseFormReturn<any>;
    formName?: string;
    size?: 'sm' | 'md' | 'lg' | 'xl';
    heightClass?: string;
    isLoading?: boolean;
    extraControls?: ReactNode;
    debug?: boolean;
    currentStep?: number;
    onCurrentStepChange?: (step: number) => void;
}

export interface StepItem {
    title: string;
    description?: string;
    content: ReactNode;
    pageControls?: ReactNode;
    hidden?: boolean;
}

export interface StepComponentProps {
    setFooterControls: (controls: ReactNode) => void;
}

function parseErrorMessages(errors: FieldErrors): string {
    const invalidTypeFields: string[] = [];

    const traverseErrors = (errorObj: FieldErrors, path: string[] = []) => {
        for (const key in errorObj) {
            if (errorObj.hasOwnProperty(key)) {
                const error = errorObj[key];
                const currentPath = [...path, key];

                if (
                    error?.type === 'invalid_type' &&
                    typeof error === 'object' &&
                    'type' in error
                ) {
                    invalidTypeFields.push(currentPath.join(' > '));
                    traverseErrors(error as FieldErrors, currentPath);
                }

                if (error?.type === 'custom' && typeof error === 'object') {
                    invalidTypeFields.push(currentPath.join(' > '));
                    traverseErrors(error as FieldErrors, currentPath);
                }
            }
        }
    };

    traverseErrors(errors);

    if (invalidTypeFields.length > 0) {
        const formattedFields = invalidTypeFields.map((field) =>
            field
                .split(' > ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' > ')
        );
        const lastField = formattedFields.pop();
        const fieldMessage =
            formattedFields.length > 0
                ? `${formattedFields.join(', ')}, and ${lastField}`
                : lastField;
        return `${fieldMessage} are required but empty`;
    }

    return '';
}

const DialogWrapper = ({
    form,
    children,
    formName,
    onSubmit,
    onClose,
    setCurrentStep,
}: {
    form?: UseFormReturn<any>;
    formName?: string;
    children: ReactNode;
    onSubmit?: (data: any) => void;
    onClose?: () => void;
    setCurrentStep?: (step: number) => void;
}) => {
    if (form && onSubmit) {
        return (
            <Form {...form}>
                <form
                    id={formName}
                    onSubmit={form.handleSubmit(
                        async (data) => {
                            await onSubmit(data);
                            setCurrentStep?.(1);
                        },
                        (err) => {
                            console.error(`error`, err);
                            console.debug(`form`, form.getValues());
                            toast.error(parseErrorMessages(err));
                        }
                    )}
                >
                    {children}
                </form>
            </Form>
        );
    }

    return <>{children}</>;
};

const sizeVariants = {
    sm: {
        dialog: 'max-w-3xl',
        height: 'h-[500px]',
        stepHeight: 'h-[500px]',
        stepWidth: 'w-56',
    },
    md: {
        dialog: 'max-w-4xl',
        height: 'h-[500px]',
        stepHeight: 'h-[600px]',
        stepWidth: 'w-64',
    },
    lg: {
        dialog: 'max-w-6xl',
        height: 'h-[600px]',
        stepHeight: 'h-[700px]',
        stepWidth: 'w-72',
    },
    xl: {
        dialog: 'max-w-7xl',
        height: 'h-[700px]',
        stepHeight: 'h-[800px]',
        stepWidth: 'w-80',
    },
};

export function DialogV2({
    open,
    onClose,
    isStepDialog = false,
    title = 'Dialog Title',
    headerControls,
    hideDefaultFooter = false,
    steps = [],
    singleStepContent,
    onStepChange,
    form,
    onSubmit,
    onFinish,
    formName = 'step-dialog-form',
    size = 'md',
    heightClass,
    isLoading = false,
    extraControls,
    debug = false,
    currentStep: controlledCurrentStep,
    onCurrentStepChange,
}: Props) {
    const [internalCurrentStep, setInternalCurrentStep] = useState(1);
    const currentStep = controlledCurrentStep ?? internalCurrentStep;
    const [stepFooterControls, setStepFooterControls] =
        useState<ReactNode>(null);

    const filteredSteps = useMemo(() => {
        return steps.filter((x) => !x.hidden);
    }, [steps]);

    useEffect(() => {
        if (currentStep > filteredSteps.length) {
            if (onCurrentStepChange) {
                onCurrentStepChange(1);
            } else {
                setInternalCurrentStep(1);
            }
        }
    }, [filteredSteps.length, currentStep, onCurrentStepChange]);

    const handleStepChange = useCallback(
        (step: number) => {
            if (onCurrentStepChange) {
                onCurrentStepChange(step);
            } else {
                setInternalCurrentStep(step);
            }
            onStepChange?.(step);
        },
        [onCurrentStepChange, onStepChange]
    );

    const renderStepContent = () => {
        if (!filteredSteps.length) return null;
        const step = filteredSteps[currentStep - 1];
        if (!step) return null;

        const content = step.content;
        return isValidElement(content)
            ? cloneElement(content as React.ReactElement<StepComponentProps>, {
                  setFooterControls: setStepFooterControls,
              })
            : content;
    };

    const renderStepIndicator = () => {
        return (
            <div
                className={cn(
                    sizeVariants[size].stepWidth,
                    'border-r border-gray-200 bg-gray-50 p-6'
                )}
            >
                <div className='space-y-6'>
                    {filteredSteps.map((step, index) => {
                        const stepNumber = index + 1;
                        const isActive = stepNumber === currentStep;
                        const isCompleted = stepNumber < currentStep;

                        return (
                            <div
                                key={step?.title}
                                className={cn(
                                    'flex cursor-pointer items-center gap-4',
                                    isActive && 'text-primary',
                                    isCompleted && 'text-gray-500'
                                )}
                                onClick={() => handleStepChange(stepNumber)}
                            >
                                <div
                                    className={cn(
                                        'flex h-6 w-6 flex-none items-center justify-center rounded-full border-2 text-xs',
                                        isActive &&
                                            'border-red-800 bg-red-800 text-white',
                                        isCompleted &&
                                            'border-red-800 bg-red-800 text-white',
                                        !isActive &&
                                            !isCompleted &&
                                            'border-gray-300'
                                    )}
                                >
                                    {isCompleted ? (
                                        <CheckIcon className='h-4 w-4' />
                                    ) : (
                                        <span>{stepNumber}</span>
                                    )}
                                </div>
                                <div className='flex flex-col'>
                                    <span className='text-sm font-medium'>
                                        {step?.title}
                                    </span>
                                    {step?.description && (
                                        <span className='mt-0.5 text-xs text-gray-500'>
                                            {step.description}
                                        </span>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderPageControls = () => {
        const step = filteredSteps[currentStep - 1];

        return step?.pageControls;
    };

    const renderDefaultFooter = () => {
        if (isStepDialog) {
            return (
                <>
                    {filteredSteps.length > 1 && (
                        <Button
                            variant='outline'
                            size='sm'
                            type='button'
                            onClick={(e) => {
                                e.preventDefault();
                                handleStepChange(currentStep - 1);
                            }}
                            disabled={currentStep === 1 || isLoading}
                            className='min-w-[100px]'
                        >
                            Previous
                        </Button>
                    )}

                    {currentStep < filteredSteps.length && (
                        <Button
                            size='sm'
                            type='button'
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleStepChange(currentStep + 1);
                            }}
                            disabled={isLoading}
                            className='min-w-[100px]'
                        >
                            Next
                        </Button>
                    )}

                    {currentStep === filteredSteps.length && !form && (
                        <Button
                            size='sm'
                            type='button'
                            onClick={(e) => {
                                e.preventDefault();
                                onFinish?.();
                            }}
                            className='min-w-[100px]'
                        >
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </Button>
                    )}

                    {currentStep === filteredSteps.length && form && (
                        <Button
                            size='sm'
                            form={formName}
                            type='submit'
                            disabled={isLoading}
                            className='flex min-w-[100px] items-center gap-2'
                        >
                            {isLoading && (
                                <svg
                                    className='h-4 w-4 animate-spin'
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                >
                                    <circle
                                        className='opacity-25'
                                        cx='12'
                                        cy='12'
                                        r='10'
                                        stroke='currentColor'
                                        strokeWidth='4'
                                    />
                                    <path
                                        className='opacity-75'
                                        fill='currentColor'
                                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                    />
                                </svg>
                            )}
                            {isLoading ? 'Submitting...' : 'Submit'}
                        </Button>
                    )}
                </>
            );
        }

        return (
            <>
                <Button
                    variant='outline'
                    size='sm'
                    onClick={onClose}
                    disabled={isLoading}
                    className='min-w-[100px]'
                    type='button'
                >
                    Cancel
                </Button>

                {form && (
                    <Button
                        size='sm'
                        disabled={isLoading}
                        type='submit'
                        className='min-w-[100px]'
                    >
                        {isLoading ? 'Saving...' : 'Save'}
                    </Button>
                )}

                {!form && onFinish && (
                    <Button
                        size='sm'
                        disabled={isLoading}
                        type='button'
                        onClick={(e) => {
                            e.preventDefault();
                            onFinish();
                        }}
                        className='min-w-[100px]'
                    >
                        {isLoading ? 'Saving...' : 'Save'}
                    </Button>
                )}
            </>
        );
    };

    const handleClose = () => {
        if (!controlledCurrentStep) {
            setInternalCurrentStep(1);
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onOpenChange={(value) => {
                if (!value) {
                    handleClose();
                }
            }}
        >
            <ErrorBoundary>
                <DialogContent
                    className={cn(
                        'p-0',
                        isStepDialog
                            ? sizeVariants[size].dialog
                            : sizeVariants[size].dialog,
                        heightClass
                    )}
                >
                    <DialogWrapper
                        form={form}
                        formName={formName}
                        onSubmit={onSubmit}
                        onClose={handleClose}
                        setCurrentStep={
                            onCurrentStepChange
                                ? onCurrentStepChange
                                : setInternalCurrentStep
                        }
                    >
                        <div
                            className={cn(
                                'flex',
                                isStepDialog
                                    ? sizeVariants[size].stepHeight
                                    : sizeVariants[size].height
                            )}
                            tabIndex={-1}
                        >
                            {isStepDialog && renderStepIndicator()}

                            <div className='flex flex-1 flex-col'>
                                <DialogHeader className='border-b p-4 pb-2'>
                                    <div className='flex items-center justify-between'>
                                        <DialogTitle>
                                            {isStepDialog ? (
                                                <div className='flex flex-col gap-1'>
                                                    <span>{title}</span>
                                                    <span className='text-sm font-normal text-gray-500'>
                                                        {
                                                            filteredSteps[
                                                                currentStep - 1
                                                            ]?.title
                                                        }
                                                        {filteredSteps[
                                                            currentStep - 1
                                                        ]?.description && (
                                                            <>
                                                                {' - '}
                                                                {
                                                                    filteredSteps[
                                                                        currentStep -
                                                                            1
                                                                    ]
                                                                        .description
                                                                }
                                                            </>
                                                        )}
                                                    </span>
                                                </div>
                                            ) : (
                                                title
                                            )}
                                        </DialogTitle>
                                        {headerControls && (
                                            <div className='flex items-center gap-2'>
                                                {headerControls}
                                            </div>
                                        )}
                                    </div>
                                </DialogHeader>

                                <div className='max-h-[calc(100vh-200px)] flex-1 overflow-y-auto px-6 py-4'>
                                    {isStepDialog
                                        ? renderStepContent()
                                        : singleStepContent}
                                </div>

                                <DialogFooter className='border-t border-gray-100 p-4'>
                                    <div className='flex w-full items-center justify-between'>
                                        <div className='flex items-center gap-2'>
                                            {stepFooterControls &&
                                                stepFooterControls}
                                            {extraControls && extraControls}
                                            {renderPageControls()}
                                        </div>
                                        <div
                                            className={cn(
                                                'flex items-center gap-2',
                                                stepFooterControls ||
                                                    extraControls ||
                                                    renderPageControls()
                                                    ? 'ml-auto'
                                                    : 'w-full justify-end'
                                            )}
                                        >
                                            {!hideDefaultFooter &&
                                                renderDefaultFooter()}
                                        </div>
                                    </div>
                                </DialogFooter>
                            </div>
                        </div>
                    </DialogWrapper>
                </DialogContent>
            </ErrorBoundary>
        </Dialog>
    );
}

const CheckIcon = ({ className }: { className?: string }) => (
    <svg
        className={className}
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
    >
        <polyline points='20 6 9 17 4 12' />
    </svg>
);
