import { AxiosError } from 'axios';
import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import instance from './axios-client';

/**
 * @class
 * @name ApiServer
 * @description This is the API Server class that generalise the API call flow to save a few steps here and there
 * @notes updated to adapt to the changes needed in the as per API Server Needs
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export abstract class ApiServer<T = any> {
    protected server: any = {};
    protected host: string;

    constructor(
        protected readonly token: string,
        protected readonly configuration: ServiceConfigInterface,
        protected readonly platform: string,
        protected readonly zone: string,
        protected readonly isPublic: boolean = false
    ) {
        console.log(`api-server| constructor(): Enter`);
        console.log(`api-server| constructor(): $token = ${token}`);
        console.log(
            `api-server| constructor(): $configuration = ${JSON.stringify(
                configuration
            )}`
        );
        console.log(
            `api-server| constructor(): $platform = ${JSON.stringify(platform)}`
        );
        console.log(
            `api-server| constructor(): $zone = ${JSON.stringify(zone)}`
        );
        console.log(
            `api-server| constructor(): $server = ${`../config/api/${this.zone}-server.json`}`
        );
        this.server = require(`../config/api/${this.zone}-server.json`);
        this.host = this.prepare(this.platform) ?? this.configuration.apiHost;
    }

    private headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
    };

    protected configure(call: string): string {
        console.log(`api-server| configure(): Enter`);
        console.log(`api-server| configure(): $call = ${call}`);
        console.log(
            `api-server| configure(): $server = ${JSON.stringify(this.server)}`
        );
        const result = this.server[call].path;
        console.log(
            `api-server| configure(): $result = ${JSON.stringify(result)}`
        );
        return result;
    }

    protected prepare(app: string): string | undefined {
        console.log(`api-server| prepare(): Enter`);
        console.log(`api-server| prepare(): $app = ${app}`);
        switch (app) {
            case 'workspaces':
                return this.configuration.workspaceServiceEndpoint;
            case 'purchases':
                return this.configuration.purchaseServiceEndpoint;
            case 'users':
                return this.configuration.userServiceEndpoint;
            case 'products':
                return this.configuration.productServiceEndpoint;
            case 'inventories':
                return this.configuration.inventoryServiceEndpoint;
            case 'orders':
                return this.configuration.salesServiceEndpoint;
            default:
                return this.configuration.apiHost;
        }
    }

    protected setup(url: string, args: any[]) {
        console.log(`api-server| setup(): Enter`);
        console.log(`api-server| setup(): $url = ${JSON.stringify(url)}`);
        console.log(`api-server| setup(): $args = ${JSON.stringify(args)}`);
        let result = url;
        console.log('args', args);
        url.match(/\{.*?\}/g)?.forEach((i) => {
            console.log(`api-server| create(): $url.i = ${JSON.stringify(i)}`);
            const target = i.replace('{', '').replace('}', '');
            if (target !== undefined && target !== '') {
                try {
                    console.log(
                        `api-server| create(): $url.target = ${JSON.stringify(
                            target
                        )}`
                    );
                    const index = Number.parseInt(target);
                    console.log(
                        `api-server| create(): $url.index = ${JSON.stringify(
                            index
                        )}`
                    );
                    console.log(
                        `api-server| create(): $url.replace = ${JSON.stringify(
                            args[index]
                        )}`
                    );
                    if (args.length >= index + 1) {
                        result = result.replace(i, args[index]);
                        console.log(
                            `api-server| create(): $url.result = ${JSON.stringify(
                                result
                            )}`
                        );
                    } else {
                        throw new Error(
                            `Argument expected at position ${index} but not found`
                        );
                    }

                    if (sessionStorage.getItem('session')) {
                        result = result + '?dd=1';
                    }
                } catch (ex) {
                    console.warn(ex);
                }
            }
        });
        return result;
    }

    protected async delete(...args: any[]): Promise<T> {
        console.log(`api-server| delete(): Enter`);
        console.log(`api-server| delete(): $args = ${JSON.stringify(args)}`);
        const command = args.shift();
        const url = this.setup(this.configure(command), args);
        console.log(`api-server| delete(): $url = ${JSON.stringify(url)}`);
        const workspace = sessionStorage.getItem('workspace');
        const response = await instance.delete(this.host + url, {
            headers: { ...this.headers, 'X-T4S-OWI': workspace || '' },
        });
        console.log(
            `api-server| delete(): $response= ${JSON.stringify(response.data)}`
        );
        if (response.status < 300) {
            return response.data;
        } else throw new AxiosError('Unable to complete request');
    }

    protected async extract(...args: any[]) {
        console.log(`api-server| extract(): Enter`);
        console.log(`api-server| extract(): $args = ${JSON.stringify(args)}`);
        const command = args.shift();
        const url = this.setup(this.configure(command), args);
        console.log(`api-server| extract(): $url = ${JSON.stringify(url)}`);
        const workspace = sessionStorage.getItem('workspace');
        const response = await instance.post(this.host + url, {
            headers: { ...this.headers, 'X-T4S-OWI': workspace || '' },
        });
        console.log(
            `api-server| delete(): $response= ${JSON.stringify(response.data)}`
        );
        if (response.status < 300) {
            return response.data;
        } else throw new AxiosError('Unable to complete request');
    }

    protected async get(...args: any[]): Promise<T | T[]> {
        console.log(`api-server| get(): Enter`);
        console.log(`api-server| get(): $args = ${JSON.stringify(args)}`);
        const command = args.shift();
        console.log(`api-server| get(): $args = ${JSON.stringify(args)}`);
        const url = this.setup(this.configure(command), args);
        console.log(`api-server| get(): $url = ${JSON.stringify(url)}`);
        const workspace = sessionStorage.getItem('workspace');

        // Extract signal from the last argument if it's an object with signal property
        const lastArg = args[args.length - 1];
        const config =
            typeof lastArg === 'object' && lastArg?.signal
                ? {
                      signal: lastArg.signal,
                      headers: {
                          ...this.headers,
                          'X-T4S-OWI': workspace || '',
                      },
                  }
                : {
                      headers: {
                          ...this.headers,
                          'X-T4S-OWI': workspace || '',
                      },
                  };

        const response = await instance.get(this.host + url, config);
        console.log(
            `api-server| get(): $response= ${JSON.stringify(response.data)}`
        );
        if (response.status < 300) {
            return response.data;
        } else throw new AxiosError('Unable to complete request');
    }

    protected async post(...args: any[]): Promise<T> {
        console.log(`api-server| post(): Enter`);
        console.log(`api-server| post(): $args = ${JSON.stringify(args)}`);
        let authType: 'apiKey' | 'bearer' = 'bearer';

        // Check if the first argument is the authType
        if (args[0] === 'apiKey' || args[0] === 'bearer') {
            authType = args.shift();
        }

        const command = args.shift();
        const data = args.pop();
        const url = this.setup(this.configure(command), args);
        console.log(`api-server| post(): $url = ${JSON.stringify(url)}`);
        const workspace = sessionStorage.getItem('workspace');
        const response = await instance.post(this.host + url, data, {
            headers: {
                ...this.headers,
                'X-T4S-OWI': workspace || '',
                Authorization:
                    authType === 'apiKey'
                        ? `api-key sq2ehcVE1RJKD4O543AYo2PZY4x6dFAN`
                        : `Bearer ${this.token}`,
            },
        });
        console.log(
            `api-server| create(): $response= ${JSON.stringify(response.data)}`
        );
        if (response.status < 300) {
            return response.data;
        } else throw new AxiosError('Unable to complete request');
    }

    protected async patch(...args: any[]): Promise<T> {
        console.log(`api-server| patch(): Enter`);
        console.log(`api-server| patch(): $args = ${JSON.stringify(args)}`);
        const command = args.shift();
        const data = args.pop();
        const url = this.setup(this.configure(command), args);
        console.log(`api-server| patch(): $url = ${JSON.stringify(url)}`);
        const workspace = sessionStorage.getItem('workspace');
        const response = await instance.patch(this.host + url, data, {
            headers: { ...this.headers, 'X-T4S-OWI': workspace || '' },
        });
        console.log(
            `api-server| patch(): $response= ${JSON.stringify(response.data)}`
        );
        if (response.status < 300) {
            return response.data;
        } else throw new AxiosError('Unable to complete request');
    }

    protected async put(...args: any[]): Promise<T> {
        console.log(`api-server| put(): Enter`);
        console.log(`api-server| put(): $args = ${JSON.stringify(args)}`);
        const command = args.shift();
        const data = args.pop();
        const url = this.setup(this.configure(command), args);
        console.log(url);
        console.log('this is my url');
        console.log(`api-server| put(): $url = ${JSON.stringify(url)}`);
        const workspace = sessionStorage.getItem('workspace');
        const response = await instance.put(this.host + url, data, {
            headers: { ...this.headers, 'X-T4S-OWI': workspace || '' },
        });
        console.log(
            `api-server| put(): $response= ${JSON.stringify(response.data)}`
        );
        if (response.status < 300) {
            return response.data;
        } else throw new AxiosError('Unable to complete request');
    }
}
