import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { getMenuList } from 'src/app/router/menu-list';
import { useContextStore } from 'src/app/stores/context-store';
import { NavigationButton } from '../navigation/navigation-button';
import { CollapsibleNavigationButton } from '../navigation/collapsible-navigation-button';

export function ExpandedMenu() {
    const location = useLocation();
    const { instance } = useMsal();
    const { user } = useContextStore();
    const menuList = getMenuList(
        location.pathname,
        instance,
        user?.contact?.email
    );

    return (
        <nav className='h-full w-full'>
            <ul className='flex h-full flex-col items-start justify-between space-y-1 px-2'>
                {menuList.map(({ groupLabel, menus }, index) => (
                    <li key={index} className='w-full'>
                        <p className='max-w-[248px] truncate pb-2 text-xs font-medium text-muted-foreground'>
                            {groupLabel}
                        </p>

                        {menus.map((menu, index) => {
                            const {
                                href,
                                label,
                                active,
                                icon: Icon,
                                onClick,
                            } = menu;

                            if (!menu.submenus || menu.submenus.length === 0) {
                                return (
                                    <NavigationButton
                                        href={href}
                                        label={label}
                                        active={active || false}
                                        Icon={Icon}
                                        onClick={onClick}
                                    ></NavigationButton>
                                );
                            }

                            return (
                                <CollapsibleNavigationButton
                                    Icon={Icon}
                                    label={label}
                                    active={active || false}
                                    submenus={menu.submenus || []}
                                    onClick={onClick}
                                ></CollapsibleNavigationButton>
                            );
                        })}
                    </li>
                ))}
            </ul>
        </nav>
    );
}
