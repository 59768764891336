import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';
import { toast } from 'sonner';
import { FileCreateDataObject } from 'src/app/_api_adb2c/attachment/file-create-data-object';
import { useAttachmentService } from 'src/app/_api_adb2c/attachment/hooks/use-attachment-service';
import {
    ITranslatedValue,
    ProductCategoriesModel,
} from 'src/app/_api_adb2c/product/category/category.model';
import { useCategoryService } from 'src/app/_api_adb2c/product/category/hooks/use-category-service';
import { useCreateProduct } from 'src/app/_api_adb2c/product/product/hooks/use-create-product';
import { useProductService } from 'src/app/_api_adb2c/product/product/hooks/use-product-service';
import {
    IndustryType,
    IProductProcessGroup,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductUom } from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { useCreatePurchase } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-create-purchase';
import { usePurchaseService } from 'src/app/_api_adb2c/purchase/purchase/hooks/use-purchase-service';
import { ExternalReferenceModel } from 'src/app/_api_adb2c/purchase/purchase/models/external-reference.model';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { useSupplierService } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-supplier-service';
import { SupplierModel } from 'src/app/_api_adb2c/purchase/suppliers/models/supplier.model';
import { useSupplierConnect } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-connect';
import { useSupplierInvite } from 'src/app/_api_adb2c/workspace/account/hooks/use-supplier-invite';
import { useDelegationService } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegation-service';
import { useSearchService } from 'src/app/_api_adb2c/workspace/search/hooks/use-search-service';
import { UserCreateDataObject } from 'src/app/_api_adb2c/workspace/users/user-create-data-object';
import { useWorkspaceService } from 'src/app/_api_adb2c/workspace/workspace/hooks/use-workspace-service';
import { WorkspaceModel } from 'src/app/_api_adb2c/workspace/workspace/workspace.model';
import { AttachmentCreateParams } from 'src/app/components-v2/dropzone';
import { useContextStore } from 'src/app/stores/context-store';
import countries from 'src/infrastructure/config/data/countries.json';
import { read, utils } from 'xlsx';

export interface ImportErrorMessage {
    rowNum: number;
    message: string;
}

export interface BaseImportHookResult {
    isLoading: boolean;
    findOrCreatePartner: (
        workspaceId: string,
        vendorName: string,
        data: any,
        workspace: WorkspaceModel,
        contactInfo?: {
            email: string;
            firstName: string;
            lastName: string;
        }
    ) => Promise<{
        workspaceId: string;
        supplierId: string;
    }>;
    findOrCreateCategory: (
        workspaceId: string,
        item: any,
        parentCode?: string,
        parentWorkspace?: string
    ) => Promise<ProductCategoriesModel>;
    findOrCreateProduct: (
        workspaceId: string,
        item: any,
        categoryId: string
    ) => Promise<any>;
    createPurchaseOrder: (
        workspaceId: string,
        supplierId: string,
        item: any,
        product: { id: string; cost: number },
        purchaseOrder: PurchaseModel,
        parentPurchase?: PurchaseModel
    ) => Promise<PurchaseModel>;
    processFile: <T>(
        file: File,
        sheetName: string,
        validateFn: (
            data: T[],
            errorMessages: ImportErrorMessage[]
        ) => Promise<void>,
        processFn: (
            data: T[],
            purchaseOrder: PurchaseModel,
            workspace: WorkspaceModel
        ) => Promise<void>,
        workspaceId: string,
        purchaseOrderId: string
    ) => Promise<T[]>;
    uploadFile: <T>(
        file: AttachmentCreateParams,
        workspaceId: string,
        purchaseOrderId: string
    ) => Promise<void>;
    services: {
        workspaceService: ReturnType<typeof useWorkspaceService>['service'];
        purchaseService: ReturnType<typeof usePurchaseService>['service'];
        categoryService: ReturnType<typeof useCategoryService>['service'];
        productService: ReturnType<typeof useProductService>['service'];
        supplierService: ReturnType<typeof useSupplierService>['service'];
        searchService: ReturnType<typeof useSearchService>['service'];
        delegationService: ReturnType<typeof useDelegationService>['service'];
        attachmentService: ReturnType<typeof useAttachmentService>['service'];
    };
    mutations: {
        createProduct: ReturnType<typeof useCreateProduct>['mutateAsync'];
        invite: ReturnType<typeof useSupplierInvite>['mutateAsync'];
        connect: ReturnType<typeof useSupplierConnect>['mutateAsync'];
        createPurchase: ReturnType<typeof useCreatePurchase>['mutateAsync'];
    };
    store: ReturnType<typeof useContextStore>;
    client: ReturnType<typeof useQueryClient>;
}

interface BaseCategoryName {
    locales: Array<{
        localeName: string;
        text: string;
    }>;
}

interface BaseCategory {
    _id: string;
    code?: string;
    createdOn?: Date;
    defaultUnitOfMeasurement: string;
    description?: string;
    industry: IndustryType;
    lastUpdatedOn?: Date;
    name: BaseCategoryName;
    processes?: IProductProcessGroup[];
    translatedName?: ITranslatedValue[];
    workspace: WorkspaceModel;
}

interface ProcessFileOptions<T> {
    file: File;
    sheetName: string;
    validateFn: (
        data: T[],
        errorMessages: ImportErrorMessage[]
    ) => Promise<void>;
    processFn: (
        data: T[],
        purchaseOrder: PurchaseModel,
        workspace: WorkspaceModel
    ) => Promise<void>;
    workspaceId: string;
    purchaseOrderId: string;
}

const formatValidationErrors = (
    errorMessages: ImportErrorMessage[]
): string => {
    const formattedErrors = errorMessages
        .map((err) => `Row ${err.rowNum}: ${err.message}`)
        .join('\n');

    return `Found ${errorMessages.length} validation error${
        errorMessages.length > 1 ? 's' : ''
    } in the import file:\n\n${formattedErrors}`;
};

const parseExcelData = <T>(buffer: ArrayBuffer, sheetName: string): T[] => {
    const wb = read(buffer, { type: 'buffer' });
    const ws = wb.Sheets[sheetName];

    if (!ws) {
        throw new Error(
            `Sheet "${sheetName}" not found in the Excel file. Please check the template format.`
        );
    }

    const rawData: T[] = utils.sheet_to_json(ws);

    if (rawData.length === 0) {
        throw new Error(
            'The uploaded file appears to be empty. Please check the file content.'
        );
    }

    return rawData
        .filter((row: any) =>
            Object.values(row).some(
                (value) => value !== undefined && value !== ''
            )
        )
        .map((row: any, index) => ({
            ...row,
            __rowNum__: index + 2,
        }));
};

const invalidateQueries = (client: ReturnType<typeof useQueryClient>) => {
    const queries = ['purchaseTrace', 'purchase', 'order', 'purchase-reverse'];
    queries.forEach((query) => {
        client.invalidateQueries({ queryKey: [query] });
    });
};

export function useImportBase(): BaseImportHookResult {
    const [isLoading, setIsLoading] = useState(false);

    const { service: workspaceService } = useWorkspaceService();
    const { service: purchaseService } = usePurchaseService();
    const { service: categoryService } = useCategoryService();
    const { service: productService } = useProductService();
    const { service: supplierService } = useSupplierService();
    const { service: searchService } = useSearchService();
    const { service: delegationService } = useDelegationService();
    const { service: attachmentService } = useAttachmentService();

    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: invite } = useSupplierInvite();
    const { mutateAsync: connect } = useSupplierConnect();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const store = useContextStore();
    const client = useQueryClient();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const findPartner = async (
        workspaceId: string,
        name: string,
        registrationNumber: string
    ) => {
        const partners: SupplierModel[] = await supplierService.list(
            workspaceId,
            '1'
        );

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller?.name?.toLocaleLowerCase() ===
                    name?.toLocaleLowerCase() ||
                partner.seller?.registrationNumber?.toLocaleLowerCase() ===
                    registrationNumber?.toLocaleLowerCase()
            );
        });

        return existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner.seller?._id || '',
                  supplierId: existingPartner._id || '',
              }
            : undefined;
    };

    const findOrCreatePartner = async (
        workspaceId: string,
        vendorName: string,
        data: any,
        parentWorkspace: WorkspaceModel,
        contactInfo?: {
            email: string;
            firstName: string;
            lastName: string;
        }
    ) => {
        const registrationNumber = data[
            'Factory License Number (Material Supplier)'
        ]
            ?.toString()
            ?.trim();

        const existingPartner = await findPartner(
            workspaceId,
            vendorName,
            registrationNumber
        );

        if (existingPartner) {
            return existingPartner;
        }

        // Create Partner
        const workspaces: WorkspaceModel[] =
            await workspaceService.searchByCompanyName(
                encodeURIComponent(vendorName)
            );

        let workspace = workspaces.find(
            (x) => x.company?.demographics.name === vendorName
        );

        if (!workspace) {
            const country = countries.find(
                (x) =>
                    x.name.toLocaleLowerCase() ===
                    data['Factory Country (Material Supplier)']
                        .toLocaleLowerCase()
                        ?.toString()
                        ?.trim()
            )?.['alpha-2'];

            workspace = await searchService.searchByRegistrationNumber(
                registrationNumber,
                country || ''
            );
        }

        if (workspace) {
            const delegation = await delegationService.list(workspaceId, '1');
            const isExist = delegation.find(
                (x) => x.delegatedTo._id === workspace?._id
            );

            if (!isExist) {
                await delegationService.create(
                    workspaceId,
                    {
                        delegatedTo: workspace._id,
                    },
                    '1'
                );
            }

            await connect({
                delegate: workspaceId,
                supplier: workspace._id,
                delegateId: workspaceId,
                diversion: '1',
            });
        } else {
            const contact: UserCreateDataObject[] = [];

            if (store.user) {
                contact.push({
                    contact: {
                        email: store.user.contact.email,
                        firstName: store.user.contact.firstName,
                        lastName: store.user.contact.lastName,
                        device: {},
                    },
                });
            }

            if (contactInfo?.email && contactInfo?.firstName) {
                contact.push({
                    contact: {
                        email: contactInfo.email,
                        firstName: contactInfo.firstName,
                        lastName: contactInfo.lastName,
                        device: {},
                    },
                });
            }

            const country = countries.find(
                (x) =>
                    x.name.toLocaleLowerCase() ===
                    data['Factory Country (Material Supplier)']
                        .toLocaleLowerCase()
                        ?.toString()
                        ?.trim()
            )?.['alpha-2'];

            await invite({
                body: {
                    company: data['Factory Name (Material Supplier)']
                        ?.toString()
                        ?.trim(),
                    registrationNumber,
                    country: country || '',
                    contact: contact.length > 0 ? contact : undefined,
                },
                delegateId: workspaceId,
                diversion: '1',
            });
        }

        await delay(2000);

        const partner = await findPartner(
            workspaceId,
            vendorName,
            registrationNumber
        );

        if (!partner) {
            throw new Error(`Failed to create partner: ${vendorName}`);
        }

        return partner;
    };

    const DEBUG_PREFIX = '[ImportBase]';

    const findOrCreateCategory = async (
        workspaceId: string,
        item: any,
        parentCode?: string,
        parentWorkspace?: string
    ) => {
        console.debug(
            `${DEBUG_PREFIX} Finding/Creating category for workspace: ${workspaceId}`
        );

        let parentCategory;
        let categoryName =
            item?.['Main Category [Optional]'] || 'Placeholder Category';
        console.debug(
            `${DEBUG_PREFIX} Category name to search: ${categoryName}`
        );

        const baseCategories = await categoryService.loadBaseCategories('1');
        console.debug(
            `${DEBUG_PREFIX} Loaded ${baseCategories.length} base categories`
        );

        // First check if it matches any base category
        const matchingBaseCategory = baseCategories.find(
            (category: BaseCategory) => category.code === categoryName
        );

        if (matchingBaseCategory) {
            console.debug(
                `${DEBUG_PREFIX} Found matching base category: ${matchingBaseCategory._id}`
            );
            return matchingBaseCategory;
        }

        const existingCategories = await categoryService.search(
            workspaceId,
            encodeURIComponent(categoryName),
            '1'
        );
        console.debug(
            `${DEBUG_PREFIX} Found ${existingCategories.length} existing categories matching name`
        );

        let category: ProductCategoriesModel;

        if (existingCategories.length === 0) {
            console.debug(
                `${DEBUG_PREFIX} Creating new category: ${categoryName}`
            );
            category = await categoryService.create(
                workspaceId,
                {
                    code: categoryName,
                    defaultUnitOfMeasurement: ProductUom.PCS,
                    name: categoryName,
                    description: `Category created from template - ${categoryName}`,
                    industry: IndustryType.OTHERS,
                    workspace: workspaceId,
                },
                '1'
            );
        } else {
            console.debug(
                `${DEBUG_PREFIX} Using existing category: ${existingCategories[0]._id}`
            );
            category = existingCategories[0];
        }

        return category;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: any,
        categoryId: string
    ) => {
        const name = item['Product Name']?.toString()?.trim();
        let product = await productService.searchByName(workspaceId, name);

        if (!product) {
            const externalReferences = item['Item Number [Optional]']
                ? [
                      {
                          source: 'externalDataId',
                          value: item['Item Number [Optional]'],
                      },
                  ]
                : [];

            product = await createProduct({
                delegateId: workspaceId,
                body: {
                    category: categoryId,
                    externalReferences,
                    name,
                    description: '',
                    unitOfMeasurement: ProductUom.PCS,
                    loaderType: store.workspace?.supplyChainLoaderType || 0,
                    enabled: true,
                    tags: [],
                    workspace: workspaceId,
                    measureValue: 0,
                },
                diversion: '1',
            });

            await delay(2000);
        }

        return product;
    };

    const createPurchaseOrder = async (
        workspaceId: string,
        supplierId: string,
        item: {
            processes: string[];
            reference: ExternalReferenceModel[];
            nature: string;
        },
        product: { id: string; cost: number },
        purchaseOrder: PurchaseModel,
        parentPurchase?: PurchaseModel
    ) => {
        const reference: ExternalReferenceModel[] = [];

        if (item.reference && item.reference.length > 0) {
            reference.push(...item.reference);
        }

        const purchase = await createPurchase({
            body: {
                supplier: supplierId,
                currency: parentPurchase?.currency || 'USD',
                items: [
                    {
                        product: product.id,
                        ppu: product.cost,
                        quantity: 1,
                        unit: ProductUom.PCS,
                    },
                ],
                rules: purchaseOrder.rules?.[0]
                    ? [purchaseOrder.rules?.[0]]
                    : [],
                reference,
                shipToAddress: 'Filler Address',
                parent: parentPurchase?._id,
                processes: item.processes,
                nature: item.nature,
            },
            delegateId: workspaceId,
            diversion: '1',
        });

        return purchaseService.get(workspaceId, purchase.id, '1');
    };

    const processFile = async <T>(
        file: File,
        sheetName: string,
        validateFn: (
            data: T[],
            errorMessages: ImportErrorMessage[]
        ) => Promise<void>,
        processFn: (
            data: T[],
            purchaseOrder: PurchaseModel,
            workspace: WorkspaceModel
        ) => Promise<void>,
        workspaceId: string,
        purchaseOrderId: string
    ): Promise<T[]> => {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const fileReader = new FileReader();

            fileReader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const data = parseExcelData<T>(buffer, sheetName);

                    const [workspace, purchaseOrder] = await Promise.all([
                        workspaceService.get(workspaceId, '1'),
                        purchaseService.get(workspaceId, purchaseOrderId, '1'),
                    ]);

                    const errorMessages: ImportErrorMessage[] = [];
                    await validateFn(data, errorMessages);

                    if (errorMessages.length > 0) {
                        const errorSummary =
                            formatValidationErrors(errorMessages);
                        throw new Error(errorSummary);
                    }

                    await processFn(data, purchaseOrder, workspace);
                    invalidateQueries(client);

                    resolve(data);
                    toast.success(
                        'Successfully imported the template, please refresh the page'
                    );
                } catch (error) {
                    const err = error as Error;
                    const errorMessage = err.message.includes(
                        'validation error'
                    )
                        ? err.message
                        : `Failed to import the template: ${err.message}`;

                    toast.error(errorMessage, {
                        duration: 6000,
                        style: { maxWidth: '500px', whiteSpace: 'pre-line' },
                    });
                    // reject(err);

                    reject();
                } finally {
                    setIsLoading(false);
                }
            };

            fileReader.onerror = (error) => {
                toast.error('Failed to read the file. Please try again.');
                reject(error);
            };

            fileReader.readAsArrayBuffer(file);
        });
    };

    const uploadFile = async <T>(
        file: AttachmentCreateParams,
        workspaceId: string,
        purchaseOrderId: string
    ) => {
        const request: FileCreateDataObject = {
            contentType: file.file.type,
            size: file.file.size,
            name: file.file.name,
            genre: 'temporary',
            uploadedBy: store.user?._id || '',
            workspace: workspaceId,
        };
        const upload = await attachmentService.upload(
            workspaceId,
            'salesOrder',
            '1',
            request
        );

        const blob = new Blob([file.file], { type: file.file.type });

        await axios.put(upload.token, blob, {
            headers: {
                'Content-Type': file.file.type,
                'x-ms-blob-type': 'BlockBlob',
                'Content-Length': file.file.size,
            },
        });
        const fileId = upload.attachment?.assetName
            ?.split('/')
            .at(-1)
            ?.split('.')
            .slice(0, -1)
            .join('.');
        console.log(`uploadFile() fileId: ${JSON.stringify(fileId)}`);

        const exist = await purchaseService.get(workspaceId, purchaseOrderId);
        Object.assign(exist, {
            cascadeFileId: fileId,
        });
        await purchaseService.update(workspaceId, purchaseOrderId, exist);
    };

    return {
        isLoading,
        findOrCreatePartner,
        findOrCreateCategory,
        findOrCreateProduct,
        createPurchaseOrder,
        uploadFile,
        processFile,
        services: {
            workspaceService,
            purchaseService,
            categoryService,
            productService,
            supplierService,
            searchService,
            delegationService,
            attachmentService,
        },
        mutations: {
            createProduct,
            invite,
            connect,
            createPurchase,
        },
        store,
        client,
    };
}
