import { Download, Trash, Upload } from 'lucide-react';
import { useCallback, useState, useEffect } from 'react';
import { Select } from 'src/app/components-v2/select';
import { SalesOrderDocModel } from 'src/app/_api_adb2c/sales/sales/models/sales-order-doc.model';

interface OptionalFileWithType {
    file: File;
    preview?: string;
    documentType?: string;
    existingFile?: SalesOrderDocModel;
}

interface NewOptionalDocumentsSectionProps {
    availableTypes: { value: string; label: string }[];
    onUpload: (files: OptionalFileWithType[]) => void;
    existingFiles?: OptionalFileWithType[];
    onRemoveExisting?: (file: SalesOrderDocModel) => void;
    onDownload?: (file: SalesOrderDocModel) => void;
}

export const NewOptionalDocumentsSection: React.FC<
    NewOptionalDocumentsSectionProps
> = ({
    availableTypes,
    onUpload,
    existingFiles = [],
    onRemoveExisting,
    onDownload,
}) => {
    const [files, setFiles] = useState<OptionalFileWithType[]>([]);

    // Initialize files when existingFiles prop changes
    useEffect(() => {
        console.log('Received existing files:', existingFiles);
        setFiles(existingFiles);
    }, [existingFiles]);

    const updateParent = useCallback(
        (newFiles: OptionalFileWithType[]) => {
            console.log('Updating parent with files:', newFiles);
            onUpload(newFiles);
        },
        [onUpload]
    );

    const handleFileSelect = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const selectedFiles = event.target.files;
            if (!selectedFiles) return;

            const newFiles: OptionalFileWithType[] = Array.from(
                selectedFiles
            ).map((file) => ({
                file,
                preview: URL.createObjectURL(file),
                documentType:
                    availableTypes[0]?.value || availableTypes[0]?.label || '',
            }));

            setFiles((prev) => {
                const updatedFiles = [...prev, ...newFiles];
                updateParent(updatedFiles);
                return updatedFiles;
            });
        },
        [availableTypes, updateParent]
    );

    const handleTypeChange = useCallback(
        (index: number, type: string) => {
            setFiles((prev) => {
                const newFiles = [...prev];
                newFiles[index] = {
                    ...newFiles[index],
                    documentType: type || '',
                };
                updateParent(newFiles);
                return newFiles;
            });
        },
        [updateParent]
    );

    const handleRemove = useCallback(
        (index: number) => {
            setFiles((prev) => {
                const newFiles = [...prev];
                const fileToRemove = newFiles[index];

                // If it's an existing file, call onRemoveExisting
                if (fileToRemove.existingFile && onRemoveExisting) {
                    onRemoveExisting(fileToRemove.existingFile);
                }

                // Clean up preview URL if it exists
                const previewUrl = newFiles[index]?.preview;
                if (previewUrl) {
                    URL.revokeObjectURL(previewUrl);
                }

                newFiles.splice(index, 1);
                updateParent(newFiles);
                return newFiles;
            });
        },
        [onRemoveExisting, updateParent]
    );

    const handleDownload = useCallback(
        (file: SalesOrderDocModel) => {
            if (onDownload) {
                onDownload(file);
            }
        },
        [onDownload]
    );

    return (
        <div className='flex flex-col gap-6'>
            <div className='flex items-center justify-between'>
                <h3 className='text-xs font-semibold text-gray-900'>
                    Optional Documents
                </h3>
                <div className='flex items-center gap-2'>
                    <label className='inline-flex cursor-pointer items-center gap-2 rounded-md bg-blue-600 px-4 py-2 text-xs font-medium text-white hover:bg-blue-700'>
                        <Upload className='h-4 w-4' />
                        <input
                            type='file'
                            multiple
                            className='hidden'
                            onChange={handleFileSelect}
                            accept='.pdf,.png,.jpg,.jpeg,.doc,.docx'
                        />
                        Add Documents
                    </label>
                </div>
            </div>

            {/* Upload Instructions */}
            <div className='rounded-lg bg-gray-50 p-4 text-xs text-gray-600'>
                <p>
                    Supported file types: PDF, PNG, JPG, DOC, DOCX (max 10MB per
                    file)
                </p>
            </div>

            {/* Files List */}
            <div className='space-y-3'>
                {files.map((file, index) => (
                    <div
                        key={index}
                        className='flex items-center gap-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all hover:shadow-md'
                    >
                        <div className='min-w-0 flex-1'>
                            <p className='truncate text-xs font-medium text-gray-900'>
                                {file.existingFile
                                    ? file.existingFile.file.originalName
                                    : file.file.name}
                            </p>
                            <p className='mt-1 text-xs text-gray-500'>
                                {file.existingFile
                                    ? `${(
                                          file.existingFile.file.size /
                                          1024 /
                                          1024
                                      ).toFixed(2)} MB`
                                    : `${(file.file.size / 1024 / 1024).toFixed(
                                          2
                                      )} MB`}
                            </p>
                        </div>
                        <div className='w-64'>
                            <Select
                                value={file.documentType || ''}
                                options={availableTypes}
                                setValue={(value) =>
                                    handleTypeChange(index, value)
                                }
                                placeholder='Select document type'
                            />
                        </div>
                        <div className='flex items-center gap-2'>
                            {file.existingFile && onDownload && (
                                <button
                                    onClick={() =>
                                        handleDownload(file.existingFile!)
                                    }
                                    className='flex items-center gap-1 rounded-md px-3 py-1.5 text-xs font-medium text-blue-600 hover:bg-blue-50'
                                >
                                    <Download className='h-4 w-4' />
                                </button>
                            )}
                            <button
                                onClick={() => handleRemove(index)}
                                className='flex items-center gap-1 rounded-md px-3 py-1.5 text-xs font-medium text-red-600 hover:bg-red-50'
                            >
                                <Trash className='h-4 w-4' />
                            </button>
                        </div>
                    </div>
                ))}

                {files.length === 0 && (
                    <div className='flex flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 p-8'>
                        <Upload className='mb-4 h-12 w-12 text-gray-400' />
                        <p className='text-xs font-medium text-gray-900'>
                            No optional documents added
                        </p>
                        <p className='mt-1 text-xs text-gray-500'>
                            Click "Add Documents" to upload optional documents
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};
