import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    OnChangeFn,
    RowSelectionState,
    SortingState,
    Table as TanstackTable,
    useReactTable,
} from '@tanstack/react-table';
import { Loader2, Search } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDelegations } from 'src/app/_api_adb2c/workspace/delegations/hooks/use-delegations';
import { Select } from 'src/app/components-v2/select';
import { useDebounce } from 'src/app/utils/use-debounce';
import { Button } from 'src/components/ui/button';
import { Input } from 'src/components/ui/input';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'src/components/ui/table';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { cn } from 'src/lib/utils';

interface Controls {
    enableSearch?: boolean;
    enableDelegation?: boolean;
}

interface Actions {
    id: string;
    icon: React.ReactNode;
    onClick: () => void;
}

interface ReusableTableProps<TData, TSubRowField extends keyof TData, TValue> {
    columns: ColumnDef<TData, TValue>[];
    data: TData[];
    className?: string;
    headerClassName?: string;
    subRowsField?: TSubRowField;
    title?: string;
    loading?: boolean;
    controls?: Controls;
    actions?: Actions[];
    setRowSelection?: OnChangeFn<RowSelectionState>;
    rowSelection?: RowSelectionState;
    onLoadMore?: () => void;
    hasMore?: boolean;
}

export function ReusableTable<TData, TSubRowField extends keyof TData, TValue>({
    columns,
    data,
    className,
    headerClassName,
    subRowsField,
    title,
    loading = false,
    actions,
    controls,
    setRowSelection,
    rowSelection,
}: ReusableTableProps<TData, TSubRowField, TValue>) {
    const divRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);
    const [tableHeight, setTableHeight] = useState('400px');
    const [sorting, setSorting] = useState<SortingState>([]);
    const [expanded, setExpanded] = useState({});
    const [globalFilter, setGlobalFilter] = useState<any[]>([]);
    const [search, setSearch] = useState('');

    const { enableDelegation, enableSearch } = controls || {};

    const updateHeight = () => {
        const newHeight = window.innerHeight;
        setTableHeight(`${newHeight}px`);
    };

    useEffect(() => {
        updateHeight();
        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    const getSubRows = (row: TData) => {
        if (!subRowsField) return [];

        return row[subRowsField] as TData[];
    };

    const isCustom = (row: TData) => {
        if (!subRowsField) return false;

        return (row[subRowsField] as TData[])?.length > 0;
    };

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            expanded,
            globalFilter: globalFilter,
            rowSelection: rowSelection ? rowSelection : {},
        },
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        onExpandedChange: setExpanded,
        onRowSelectionChange: setRowSelection || undefined,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSubRows,
        filterFns: {},
    });

    return (
        <div className='flex h-full flex-col' ref={divRef}>
            <div id='control-bar' className='mb-4 flex items-center gap-4 px-1'>
                {enableSearch && (
                    <TableSearchControls
                        table={table}
                        search={search}
                        setSearch={setSearch}
                    />
                )}

                {enableDelegation && (
                    <TableDelegationControls setSearch={setSearch} />
                )}
            </div>

            {title && (
                <div className='flex h-10 w-full items-center justify-between rounded-t-lg border bg-red-800 px-4 py-2'>
                    <span className='text-sm font-bold text-white'>
                        {title}
                    </span>

                    <div className='flex gap-4'>
                        {actions?.map((action) => (
                            <Button
                                key={action.id}
                                variant='ghost'
                                className='h-full py-2 text-white hover:bg-red-700 hover:text-white hover:opacity-80 hover:shadow'
                                size='sm'
                                id={action.id}
                                data-testid={action.id}
                                onClick={action.onClick}
                            >
                                {action.icon}
                            </Button>
                        ))}
                    </div>
                </div>
            )}

            <Table
                className={cn('h-full w-full', className)}
                parentClassName='overflow-auto relative'
                parentStyle={{ height: tableHeight }}
                ref={tableRef}
            >
                <TableHeader className='sticky top-0 z-10 bg-white text-xs'>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <TableRow key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <TableHead
                                    key={header.id}
                                    className={cn(
                                        headerClassName,
                                        'whitespace-nowrap px-4'
                                    )}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                              header.column.columnDef.header,
                                              header.getContext()
                                          )}
                                </TableHead>
                            ))}
                        </TableRow>
                    ))}
                </TableHeader>

                <TableBody className='border bg-white text-xs'>
                    {loading && (
                        <>
                            <TableRow>
                                <TableCell
                                    colSpan={columns.length}
                                    className='h-full text-center'
                                >
                                    <div className='flex h-full items-center justify-center'>
                                        <Loader2 className='h-8 w-8 animate-spin' />
                                        <span className='ml-2'>Loading...</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </>
                    )}

                    {!loading && (
                        <>
                            {table.getRowModel().rows.map((row, index) => (
                                <TableRow
                                    key={row.id}
                                    data-state={
                                        row.getIsSelected() && 'selected'
                                    }
                                    onClick={() => {
                                        row.toggleExpanded(
                                            !row.getIsExpanded()
                                        );
                                    }}
                                    className={cn(
                                        'cursor-pointer',
                                        isCustom(row.original) && 'font-medium'
                                    )}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell
                                            key={cell.id}
                                            className={cn(
                                                cell.column.columnDef.meta
                                                    ?.columnsClassName,
                                                'max-w-[240px] truncate whitespace-nowrap px-4 text-start',
                                                cell.column.id ===
                                                    row.getVisibleCells()[0]
                                                        .column.id &&
                                                    isCustom(row.original) &&
                                                    'relative pl-8 before:absolute before:left-3 before:text-gray-500 before:transition-transform before:content-["►"]',
                                                cell.column.id ===
                                                    row.getVisibleCells()[0]
                                                        .column.id &&
                                                    isCustom(row.original) &&
                                                    row.getIsExpanded() &&
                                                    'before:rotate-90'
                                            )}
                                        >
                                            <TooltipProvider>
                                                <Tooltip>
                                                    <TooltipTrigger className='w-full truncate text-start'>
                                                        {flexRender(
                                                            cell.column
                                                                .columnDef.cell,
                                                            cell.getContext()
                                                        )}
                                                    </TooltipTrigger>

                                                    <>
                                                        {cell.getValue() && (
                                                            <TooltipContent className='text-white'>
                                                                <>
                                                                    {cell.getValue()}
                                                                </>
                                                            </TooltipContent>
                                                        )}
                                                    </>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}

                            <TableRow className='h-full hover:bg-white'>
                                <TableCell colSpan={columns.length}></TableCell>
                            </TableRow>
                        </>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

interface TableDelegationControlsProps {
    setSearch: (search: string) => void;
}

export function TableDelegationControls({
    setSearch,
}: TableDelegationControlsProps) {
    const { data: delegations } = useDelegations();
    const [searchParams, setSearchParams] = useSearchParams();

    const delegateId = searchParams.get('delegateId') || '';

    const delegationOptions = useMemo(() => {
        return (delegations || []).map((delegate) => {
            return {
                label: delegate.delegatedTo?.company?.demographics?.name,
                value: delegate.delegatedTo?._id,
            };
        });
    }, [delegations]);

    const handleChange = (value: string) => {
        if (value) {
            setSearchParams((params) => {
                params.set('delegateId', value);

                return params;
            });

            setSearch('');
        } else {
            setSearchParams((params) => {
                params.delete('delegateId');

                return params;
            });
        }
    };

    return (
        <Select
            value={delegateId}
            setValue={handleChange}
            data-testid='delegate-select'
            options={delegationOptions}
            placeholder='For Company'
            className='w-56'
        />
    );
}

interface TableSearchControlsProps<TData> {
    table: TanstackTable<TData>;
    search: string;
    setSearch: (search: string) => void;
}

export function TableSearchControls<TData>({
    table,
    search,
    setSearch,
}: TableSearchControlsProps<TData>) {
    const debouncedSearchTerm = useDebounce(search, 500);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    useEffect(() => {
        table.setGlobalFilter(debouncedSearchTerm);
    }, [debouncedSearchTerm, table]);

    return (
        <div className='relative w-56 text-xs'>
            <Input
                type='text'
                placeholder='Search text'
                value={search}
                onChange={handleSearch}
                className='h-8 w-full rounded-md pr-10 text-xs'
            />

            <div className='absolute inset-y-0 right-0 flex items-center rounded-r-md bg-gray-700 px-2'>
                <Search className='h-4 w-4 text-white' />
            </div>
        </div>
    );
}
