import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
    SupportDocType,
    SupportDocTypeLabel,
} from 'src/app/_api_adb2c/shared/support-doc-type.enum';
import { DocumentUpdateDataObject } from 'src/app/_api_adb2c/workspace/documents/document-update-data-object';
import { useUpdateDocument } from 'src/app/_api_adb2c/workspace/documents/hooks/use-update-document';
import { CheckboxInput } from 'src/app/components/Form/CheckboxInput';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';
import { Form } from 'src/components/ui/form';
import { z } from 'zod';
import { BaseDialog } from 'src/app/components-v2/base-dialog';
import { WorkspaceFileModel } from './workspace-documents';

interface Props {
    open: boolean;
    onClose: () => void;
    document: WorkspaceFileModel;
    documents: WorkspaceFileModel[];
}

const formSchema = z.object({
    name: z.string(),
    nature: z.string(),
    comment: z.string().optional(),
    isPrivate: z.boolean(),
});

export const EditDocument = ({ open, onClose, document, documents }: Props) => {
    const { mutateAsync } = useUpdateDocument();

    const form = useForm<z.infer<typeof formSchema>>({
        mode: 'onChange',
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: document.name,
            nature: document.type,
            comment: document.description,
            isPrivate: document.isPrivate,
        },
    });

    const onSubmit = async (data: z.infer<typeof formSchema>) => {
        const existing = documents?.find((x) => x._id === document._id);

        if (!existing) return;

        const body: DocumentUpdateDataObject = {
            documents: {
                _id: existing?._id,
                description: data.comment,
                file: existing.file._id,
                isPrivate: data.isPrivate,
                type: data.nature,
            },
            extensions: {
                isCertificate: false,
                name: existing.name,
                translatedName: [existing.name],
                translatedDescription: data.comment ? [data.comment] : [],
            },
        };

        await mutateAsync({
            id: existing._id,
            body,
        });

        reset();
    };

    const reset = () => {
        form.reset();
        onClose();
    };

    return (
        <BaseDialog open={open} onClose={reset} title='Edit Document'>
            <Form {...form}>
                <form id='my-form' onSubmit={form.handleSubmit(onSubmit)}>
                    <TextInput label='Name' inputName='name' />

                    <SelectInput
                        label='Nature'
                        name='nature'
                        options={Object.values(SupportDocType).map((v) => ({
                            label: SupportDocTypeLabel[v],
                            value: v,
                        }))}
                    />

                    <TextInput label='Comment' inputName='comment' />

                    <CheckboxInput label='Private' name='isPrivate' />
                </form>
            </Form>
        </BaseDialog>
    );
};
