import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { cn } from 'src/app/utils/cn';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import { Input } from 'src/components/ui/input';
import { IoIosAlert } from 'react-icons/io';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';

const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
};

export interface TextInputProps
    extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
    label?: string;
    inputName: string;
    type?: string;
    placeholder?: string;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    disabled?: boolean;
    readOnly?: boolean;
    misMatch?: { type: string; field: string; value: string; file: string }[];
    library?: any;
    className?: string;
    displayValue?: string;
    size?: keyof typeof sizes;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const TextInput = ({
    label,
    inputName: name,
    type = 'string',
    placeholder,
    startAdornment,
    endAdornment,
    disabled = false,
    readOnly = false,
    misMatch,
    library,
    className,
    displayValue,
    size = 'xs',
    onKeyDown,
    ...args
}: TextInputProps) => {
    const { control, watch } = useFormContext();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const currentValue = watch(name);
    const [value, setValue] = useState(currentValue);

    useEffect(() => {
        setValue(currentValue);
    }, [currentValue]);

    if (disabled && !readOnly) {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => {
                    return (
                        <FormItem className=''>
                            <div>
                                <FormLabel className='inline-block text-xs font-bold'>
                                    {label}
                                </FormLabel>
                                {misMatch?.find(
                                    (t) => t.field === field.name
                                ) ? (
                                    <Popover
                                        open={popoverOpen}
                                        onOpenChange={setPopoverOpen}
                                    >
                                        <PopoverTrigger asChild>
                                            <IoIosAlert
                                                className='inline-block cursor-pointer'
                                                onMouseOver={() => {
                                                    setTimeout(() => {
                                                        document
                                                            .querySelector(
                                                                '[data-radix-popper-content-wrapper]'
                                                            )
                                                            ?.setAttribute(
                                                                'style',
                                                                'left: unset !important; top: unset !important; transform: translate(50%, 0%) !important;'
                                                            );
                                                    }, 100);
                                                    setPopoverOpen(true);
                                                }}
                                                onMouseLeave={() =>
                                                    setPopoverOpen(false)
                                                }
                                            />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            side='top'
                                            align='center'
                                            sideOffset={5}
                                            className='absolute w-[300px]'
                                        >
                                            <span className='text-xs'>
                                                {misMatch?.find(
                                                    (t) =>
                                                        t.field === field.name
                                                )?.field || ''}{' '}
                                                of{' '}
                                                {library?.documents?.find(
                                                    (d: any) =>
                                                        d.file.id ===
                                                        misMatch?.find(
                                                            (m) =>
                                                                m.field ===
                                                                field.name
                                                        )?.file
                                                )?.file.name ||
                                                    'No Document'}{' '}
                                                is{' '}
                                                {misMatch?.find(
                                                    (t) =>
                                                        t.field === field.name
                                                )?.value || ''}
                                            </span>
                                        </PopoverContent>
                                    </Popover>
                                ) : null}
                            </div>
                            <span className='flex h-7 w-full py-1 text-xs'>
                                {displayValue || field.value || '-'}
                            </span>
                        </FormItem>
                    );
                }}
            />
        );
    }

    return (
        <FormField
            control={control}
            name={name}
            rules={{
                required: `${label} is required`,
            }}
            key={name}
            render={({ field }) => (
                <FormItem className='flex flex-1 flex-col'>
                    {label && (
                        <FormLabel className={cn('', sizes[size])}>
                            {label}
                        </FormLabel>
                    )}

                    <FormControl>
                        <div className={cn('relative', sizes[size])}>
                            {startAdornment && (
                                <div className='absolute left-2 top-1/2 -translate-y-1/2 transform text-gray-400'>
                                    {startAdornment}
                                </div>
                            )}

                            <Input
                                {...args}
                                {...field}
                                type={type}
                                key={name}
                                placeholder={placeholder || '-'}
                                data-testid='input'
                                disabled={disabled}
                                readOnly={readOnly}
                                className={cn(sizes[size], className)}
                                value={displayValue || value || ''}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    if (type === 'number' && args.step) {
                                        const value = parseFloat(
                                            e.target.value
                                        );
                                        if (!isNaN(value)) {
                                            const decimals =
                                                args.step
                                                    .toString()
                                                    .split('.')[1]?.length || 0;
                                            field.onChange(
                                                parseFloat(
                                                    value.toFixed(decimals)
                                                )
                                            );
                                            return;
                                        }
                                    }
                                    field.onChange(e.target.value);
                                }}
                                onBlur={field.onBlur}
                                onKeyDown={(e) => {
                                    if (onKeyDown) {
                                        onKeyDown(e);
                                    }
                                }}
                            />

                            {endAdornment && (
                                <div className='absolute right-2 top-1/2 -translate-y-1/2 transform text-gray-400'>
                                    {endAdornment}
                                </div>
                            )}
                        </div>
                    </FormControl>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
};
