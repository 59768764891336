import { IPublicClientApplication } from '@azure/msal-browser';
import {
    FileText,
    LayoutGrid,
    LogOut,
    LucideIcon,
    PackageSearch,
    Settings,
    ShoppingBasket,
    UserRound,
    Users,
} from 'lucide-react';
import { UserModel } from '../_api_adb2c/workspace/users/user.model';

export interface MenuItem {
    href: string;
    label: string;
    active?: boolean;
    icon: LucideIcon;
    submenus?: SubmenuItem[];
    onClick?: () => void;
    restrictedTo?: string[];
}

export interface SubmenuItem {
    href: string;
    label: string;
    active?: boolean;
    restrictedTo?: string[];
}

export interface MenuGroup {
    groupLabel: string;
    menus: MenuItem[];
    restrictedTo?: string[];
}

export function getMenuList(
    pathname: string,
    instance: IPublicClientApplication,
    userEmail?: string
): MenuGroup[] {
    console.log(pathname);

    const allMenus: MenuGroup[] = [
        {
            groupLabel: 'Main Menu',
            menus: [
                {
                    href: '/',
                    label: 'Dashboard',
                    active: pathname === '/',
                    icon: LayoutGrid,
                    submenus: [],
                },
                {
                    href: '',
                    label: 'Orders',
                    active:
                        pathname.includes('/orders') ||
                        pathname.includes('/purchases'),
                    icon: ShoppingBasket,
                    submenus: [
                        {
                            href: '/orders',
                            label: 'Received Orders',
                            active: pathname.includes('/orders'),
                        },
                        {
                            href: '/purchases',
                            label: 'Purchase Orders',
                            active: pathname.includes('/purchases'),
                        },
                    ],
                },
                {
                    href: '',
                    label: 'Products',
                    active:
                        pathname.includes('/products') ||
                        pathname.includes('/categories') ||
                        pathname.includes('/components'),
                    icon: PackageSearch,
                    submenus: [
                        {
                            href: '/products',
                            label: 'Items',
                            active: pathname.includes('/products'),
                        },
                        {
                            href: '/categories',
                            label: 'Categories',
                            active: pathname.includes('/categories'),
                        },
                        {
                            href: '/components',
                            label: 'Components',
                            active: pathname.includes('/components'),
                        },
                    ],
                },
                {
                    href: '/suppliers',
                    label: 'Suppliers',
                    active: pathname.includes('/suppliers'),
                    icon: Users,
                    submenus: [],
                },
                {
                    href: '',
                    label: 'Reports',
                    active: pathname.includes('/reports'),
                    icon: FileText,
                    submenus: [
                        { href: '/reports/substances', label: 'Substances' },
                        {
                            href: '/approvals',
                            label: 'Approvals',
                            active: pathname.includes('/approvals'),
                        },
                        { href: '/reports/supplier', label: 'Suppliers' },
                        { href: '/reports/order', label: 'Orders' },
                        { href: '/reports/product', label: 'Products' },
                    ],
                },
            ],
        },
        {
            groupLabel: 'Settings',
            menus: [
                {
                    href: '/settings/impersonate',
                    label: 'Impersonate',
                    active: pathname === '/settings/impersonate',
                    icon: UserRound,
                    submenus: [],
                    restrictedTo: [
                        'v-iantien@lifung.com',
                        'aiyazhanaliyeva@lifung.com',
                    ],
                },
                {
                    href: '/settings',
                    label: 'Workspace Settings',
                    active: pathname.includes('/settings'),
                    icon: Settings,
                    submenus: [],
                },
                {
                    href: '/',
                    label: 'Logout',
                    active: false,
                    icon: LogOut,
                    submenus: [],
                    onClick: () => {
                        instance.logoutRedirect();
                    },
                },
            ],
        },
    ];

    if (!userEmail) {
        return allMenus;
    }

    return allMenus
        .map((group) => {
            const filteredMenus = group.menus.filter((menu) => {
                if (
                    menu.restrictedTo &&
                    !menu.restrictedTo.includes(userEmail)
                ) {
                    return false;
                }

                if (menu.submenus && menu.submenus.length > 0) {
                    menu.submenus = menu.submenus.filter(
                        (submenu) =>
                            !submenu.restrictedTo ||
                            submenu.restrictedTo.includes(userEmail)
                    );
                }

                return true;
            });

            return {
                ...group,
                menus: filteredMenus,
            };
        })
        .filter((group) => group.menus.length > 0);
}
