import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useContextStore } from 'src/app/stores/context-store';
import { PRODUCT_APPROVAL_BY_WORKSPACE_QUERY_KEY } from './product-approval-query-keys';
import { useProductApprovalService } from './use-product-approval-service';
import { ProductApprovalRequest } from '../model/product-approval-request';

export function useApprovalByWorkspace(delegateId?: string) {
    const { service } = useProductApprovalService();
    const context = useContextStore();

    return useQuery<ProductApprovalRequest[]>({
        queryKey: PRODUCT_APPROVAL_BY_WORKSPACE_QUERY_KEY(
            delegateId || context.workspace?._id || ''
        ),
        queryFn: async () => {
            return await service.getByWorkspace(context.workspace?._id || '');
        },
        onError: (err) => {
            const error = err as Error;

            toast.error(`Failed to fetch approvals: ${error.message}`);
        },
    });
}
