import { ColumnDef } from '@tanstack/react-table';
import { useQuery } from '@tanstack/react-query';
import { Filter, GanttChartSquare } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { usePurchaseStatisticsService } from 'src/app/_api_adb2c/purchase/statistics/hooks/use-purchase-statistics-service';
import { useContextStore } from 'src/app/stores/context-store';
import { ReusableTable } from '../../Purchases/reusable-table';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { useSuppliers } from 'src/app/_api_adb2c/purchase/suppliers/hooks/use-suppliers';
import { MultiSearchSelect } from 'src/components/ui/multi-search-select';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import {
    getTotalWeight,
    convertWeight,
    getPurchaseOrderItemCategory,
} from 'src/app/utils/substance';
import { UsageNature } from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    calculatePurchaseSubstanceWeight,
    calculateSubstanceWeight,
} from 'src/app/utils/substance';
import { Data, SubstanceItem } from './Details';

export interface Item {
    category: string[];
    cumulativeWeight: number;
    totalWeight: number;
    purchaseOrders: { purchaseOrderId: string; tier: number }[];
    substanceName: string;
    substanceCode: string;
    shippedOn: string[];
    purchases: any[];
}

export function SubstanceReportTable() {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const context = useContextStore();

    const startDate = searchParams.get('startDate') || '';
    const endDate = searchParams.get('endDate') || '';
    const thresholdWeight = parseFloat(
        searchParams.get('thresholdWeight') || '0'
    );
    const category = searchParams.get('category') || '';
    const substanceName = searchParams.get('substanceName') || '';
    const casNumber = searchParams.get('casNumber') || '';

    const suppliers = searchParams.get('suppliers') || '';
    const memoizedSuppliers = useMemo(() => {
        if (suppliers) {
            return suppliers.split(',');
        }

        return [];
    }, [suppliers]);

    // Create a memoized navigation handler
    const handleSubstanceNavigation = useCallback(
        (substanceName: string, substanceCode: string) => {
            // Encode the substance name and CAS number for URL safety
            const parsedName = encodeURIComponent(substanceName || '--');
            const parsedCas = encodeURIComponent(substanceCode || '--');
            console.debug(
                'handleSubstanceNavigation(): parsedName is ',
                parsedName
            );
            console.debug(
                'handleSubstanceNavigation(): parsedCas is ',
                parsedCas
            );
            // Create query parameters
            const queryParams = new URLSearchParams();

            // Add date filters if available
            if (startDate) {
                queryParams.append('startDate', startDate);
            }
            if (endDate) {
                queryParams.append('endDate', endDate);
            }

            // Add suppliers if selected
            if (suppliers) {
                queryParams.append('suppliers', suppliers);
            }

            // Construct the URL with query parameters
            const baseUrl = `/report/substances/${parsedName}/${parsedCas}`;
            const queryString = queryParams.toString();
            const url = queryString ? `${baseUrl}?${queryString}` : baseUrl;

            // Navigate to the URL
            navigate(url);
        },
        [navigate, startDate, endDate, suppliers] // Dependencies for the callback
    );

    const { service } = usePurchaseStatisticsService();

    const { data: items = [], isLoading } = useQuery({
        queryKey: [
            'substance-statistics',
            context.workspace?._id,
            startDate,
            endDate,
            suppliers,
        ],
        queryFn: async ({ signal }) => {
            if (!context.workspace || !startDate || !endDate) return [];

            try {
                const response: Item[] = await service.getStatisticsByWorkspace(
                    context.workspace?._id || '',
                    startDate || DateTime.now().startOf('month').toISO(),
                    endDate || DateTime.now().endOf('month').toISO(),
                    suppliers,
                    { signal }
                );
                return response;
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                    // Let React Query handle the abort error
                    throw error;
                }
                console.error('Error fetching statistics:', error);
                return [];
            }
        },
        enabled: !!context.workspace && !!startDate && !!endDate,
    });

    const filteredItems = useMemo(() => {
        let filteredItems = items;
        if (thresholdWeight > 0) {
            filteredItems = filteredItems.filter((item) => {
                return item.cumulativeWeight >= thresholdWeight;
            });
        }

        if (category) {
            filteredItems = filteredItems.filter((item) => {
                return item.category.includes(category);
            });
        }

        if (substanceName) {
            filteredItems = filteredItems.filter((item) => {
                return item.substanceName.includes(substanceName);
            });
        }

        if (casNumber) {
            filteredItems = filteredItems.filter((item) => {
                return item.substanceCode.includes(casNumber);
            });
        }

        const response = filteredItems.map((x) => {
            const allCategories = new Set<string>(x.category || []);
            // Create a set to collect all unique shipping dates
            const allShippedDates = new Set<string>();

            // Add original shipping dates if available
            if (x.shippedOn && Array.isArray(x.shippedOn)) {
                x.shippedOn.forEach((date) => {
                    if (date) {
                        allShippedDates.add(String(date).split('T')[0]);
                    }
                });
            }

            const transformedData: Data[] = x.purchases.map((purchase) => ({
                supplier: purchase.purchaseOrder.supplier.seller.name,
                purchase: purchase.purchaseOrder,
                traces: purchase.traces || [],
                tier: purchase.tier,
            }));

            // Get tier 0 orders (main orders)
            const tier0Orders = transformedData.filter(
                (item) => item.tier === 0
            );

            const mappedOrders: SubstanceItem[] = tier0Orders.map(
                (mainOrder) => {
                    // Find all related traces for this order
                    const relatedTraces = transformedData.filter(
                        (item) =>
                            mainOrder.purchase.relatedPurchaseOrders?.includes(
                                item.purchase._id
                            ) && item.tier > 0
                    );

                    // Create the trace hierarchy
                    const traces: SubstanceItem[] = relatedTraces.map(
                        (trace) => ({
                            _id: trace.purchase._id,
                            purchase: trace.purchase,
                            traces: [], // Further nested traces if needed
                            supplier: trace.supplier,
                            tier: trace.tier,
                        })
                    );

                    return {
                        _id: mainOrder.purchase._id,
                        purchase: mainOrder.purchase,
                        traces,
                        supplier: mainOrder.supplier,
                        tier: mainOrder.tier,
                    };
                }
            );

            console.debug('x is ', x.substanceName);
            console.debug('mappedOrders is ', mappedOrders);

            let totalWeight = 0;
            let categories = new Set<string>();

            mappedOrders.forEach((order) => {
                const weight = calculatePurchaseSubstanceWeight(
                    order.purchase,
                    x.substanceCode,
                    x.substanceName
                );

                const category = getPurchaseOrderItemCategory(
                    order.purchase,
                    context.workspace?.features.supplyChainLoaderType || 0
                );

                if (category) {
                    categories.add(category);
                }

                if (order.purchase.shippedOn) {
                    try {
                        // Try to get a string representation of the date(s)
                        if (Array.isArray(order.purchase.shippedOn)) {
                            order.purchase.shippedOn.forEach((date: Date) => {
                                if (date) {
                                    // Convert to string safely
                                    allShippedDates.add(
                                        String(date).split('T')[0]
                                    );
                                }
                            });
                        } else {
                            // Add as string
                            allShippedDates.add(
                                String(order.purchase.shippedOn).split('T')[0]
                            );
                        }
                    } catch (e) {
                        console.error('Error processing shipping date:', e);
                    }
                }

                totalWeight += weight;
            });

            return {
                ...x,
                purchases: mappedOrders,
                totalWeight: totalWeight,
                category: Array.from(categories),
                shippedOn: Array.from(allShippedDates),
            };

            // // Apply the same calculation logic as in SubstanceDetails
            // if (x.purchases && x.purchases.length > 0) {
            //     // First transform all purchases with their tier information
            //     const transformedData = x.purchases.map((purchase) => ({
            //         supplier:
            //             purchase.purchaseOrder.supplier?.seller?.name ||
            //             'Unknown',
            //         purchase: purchase.purchaseOrder,
            //         traces: purchase.traces || [],
            //         tier: purchase.tier,
            //     }));

            //     // Get tier 0 orders (main orders) - CRITICAL for matching the SubstanceDetails view
            //     tier0Orders = transformedData.filter((item) => item.tier === 0);
            //     console.log('Tier 0 orders:', tier0Orders);

            //     hasMultipleTiers = x.purchases.some((p) => p.tier > 0);

            //     // Create a hierarchical structure of orders
            //     mappedOrders = tier0Orders.map((mainOrder) => {
            //         // Find all related traces for this order
            //         const relatedTraces = transformedData.filter(
            //             (item) =>
            //                 mainOrder.purchase.relatedPurchaseOrders?.includes(
            //                     item.purchase._id
            //                 ) && item.tier > 0
            //         );
            //         console.log(
            //             'Related traces for order:',
            //             mainOrder.purchase._id,
            //             relatedTraces
            //         );

            //         // Calculate weight for this main purchase order using shared function
            //         const mainOrderWeight = calculatePurchaseSubstanceWeight(
            //             mainOrder.purchase,
            //             x.substanceCode,
            //             x.substanceName
            //         );
            //         console.log('Main order weight:', mainOrderWeight);

            //         // Process traces and calculate their weights with shared function
            //         const tracesWithWeights = relatedTraces.map((trace) => {
            //             // Calculate trace weight using shared function
            //             const traceWeight = calculatePurchaseSubstanceWeight(
            //                 trace.purchase,
            //                 x.substanceCode,
            //                 x.substanceName
            //             );
            //             console.log(
            //                 'Trace weight for:',
            //                 trace.purchase._id,
            //                 traceWeight
            //             );

            //             return {
            //                 _id: trace.purchase._id,
            //                 purchase: trace.purchase,
            //                 traces: [],
            //                 supplier: trace.supplier,
            //                 tier: trace.tier,
            //                 weight: traceWeight,
            //             };
            //         });

            //         // Create the hierarchical structure with weights
            //         return {
            //             _id: mainOrder.purchase._id,
            //             purchase: mainOrder.purchase,
            //             traces: tracesWithWeights,
            //             supplier: mainOrder.supplier,
            //             tier: mainOrder.tier,
            //             weight: mainOrderWeight,
            //             totalWeight:
            //                 mainOrderWeight +
            //                 tracesWithWeights.reduce(
            //                     (sum, trace) => sum + trace.weight,
            //                     0
            //                 ),
            //         };
            //     });

            //     // Calculate the total hierarchical weight - THIS IS KEY FOR MATCHING SUBSTANCE DETAILS
            //     hierarchicalTotalWeight = mappedOrders.reduce(
            //         (sum, order) => sum + order.totalWeight,
            //         0
            //     );
            //     console.log(
            //         'Hierarchical total weight:',
            //         hierarchicalTotalWeight
            //     );

            //     // Use the hierarchical weight as the main totalWeight
            //     // This ensures we're using the same approach as SubstanceDetails
            //     totalWeight = hierarchicalTotalWeight;
            //     console.log(
            //         'Using hierarchical weight as total (raw):',
            //         totalWeight
            //     );

            //     // Convert the weight to tons for display consistency with SubstanceDetails
            //     totalWeight = totalWeight;
            //     console.log('Total weight converted to tons:', totalWeight);

            //     // Now collect category and shipping date information from purchases
            //     x.purchases.forEach((y) => {
            //         const purchase = y.purchaseOrder;

            //         // Add purchase shipping date if available
            //         if (purchase.shippedOn) {
            //             try {
            //                 // Try to get a string representation of the date(s)
            //                 if (Array.isArray(purchase.shippedOn)) {
            //                     purchase.shippedOn.forEach((date: string) => {
            //                         if (date) {
            //                             // Convert to string safely
            //                             allShippedDates.add(
            //                                 String(date).split('T')[0]
            //                             );
            //                         }
            //                     });
            //                 } else {
            //                     // Add as string
            //                     allShippedDates.add(
            //                         String(purchase.shippedOn).split('T')[0]
            //                     );
            //                 }
            //             } catch (e) {
            //                 console.error('Error processing shipping date:', e);
            //             }
            //         }

            //         const latestVersion =
            //             purchase.versions?.[purchase.versions.length - 1];

            //         if (latestVersion && latestVersion.manifest) {
            //             latestVersion.manifest.forEach((z: any) => {
            //                 const product = z.product;
            //                 if (product?.category?.name) {
            //                     allCategories.add(product.category.name);
            //                 }
            //             });
            //         }
            //     });
            // }

            // // For debugging
            // console.log('Raw substance data:', x);
            // console.log('Final calculated weight:', totalWeight);

            // return {
            //     ...x,
            //     totalWeight: totalWeight,
            //     // Update the category array with all unique categories
            //     category: Array.from(allCategories),
            //     // Update the shippedOn array with all unique shipping dates
            //     shippedOn: Array.from(allShippedDates),
            //     // Include the hierarchical purchase structure with weights
            //     hierarchicalPurchases: mappedOrders,
            //     // Add the total weight calculated from the hierarchical structure
            //     hierarchicalTotalWeight: hierarchicalTotalWeight,
            //     // Track tier information more explicitly
            //     tierInfo: {
            //         tier0Count: tier0Orders.length,
            //         hasMultipleTiers: hasMultipleTiers,
            //     },
            // };
        });

        return response;
    }, [items, category, substanceName, thresholdWeight, casNumber]);

    const columns: ColumnDef<Item>[] = useMemo(() => {
        return [
            {
                header: 'Substance',
                // @ts-ignore
                accessorFn: (row) => row.substanceName || row.name || '--',
            },
            {
                header: 'CAS',
                accessorFn: (row) => row.substanceCode,
            },
            {
                header: 'Weight',
                accessorFn: (row) => row.totalWeight.toFixed(2),
            },
            {
                header: 'Category',
                accessorFn: (row) => {
                    // Create a Set to remove duplicate categories
                    const uniqueCategories = new Set(row.category || []);

                    if (uniqueCategories.size === 0) {
                        return '--';
                    }

                    // Sort categories alphabetically
                    const sortedCategories = Array.from(uniqueCategories)
                        .filter(Boolean)
                        .sort((a, b) => a.localeCompare(b));

                    return sortedCategories.join(', ');
                },
            },
            {
                header: 'Shipped On',
                accessorFn: (row) => {
                    // Create a Set to remove duplicate shipping dates
                    const uniqueDates = new Set(row.shippedOn || []);

                    if (uniqueDates.size === 0) {
                        return '--';
                    }

                    // Format and sort the dates
                    const sortedDates = Array.from(uniqueDates)
                        .filter(Boolean)
                        .sort((a, b) => {
                            // Sort dates in ascending order
                            return (
                                new Date(a).getTime() - new Date(b).getTime()
                            );
                        });

                    return sortedDates.join(', ');
                },
            },
            {
                id: 'actions',
                header: 'Actions',
                cell: ({ row }) => {
                    return (
                        <div className='flex gap-2'>
                            <GanttChartSquare
                                size={16}
                                className='cursor-pointer hover:scale-125'
                                onClick={() => {
                                    // Replace the inline navigation logic with the callback
                                    handleSubstanceNavigation(
                                        row.original.substanceName || '--',
                                        row.original.substanceCode || '--'
                                    );
                                }}
                            />
                        </div>
                    );
                },
            },
        ];
    }, [navigate, handleSubstanceNavigation]);

    console.debug('filteredItems', filteredItems);

    return (
        <>
            <TableV2
                label='Substance Report'
                isLoading={isLoading}
                columns={columns}
                data={filteredItems}
                actions={[
                    {
                        id: 'filter',
                        icon: <Filter size={16} />,
                        onClick: () => {
                            setSearchParams((prev) => {
                                prev.set('filter', 'true');
                                return prev;
                            });
                        },
                    },
                ]}
            />

            <SubstanceReportFilterModal
                open={searchParams.get('filter') === 'true'}
                onOpenChange={(open) => {
                    setSearchParams((prev) => {
                        if (open) {
                            prev.set('filter', 'true');
                        } else {
                            prev.delete('filter');
                        }
                        return prev;
                    });
                }}
            />
        </>
    );
}

interface SubstanceReportFilterModalProps {
    open: boolean;
    onOpenChange: (open: boolean) => void;
}

export function SubstanceReportFilterModal({
    open,
    onOpenChange,
}: SubstanceReportFilterModalProps) {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: suppliers } = useSuppliers();
    const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>([]);

    const memoizedSuppliers = useMemo(() => {
        const suppliers = searchParams.get('suppliers') || '';
        if (suppliers) {
            return suppliers.split(',');
        }
        return [];
    }, [searchParams]);

    useEffect(() => {
        if (open && !selectedSuppliers.length && memoizedSuppliers.length) {
            setSelectedSuppliers(memoizedSuppliers);
        }
    }, [open, memoizedSuppliers]);

    const reset = () => {
        setSelectedSuppliers([]);
        setSearchParams((prev) => {
            prev.delete('suppliers');
            return prev;
        });
        onOpenChange(false);
    };

    const handleChange = (value: string[]) => {
        setSelectedSuppliers(value);
        if (value.length > 0) {
            setSearchParams((prev) => {
                prev.set('suppliers', value.join(','));
                return prev;
            });
        } else {
            setSearchParams((prev) => {
                prev.delete('suppliers');
                return prev;
            });
        }
    };

    return (
        <DialogV2
            open={open}
            onClose={reset}
            onFinish={reset}
            title='Substance Report Filter'
            isStepDialog
            steps={[
                {
                    title: 'Suppliers',
                    description:
                        'Select the suppliers to include in the report',
                    content: (
                        <>
                            <MultiSearchSelect
                                label='Suppliers'
                                options={(suppliers || []).map((supplier) => ({
                                    label: supplier.seller.name,
                                    value: supplier._id,
                                }))}
                                value={selectedSuppliers}
                                onChange={handleChange}
                            />
                        </>
                    ),
                },
            ]}
        ></DialogV2>
    );
}
