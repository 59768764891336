import { CaretSortIcon } from '@radix-ui/react-icons';
import { CheckIcon, X } from 'lucide-react'; // Add ChevronDown import
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Badge } from 'src/components/ui/badge';
import { Button } from 'src/components/ui/button';
import {
    Command,
    CommandGroup,
    CommandInput,
    CommandItem,
} from 'src/components/ui/command';
import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from 'src/components/ui/form';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from 'src/components/ui/popover';
import { cn } from 'src/lib/utils';

interface Option {
    label: string;
    value: string;
}

interface MultiSelectInputV2Props {
    options: Option[];
    placeholder?: string;
    label?: string;
    name: string;
    className?: string;
    creatable?: boolean;
    disabled?: boolean;
    setCustomSearch?: (value: string) => void;
    renderMode?: 'compact' | 'expanded';
}

export function MultiSelectInputV2({
    options,
    placeholder = 'Select items...',
    label,
    name,
    className,
    creatable = false,
    disabled = false,
    setCustomSearch,
    renderMode = 'expanded',
}: MultiSelectInputV2Props) {
    const [open, setOpen] = useState(false);
    const [allOptions, setAllOptions] = useState(options);
    const { control, setValue, getValues } = useFormContext();

    useEffect(() => {
        if (!getValues(name)) {
            setValue(name, []);
        }
    }, [name, getValues, setValue]);

    useEffect(() => {
        setAllOptions(options);
    }, [options]);

    const updateValue = (value: string) => {
        const currentValues = getValues(name) as string[];
        const newValues = currentValues.includes(value)
            ? currentValues.filter((v) => v !== value)
            : [...currentValues, value];
        setValue(name, newValues);
    };

    const selectAll = () => {
        const currentValues = getValues(name) as string[];
        const allValues = allOptions.map((option) => option.value);

        if (currentValues.length === allOptions.length) {
            // If all are selected, deselect all
            setValue(name, []);
        } else {
            // Otherwise, select all
            setValue(name, allValues);
        }
    };

    const handleCreate = (inputValue: string) => {
        if (creatable && inputValue) {
            const newOption = { label: inputValue, value: inputValue };
            setAllOptions((prev) => [newOption, ...prev]);
            updateValue(inputValue);
        }
    };

    const renderSelectedOptions = (values: string[]) => {
        if (values.length > 10) {
            const displayedOptions = values.slice(0, 9).map((value) => {
                const option = allOptions.find((opt) => opt.value === value);
                return (
                    <Badge
                        key={value}
                        className='mb-0 mr-1 flex h-5 items-center py-0 text-xs'
                    >
                        {option?.label}
                        <span
                            className='ml-1 cursor-pointer'
                            onClick={(e) => {
                                e.stopPropagation();
                                updateValue(value);
                            }}
                        >
                            <X className='h-3 w-3' />
                        </span>
                    </Badge>
                );
            });
            return (
                <>
                    {displayedOptions}
                    <Badge className='mb-0 mr-1 flex h-5 items-center py-0 text-xs'>
                        +{values.length - 10} more...
                    </Badge>
                </>
            );
        }
        return values.map((value) => {
            const option = allOptions.find((opt) => opt.value === value);
            return (
                <Badge
                    key={value}
                    className='mb-0 mr-1 flex h-5 items-center py-0 text-xs'
                >
                    {option?.label}
                    <span
                        className='ml-1 cursor-pointer'
                        onClick={(e) => {
                            e.stopPropagation();
                            updateValue(value);
                        }}
                    >
                        <X className='h-3 w-3' />
                    </span>
                </Badge>
            );
        });
    };

    if (renderMode === 'expanded') {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem className='w-full'>
                        {label && (
                            <FormLabel className='text-xs'>{label}</FormLabel>
                        )}
                        <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                                <FormControl>
                                    <div
                                        className='min-h-8 flex w-full cursor-pointer flex-wrap gap-1 rounded-md border p-1 hover:border-primary'
                                        onClick={() => setOpen(true)}
                                    >
                                        {field.value?.length > 0 ? (
                                            renderSelectedOptions(field.value)
                                        ) : (
                                            <span className='px-2 py-1 text-xs text-muted-foreground'>
                                                {placeholder}
                                            </span>
                                        )}
                                    </div>
                                </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className='w-[200px] p-0 text-xs'>
                                <Command className='max-h-[240px] overflow-y-auto'>
                                    <CommandInput
                                        placeholder='Search...'
                                        className='h-9 text-xs'
                                        onValueChange={setCustomSearch}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                handleCreate(
                                                    (
                                                        e.target as HTMLInputElement
                                                    ).value
                                                );
                                                (
                                                    e.target as HTMLInputElement
                                                ).value = '';
                                            }
                                        }}
                                    />
                                    <CommandGroup>
                                        <CommandItem
                                            onSelect={selectAll}
                                            className='text-xs font-bold'
                                        >
                                            <CheckIcon
                                                className={cn(
                                                    'mr-2 h-4 w-4',
                                                    field.value?.length ===
                                                        allOptions.length
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                )}
                                            />
                                            {field.value?.length ===
                                            allOptions.length
                                                ? 'Deselect All'
                                                : 'Select All'}
                                        </CommandItem>
                                        {allOptions.map((option) => (
                                            <CommandItem
                                                key={option.value}
                                                onSelect={() =>
                                                    updateValue(option.value)
                                                }
                                                className='text-xs'
                                            >
                                                <CheckIcon
                                                    className={cn(
                                                        'mr-2 h-4 w-4',
                                                        field.value?.includes(
                                                            option.value || ''
                                                        )
                                                            ? 'opacity-100'
                                                            : 'opacity-0'
                                                    )}
                                                />
                                                {option.label}
                                            </CommandItem>
                                        ))}
                                    </CommandGroup>
                                </Command>
                            </PopoverContent>
                        </Popover>
                        <FormMessage />
                    </FormItem>
                )}
            />
        );
    }

    if (disabled) {
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem>
                        <FormLabel>{label}</FormLabel>
                        <div className='flex flex-wrap gap-1'>
                            {renderSelectedOptions(field.value)}
                        </div>
                    </FormItem>
                )}
            />
        );
    }

    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className='flex flex-col py-0'>
                    {label && (
                        <FormLabel className='text-xs'>{label}</FormLabel>
                    )}
                    <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                            <FormControl>
                                <Button
                                    variant='outline'
                                    role='combobox'
                                    size='sm'
                                    className={cn(
                                        'h-8 w-full pl-3 text-left font-normal',
                                        !field.value?.length &&
                                            'text-xs text-muted-foreground',
                                        className
                                    )}
                                >
                                    <div className='flex flex-1 flex-wrap items-center gap-1 overflow-hidden'>
                                        {field.value?.length > 0
                                            ? renderSelectedOptions(field.value)
                                            : placeholder}
                                    </div>
                                    <CaretSortIcon className='ml-auto h-4 w-4 shrink-0 opacity-50' />
                                </Button>
                            </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className='w-[200px] p-0 text-xs'>
                            <Command className='max-h-[240px] overflow-y-auto'>
                                <CommandInput
                                    placeholder='Search...'
                                    className='h-9 text-xs'
                                    onValueChange={setCustomSearch}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleCreate(
                                                (e.target as HTMLInputElement)
                                                    .value
                                            );
                                            (
                                                e.target as HTMLInputElement
                                            ).value = '';
                                        }
                                    }}
                                />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={selectAll}
                                        className='text-xs font-bold'
                                    >
                                        <CheckIcon
                                            className={cn(
                                                'mr-2 h-4 w-4',
                                                field.value?.length ===
                                                    allOptions.length
                                                    ? 'opacity-100'
                                                    : 'opacity-0'
                                            )}
                                        />
                                        {field.value?.length ===
                                        allOptions.length
                                            ? 'Deselect All'
                                            : 'Select All'}
                                    </CommandItem>
                                    {allOptions.map((option) => (
                                        <CommandItem
                                            key={option.value}
                                            onSelect={() =>
                                                updateValue(option.value)
                                            }
                                            className='text-xs'
                                        >
                                            <CheckIcon
                                                className={cn(
                                                    'mr-2 h-4 w-4',
                                                    field.value?.includes(
                                                        option.value || ''
                                                    )
                                                        ? 'opacity-100'
                                                        : 'opacity-0'
                                                )}
                                            />
                                            {option.label}
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </Command>
                        </PopoverContent>
                    </Popover>
                    <FormMessage />
                </FormItem>
            )}
        />
    );
}
