import { ColumnDef } from '@tanstack/react-table';
import { TableV3 } from './table-v3';
import { useState, useEffect } from 'react';
import { Settings } from 'lucide-react';
import {
    ColumnDefinition,
    useColumnConfiguration,
} from '../hooks/useColumnConfiguration';
import { ColumnConfigurationModal } from './column-configuration-modal';

interface TableAction {
    id: string;
    icon?: React.ReactNode;
    label?: string;
    onClick: (e?: React.MouseEvent) => void;
    disabled?: boolean;
    condition?: {
        isAllowed: boolean;
        message?: string;
    };
    component?: React.ReactNode;
}

interface ConfigurableTableProps<TData> {
    pageKey: string;
    allColumns: ColumnDef<TData, any>[];
    defaultVisibleColumns?: string[];
    data: TData[];
    isLoading?: boolean;
    isFetchingNextPage?: boolean;
    hasNextPage?: boolean;
    onLoadMore?: () => void;
    className?: string;
    label?: string;
    actions?: TableAction[];
    controls?: {
        enableDelegation?: boolean;
        enableSearch?: boolean;
    };
    setSearch?: (search: string) => void;
}

export function ConfigurableTable<TData>({
    pageKey,
    allColumns,
    defaultVisibleColumns,
    ...tableProps
}: ConfigurableTableProps<TData>) {
    const [isConfigOpen, setIsConfigOpen] = useState(false);

    // Create default column configurations
    const defaultColumns: ColumnDefinition[] = allColumns
        .filter((col) => col.id !== 'select')
        .map((col, index) => ({
            id: col.id as string,
            visible: defaultVisibleColumns
                ? defaultVisibleColumns.includes(col.id as string)
                : true,
            order: index,
            label: (typeof col.header === 'string'
                ? col.header
                : col.id) as string,
        }));

    const {
        columns,
        updateColumnVisibility,
        resetToDefault,
        updateColumns,
        reorderColumns,
        isLoading,
    } = useColumnConfiguration({
        pageKey,
        defaultColumns,
    });

    console.debug('columns', columns);

    // Filter and sort columns based on configuration
    const visibleColumns = allColumns.filter((col) => {
        if (col.id === 'select') return true; // Always show select column
        const config = columns.find(
            (config: ColumnDefinition) => config.id === col.id
        );
        return config?.visible;
    });

    const sortedColumns = [...visibleColumns].sort((a, b) => {
        if (a.id === 'select') return -1; // Always keep select first
        if (b.id === 'select') return 1;
        const configA = columns.find(
            (config: ColumnDefinition) => config.id === a.id
        );
        const configB = columns.find(
            (config: ColumnDefinition) => config.id === b.id
        );
        return (configA?.order || 0) - (configB?.order || 0);
    });

    // Add column configuration button to actions
    const actions: TableAction[] = [
        {
            id: 'column-config',
            icon: <Settings className='h-4 w-4' />,
            label: 'Configure Columns',
            onClick: () => setIsConfigOpen(true),
        },
        ...(tableProps.actions || []),
    ];

    return (
        <>
            <TableV3
                {...tableProps}
                columns={sortedColumns}
                actions={actions}
                isLoading={isLoading || tableProps.isLoading}
            />
            <ColumnConfigurationModal
                columns={columns.map((col: ColumnDefinition) => ({
                    key: col.id,
                    label: col.label,
                    visible: col.visible,
                }))}
                onSave={(newColumns) => {
                    const updatedColumns = columns.map(
                        (col: ColumnDefinition) => ({
                            ...col,
                            visible: newColumns[col.id] ?? col.visible,
                        })
                    );
                    updateColumns(updatedColumns);
                }}
                onReorder={(startIndex, endIndex) => {
                    reorderColumns(startIndex, endIndex);
                }}
                onReset={resetToDefault}
                isOpen={isConfigOpen}
                onClose={() => setIsConfigOpen(false)}
            />
        </>
    );
}
