import { ColumnDef } from '@tanstack/react-table';
import { GanttChartSquare } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UsageNature } from 'src/app/_api_adb2c/product/product/models/product.model';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import {
    PurchaseOrderStatus,
    PurchaseOrderStatusLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/purchase-order-status.enum';
import { PurchaseModel } from 'src/app/_api_adb2c/purchase/purchase/models/purchase.model';
import { usePurchaseStatisticsService } from 'src/app/_api_adb2c/purchase/statistics/hooks/use-purchase-statistics-service';
import { DetailLabel } from 'src/app/components-v2/detail-label';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { useContextStore } from 'src/app/stores/context-store';
import { generateOrderTitleForList } from 'src/app/utils/generate-order-title';
import { Item } from '../substance-report-table';
import {
    calculatePurchaseSubstanceWeight,
    calculateSubstanceWeight,
    convertWeight,
} from 'src/app/utils/substance';

export interface SubstanceItem {
    _id: string;
    purchase: PurchaseModel;
    traces: SubstanceItem[];
    supplier?: string;
    tier?: number;
}

interface SubstanceInformationResponseVersion2 {
    purchases: SubstanceItem[];
    substanceName: string;
    substanceCode: string;
    categories?: string[];
    shippedDates?: string[];
}

export interface Data {
    supplier: string;
    tier: number;
    purchase: PurchaseModel;
    traces: PurchaseModel[];
}

export function SubstanceDetails() {
    const { name, cas } = useParams<{ name: string; cas: string }>();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { workspace: w } = useContextStore();
    const { service } = usePurchaseStatisticsService();
    const [data, setData] = useState<SubstanceItem[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Get filter parameters from URL
    const startDate = searchParams.get('startDate') || '';
    const endDate = searchParams.get('endDate') || '';
    const suppliers = searchParams.get('suppliers') || '';

    // Parse suppliers string to array if present
    const suppliersList = useMemo(() => {
        if (suppliers) {
            return suppliers.split(',');
        }
        return [];
    }, [suppliers]);

    const getSubstanceInformation = useCallback(async () => {
        setIsLoading(true);
        try {
            console.debug(
                'service<purchase-statistics>| getSubstanceInformation(): Enter'
            );
            // Filter at the API level by passing substance code or name
            const substances = await service.getStatisticsByWorkspace(
                w?.id || '',
                startDate || DateTime.now().startOf('month').toISO(),
                endDate || DateTime.now().endOf('month').toISO(),
                suppliers || '',
                {
                    // Only pass one of these, prioritizing code over name
                    substanceCode: cas,
                    substanceName: !cas ? name : undefined,
                }
            );

            console.debug('substances are ', substances);
            console.debug('substances length is ', substances.length);

            // Should only have one substance in the response, but check anyway
            const targetSubstance: Item = substances.find(
                (substance: any) =>
                    substance.substanceCode === cas ||
                    substance.substanceName === name
            );

            console.debug('targetSubstance is ', targetSubstance);

            if (targetSubstance) {
                // First transform all purchases with their tier information
                const transformedData: Data[] = targetSubstance.purchases.map(
                    (purchase) => ({
                        supplier: purchase.purchaseOrder.supplier.seller.name,
                        purchase: purchase.purchaseOrder,
                        traces: purchase.traces || [],
                        tier: purchase.tier,
                    })
                );

                // Get tier 0 orders (main orders)
                const tier0Orders = transformedData.filter(
                    (item) => item.tier === 0
                );

                // Transform the data to show clear hierarchy
                const mappedOrders: SubstanceItem[] = tier0Orders.map(
                    (mainOrder) => {
                        // Find all related traces for this order
                        const relatedTraces = transformedData.filter(
                            (item) =>
                                mainOrder.purchase.relatedPurchaseOrders?.includes(
                                    item.purchase._id
                                ) && item.tier > 0
                        );

                        // Create the trace hierarchy
                        const traces: SubstanceItem[] = relatedTraces.map(
                            (trace) => ({
                                _id: trace.purchase._id,
                                purchase: trace.purchase,
                                traces: [], // Further nested traces if needed
                                supplier: trace.supplier,
                                tier: trace.tier,
                            })
                        );

                        return {
                            _id: mainOrder.purchase._id,
                            purchase: mainOrder.purchase,
                            traces,
                            supplier: mainOrder.supplier,
                            tier: mainOrder.tier,
                        };
                    }
                );

                console.debug('mappedOrders are ', mappedOrders);
                setData(mappedOrders);
            } else {
                setData([]);
            }
        } catch (error) {
            console.error('Error fetching substance information:', error);
        } finally {
            setIsLoading(false);
        }
    }, [service, w?.id, name, cas, startDate, endDate, suppliers]);

    useEffect(() => {
        getSubstanceInformation();
    }, [getSubstanceInformation]);

    const calculateSubstanceWeight = useCallback(
        (item: SubstanceItem): string => {
            if (!item?.purchase?.versions) {
                return '--';
            }

            const totalWeight = calculatePurchaseSubstanceWeight(
                item.purchase,
                cas || '',
                name
            );

            return totalWeight.toFixed(2);
        },
        [cas, name]
    );

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            navigate(`/purchases/${id}`);
        },
        [navigate]
    );

    const getPurchaseOrderItemCategory = useCallback(
        (order: PurchaseModel) => {
            if (order.versions) {
                const version = order.versions.find(
                    (v) => (v.manifest || []).length > 0
                );
                if (version?.manifest) {
                    const manifest = version.manifest[0];
                    if (!manifest) {
                        return '--';
                    } else if (manifest.product) {
                        const purchaseable = manifest.product;
                        if (purchaseable.category) {
                            const categoryBase =
                                w?.features.supplyChainLoaderType === 0
                                    ? purchaseable.category.code
                                          ?.split('::_-')
                                          ?.pop()
                                    : purchaseable.category.code
                                          ?.split('::')
                                          ?.shift();
                            if (categoryBase) {
                                return categoryBase
                                    .split(' ')
                                    .map((i) =>
                                        i
                                            .split('_')
                                            .map(
                                                (j) =>
                                                    j
                                                        .slice(0, 1)
                                                        .toUpperCase() +
                                                    j.slice(1).toLowerCase()
                                            )
                                            .join(' ')
                                    )
                                    .join(' ');
                            } else {
                                return '--';
                            }
                        } else {
                            return '--';
                        }
                    } else {
                        return '--';
                    }
                }
            } else {
                return '--';
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const getOrderTotal = useCallback(
        (order: PurchaseModel) => {
            const version = order.versions[order.versions.length - 1];

            if (version) {
                const total = version.manifest.reduce((acc, item) => {
                    return acc + item.ppu * item.quantity;
                }, 0);

                if (w?.features?.supplyChainLoaderType === 0) {
                    return total.toFixed(2);
                } else {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });
                    return formatter.format(total);
                }
            }
        },
        [w?.features.supplyChainLoaderType]
    );

    const columns = useMemo<ColumnDef<SubstanceItem>[]>(() => {
        return [
            {
                id: 'supplier',
                header: 'Supplier',
                accessorFn: (row) => row.purchase.supplier.seller.name,
            },
            {
                id: 'purchase',
                header: 'Purchase',
                cell: ({ row }) => {
                    const indent = row.depth > 0 ? 'ml-6' : '';

                    if (row.getIsGrouped()) {
                        return null;
                    }

                    return (
                        <div className={indent}>
                            {generateOrderTitleForList(row.original.purchase)}
                        </div>
                    );
                },
            },
            {
                id: 'case',
                header: 'Case',
                cell: ({ row }) => {
                    const indent = row.depth > 0 ? 'ml-6' : '';

                    if (row.getIsGrouped()) {
                        return null;
                    }

                    return (
                        <div className={indent}>
                            {
                                row.original.purchase.reference.find(
                                    (r) => r.source === 'externalDataId'
                                )?.value
                            }
                        </div>
                    );
                },
            },
            {
                id: 'substanceWeight',
                header: 'Weight (ton)',
                accessorFn: (row) => {
                    const weight = calculateSubstanceWeight(row);

                    return Number(weight) > 0
                        ? Number((Number(weight) / 1000000).toFixed(2))
                        : '--';
                },
            },
            {
                id: 'unitOfMeasurement',
                header: 'Unit of Measurement',
                accessorFn: (row) => {
                    const version =
                        row.purchase.versions[row.purchase.versions.length - 1];

                    const latestProduct = version?.manifest[0]?.product;

                    if (latestProduct) {
                        return ProductUomLabels[
                            latestProduct.unitOfMeasurement as ProductUom
                        ];
                    }

                    return '--';
                },
            },
            {
                id: 'primary Category',
                header: 'Primary Category',
                accessorFn: (row) => getPurchaseOrderItemCategory(row.purchase),
            },
            {
                id: 'item Number',
                header: 'Item Number',
                cell: ({ row }) => {
                    if (row.getIsGrouped()) {
                        return null;
                    }

                    return row.original.purchase.versions[
                        row.original.purchase.versions.length - 1
                    ].manifest[0].product.name;
                },
            },
            {
                id: 'currency',
                header: 'Currency',
                accessorFn: (row) => row.purchase.currency,
            },
            {
                id: 'buy Date',
                header: 'Buy Date',
                accessorFn: (row) =>
                    DateTime.fromJSDate(
                        new Date(row.purchase.createdOn)
                    ).toFormat('yyyy-MM-dd'),
            },
            {
                id: 'total',
                header: 'Total',
                accessorFn: (row) => getOrderTotal(row.purchase),
            },
            {
                id: 'quantity',
                header: 'Quantity',
                cell: ({ row }) => {
                    if (row.getIsGrouped()) {
                        return null;
                    }

                    return row.original.purchase.versions[
                        row.original.purchase.versions.length - 1
                    ].manifest[0].quantity;
                },
            },
            {
                id: 'status',
                header: 'Status',
                accessorFn: (row) =>
                    PurchaseOrderStatusLabels[
                        row.purchase.status as PurchaseOrderStatus
                    ],
            },
            {
                id: 'updated On',
                header: 'Updated On',
                accessorFn: (row) =>
                    DateTime.fromJSDate(
                        new Date(row.purchase.lastUpdatedOn)
                    ).toFormat('yyyy-MM-dd'),
            },
            {
                id: 'actions',
                header: 'Actions',
                disableSorting: true,
                enableColumnFilter: false,
                cell: ({ row }) => {
                    return (
                        <GanttChartSquare
                            className='h-4 w-4 hover:scale-125'
                            onClick={() =>
                                handleRedirectToDetails(
                                    row.original.purchase._id || ''
                                )
                            }
                        />
                    );
                },
            },
        ];
    }, [
        calculateSubstanceWeight,
        getOrderTotal,
        getPurchaseOrderItemCategory,
        handleRedirectToDetails,
    ]);

    return (
        <div className='flex h-full flex-col gap-6'>
            <div className='grid grid-cols-12 gap-4'>
                <div className='col-span-12 grid grid-cols-12 gap-6 rounded-lg border border-gray-200 bg-white px-4 py-4'>
                    <div className='col-span-12 flex w-full items-center justify-between'>
                        <span className='text-xs font-bold underline decoration-red-800 decoration-2 underline-offset-8'>
                            General Information
                        </span>
                    </div>

                    <div className='col-span-8 grid grid-cols-3 gap-4'>
                        <DetailLabel title='Name'>{name}</DetailLabel>
                        <DetailLabel title='CAS'>{cas}</DetailLabel>
                    </div>
                </div>
            </div>

            {/* Show active filters if they exist */}
            {(startDate || suppliers) && (
                <div className='mb-4 grid grid-cols-12 gap-4'>
                    <div className='col-span-12 rounded-lg border border-gray-200 bg-white px-4 py-3'>
                        <div className='flex items-center gap-4'>
                            <span className='text-xs font-semibold'>
                                Active Filters:
                            </span>
                            {startDate && endDate && (
                                <span className='rounded bg-blue-100 px-2 py-1 text-xs text-blue-800'>
                                    Date Range:{' '}
                                    {new Date(startDate).toLocaleDateString()} -{' '}
                                    {new Date(endDate).toLocaleDateString()}
                                </span>
                            )}
                            {suppliers && (
                                <span className='rounded bg-green-100 px-2 py-1 text-xs text-green-800'>
                                    Suppliers: {suppliersList.length}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <TableV2
                columns={columns}
                data={data}
                isLoading={isLoading}
                label='Orders'
                subRowsField='traces'
                tanstackGroupBy='supplier'
                defaultExpanded={true}
            />
        </div>
    );
}
