import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { UserModel } from '../_api_adb2c/workspace/users/user.model';
import { useContextStore } from './context-store';

interface ImpersonationState {
    // State
    isImpersonating: boolean;
    impersonatedUser: UserModel | null;
    originalAdminToken: string | null;

    // Actions
    startImpersonation: (token: string, userData: UserModel) => void;
    endImpersonation: () => void;
}

export const useImpersonationStore = create<ImpersonationState>()(
    persist(
        (set, get) => ({
            isImpersonating: false,
            impersonatedUser: null,
            originalAdminToken: null,

            startImpersonation: (token: string, userData: UserModel) => {
                // Store the original admin token if not already saved
                const originalToken =
                    get().originalAdminToken ||
                    sessionStorage.getItem('msal.token.keys') ||
                    '';

                // Update the store state
                set({
                    isImpersonating: true,
                    impersonatedUser: userData,
                    originalAdminToken: originalToken,
                });

                // Store in sessionStorage for persistence across reloads
                sessionStorage.setItem('impersonationToken', token);
                sessionStorage.setItem(
                    'impersonatedUser',
                    JSON.stringify(userData)
                );
                sessionStorage.setItem('isImpersonating', 'true');

                // Update the context store
                const contextStore = useContextStore.getState();
                contextStore.setImpersonationToken(token);
                contextStore.setUser(userData);

                // Store original admin token
                if (!sessionStorage.getItem('adminToken')) {
                    sessionStorage.setItem('adminToken', originalToken);
                }

                window.location.reload();
            },

            endImpersonation: () => {
                // Clear impersonation data from sessionStorage
                sessionStorage.removeItem('impersonationToken');
                sessionStorage.removeItem('impersonatedUser');
                sessionStorage.removeItem('isImpersonating');

                // Update the store state
                set({
                    isImpersonating: false,
                    impersonatedUser: null,
                });

                // Reload the page to restore original authentication
                window.location.reload();
            },
        }),
        {
            name: 'impersonation-store',
            getStorage: () => sessionStorage,
        }
    )
);
