import { useContextStore } from 'src/app/stores/context-store';
import { useImpersonationService } from './use-impersonation-service';
import { useQuery } from '@tanstack/react-query';
import { impersonationQueryKeys } from './impersonation-query-keys';
import { toast } from 'sonner';
import { ImpersonationModel } from '../model/impersonation.model';

export function useImpersonations() {
    const { service } = useImpersonationService();
    const context = useContextStore();

    return useQuery<ImpersonationModel[]>({
        queryKey: impersonationQueryKeys.list(context.workspace?._id || ''),
        queryFn: () => service.list(),
        onError: () => {
            toast.error('Failed to fetch impersonations');
        },
    });
}
