import { ColumnDef } from '@tanstack/react-table';
import { Edit, GanttChartSquare, Plus, Trash } from 'lucide-react';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useBaseCategories } from 'src/app/_api_adb2c/product/category/hooks/use-base-categories';
import { useCategories } from 'src/app/_api_adb2c/product/category/hooks/use-categories';
import { useApprovalByWorkspace } from 'src/app/_api_adb2c/product/product-approval/hooks/use-approval-by-workspace';
import { useProducts } from 'src/app/_api_adb2c/product/product/hooks/use-products';
import { ProductModel } from 'src/app/_api_adb2c/product/product/models/product.model';
import { TableV2 } from 'src/app/components-v2/table-v2';
import { NavigationLink } from 'src/app/components-v2/typography';
import { TooltipWrapper } from 'src/app/components/TooltipWrapper';
import { useContextStore } from 'src/app/stores/context-store';
import { Checkbox } from 'src/components/ui/checkbox';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from 'src/components/ui/tooltip';
import { AddProduct } from './add-product';
import { DeleteProduct } from './delete-product';
import { EditProduct } from './edit-product';

const DEBUG_PREFIX = '[Products]';

export const Products = () => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    console.debug(
        `${DEBUG_PREFIX} Initializing Products component with delegateId: ${delegateId}`
    );

    const { data, isLoading } = useProducts(delegateId || '');
    const { workspace } = useContextStore();
    const navigate = useNavigate();

    const loaderType = useMemo(() => {
        const type = workspace?.supplyChainLoaderType || 0;
        console.debug(`${DEBUG_PREFIX} Supply chain loader type: ${type}`);
        return type;
    }, [workspace?.supplyChainLoaderType]);

    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductModel>();

    const { data: categories } = useCategories();
    const { data: baseCategories } = useBaseCategories(
        loaderType === 0 ? 'apparel' : 'hardgoods'
    );

    const { data: approvalRequests } = useApprovalByWorkspace(delegateId || '');

    const sortedApprovalRequests = useMemo(() => {
        return approvalRequests?.sort((a, b) => {
            return (
                new Date(b.createdOn).getTime() -
                new Date(a.createdOn).getTime()
            );
        });
    }, [approvalRequests]);

    const memoizedData = useMemo<ProductModel[]>(() => {
        console.debug(
            `${DEBUG_PREFIX} Memoizing ${data?.data?.length || 0} products`
        );
        return (data?.data || [])
            .sort((a, b) => {
                if (!a.createdOn || !b.createdOn) return -1;

                return (
                    new Date(b.createdOn).getTime() -
                    new Date(a.createdOn).getTime()
                );
            })
            .map((x) => x);
    }, [data]);

    const handleRedirectToDetails = useCallback(
        (id: string) => {
            console.debug(
                `${DEBUG_PREFIX} Redirecting to product details: ${id}`
            );
            delegateId
                ? navigate({
                      pathname: `/products/${id}`,
                      search: `?delegateId=${delegateId}`,
                  })
                : navigate(`/products/${id}`);
        },
        [navigate, delegateId]
    );

    const columns: ColumnDef<ProductModel>[] = useMemo(
        () => [
            {
                id: 'select',
                size: 100,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllPageRowsSelected()}
                        onCheckedChange={(value) =>
                            table.toggleAllPageRowsSelected(!!value)
                        }
                        aria-label='Select all'
                        data-testid='select-all-button'
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        onCheckedChange={(value) => row.toggleSelected(!!value)}
                        aria-label='Select row'
                        data-testid='select-row-button'
                    />
                ),
                enableSorting: false,
                enableHiding: false,
            },
            {
                id: 'name',
                accessorFn: (row) => row.name,
                header: 'Name',
                cell: ({ row }) => {
                    return (
                        <NavigationLink
                            path={`/products/${row.original._id}`}
                            search={`?delegateId=${delegateId}`}
                        >
                            <span style={{ textTransform: 'capitalize' }}>
                                {row.original.name}
                            </span>
                        </NavigationLink>
                    );
                },
            },
            {
                id: 'itemNo',
                header: 'Item No',
                cell: ({ row }) => {
                    const itemNo = row.original.externalReferences?.find(
                        (x) => x.source === 'externalDataId'
                    )?.value;

                    const itemNoOld = Reflect.get(
                        row.original.externalReferences?.find(
                            (x) => x.source === 'externalDataId'
                        ) ?? {},
                        'reference'
                    );

                    return itemNo || itemNoOld || '--';
                },
            },
            {
                id: 'code',
                header: 'Code',
                accessorFn: (row) => {
                    const customerItemNo = row.externalReferences?.find(
                        (x) => x.source === 'customer'
                    )?.value;

                    return customerItemNo || '--';
                },
            },
            {
                id: 'description',
                header: 'Description',
                accessorFn: (row) => row.description || '--',
                cell: ({ row }) => {
                    const maxLength = 30;
                    const description = row.original.description || '--';

                    if (description.length <= maxLength) {
                        return <div>{description}</div>;
                    }

                    return (
                        <div>
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger>
                                        {description.slice(0, maxLength)}...
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {description}
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </div>
                    );
                },
            },
            {
                id: 'hsCode',
                header: 'HS Code',
                accessorFn: (row) => row.hsCode || '--',
            },
            {
                id: 'category',
                header: 'Category',
                accessorFn: (row) => {
                    let category = categories?.find(
                        (x) => x._id === row.category?._id
                    )?.name;

                    if (!category) {
                        category = baseCategories
                            ?.find((x) => x._id === row.category?._id)
                            ?.name.locales.find(
                                (x) => x.localeName === 'en'
                            )?.text;
                    }

                    return category || '--';
                },
            },
            {
                id: 'approvalStatus',
                header: 'Approval Status',
                cell: ({ row }) => {
                    const productId = row.original._id;
                    const approval = sortedApprovalRequests?.find(
                        (request) => request.product._id === productId
                    );

                    if (!approval) return '--';

                    if (approval.isApproved) {
                        return 'Approved';
                    }

                    if (approval.isRejected) {
                        return 'Rejected';
                    }

                    return 'Pending';
                },
            },
            {
                id: 'lastUpdatedOn',
                header: 'Last Updated On',
                sortingFn: 'datetime',
                accessorFn: (row) => {
                    return DateTime.fromJSDate(
                        new Date(row.lastUpdatedOn)
                    ).toLocaleString(DateTime.DATETIME_MED);
                },
            },
            {
                id: 'actions',
                enableHiding: false,
                enableSorting: false,
                header: 'Actions',
                size: 10,
                cell: ({ row }) => {
                    const id = row.original._id;

                    return (
                        <div className='flex h-full items-center gap-4'>
                            <TooltipWrapper label='View Product'>
                                <GanttChartSquare
                                    onClick={() =>
                                        handleRedirectToDetails(id || '')
                                    }
                                    data-testid='view-product-button'
                                    className='cursor-pointer hover:scale-125'
                                    size={16}
                                />
                            </TooltipWrapper>

                            <TooltipWrapper label='Edit Product'>
                                <Edit
                                    className='cursor-pointer text-blue-800 hover:scale-125'
                                    onClick={() => {
                                        setSelectedProduct(row.original);
                                        setIsEdit(true);
                                    }}
                                    data-testid='edit-product-button'
                                    size={16}
                                />
                            </TooltipWrapper>

                            <TooltipWrapper label='Delete Product'>
                                <Trash
                                    className='cursor-pointer text-red-800 hover:scale-125'
                                    onClick={() => {
                                        setSelectedProduct(row.original);
                                        setIsDelete(true);
                                    }}
                                    data-testid='delete-product-button'
                                    size={16}
                                />
                            </TooltipWrapper>
                        </div>
                    );
                },
            },
        ],
        [
            baseCategories,
            categories,
            handleRedirectToDetails,
            delegateId,
            sortedApprovalRequests,
        ]
    );

    return (
        <div className='flex h-full flex-col'>
            <TableV2
                label='Available Products'
                columns={columns}
                data={memoizedData || []}
                isLoading={isLoading}
                disabledTooltipColumns={['actions', 'select']}
                controls={{
                    enableDelegation: true,
                    enableSearch: true,
                }}
                actions={[
                    {
                        id: 'add',
                        label: 'Add Product',
                        icon: <Plus size={16} />,
                        onClick: () => {
                            console.debug(
                                `${DEBUG_PREFIX} Opening Add Product dialog`
                            );
                            setIsCreate(true);
                        },
                    },
                ]}
            />

            {isCreate && (
                <AddProduct
                    open={isCreate}
                    onClose={() => {
                        console.debug(
                            `${DEBUG_PREFIX} Closing Add Product dialog`
                        );
                        setIsCreate(false);
                    }}
                />
            )}

            {isEdit && selectedProduct && (
                <EditProduct
                    product={selectedProduct._id}
                    open={isEdit}
                    onClose={() => {
                        console.debug(
                            `${DEBUG_PREFIX} Closing Edit Product dialog for product: ${selectedProduct._id}`
                        );
                        setIsEdit(false);
                        setSelectedProduct(undefined);
                    }}
                />
            )}

            {isDelete && selectedProduct && (
                <DeleteProduct
                    product={selectedProduct}
                    onClose={() => {
                        console.debug(
                            `${DEBUG_PREFIX} Closing Delete Product dialog for product: ${selectedProduct._id}`
                        );
                        setIsDelete(false);
                        setSelectedProduct(undefined);
                    }}
                />
            )}
        </div>
    );
};
