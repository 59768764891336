import {
    CellContext,
    ColumnDef,
    ColumnDefTemplate,
} from '@tanstack/react-table';
import axios from 'axios';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import { CloudDownload, CloudOff, Loader2 } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useAttachmentDownload } from '../_api_adb2c/attachment/hooks/use-attachment-download';
import { WorkspaceModel } from '../_api_adb2c/product/category/category.model';
import {
    ProductBillOfMaterialEntry,
    ProductModel,
    UsageNature,
} from '../_api_adb2c/product/product/models/product.model';
import { ProductModel as PurchaseProductModel } from '../_api_adb2c/purchase/purchase/models/manifest-item.model';
import { PurchaseOrderVersionModel } from '../_api_adb2c/purchase/purchase/models/purchase-order-version.model';
import {
    PurchaseModel,
    PurchaseOrderTraceModel,
} from '../_api_adb2c/purchase/purchase/models/purchase.model';
import { RenderCircle } from '../pages/Materials/Details/Substance';

export interface BaseSubstance {
    substanceName: string;
    substanceCode: string;
    weight: number;
    ratio: string;
    ratioNumber: number;
    supplierId: string;
    supplierName: string;
    substanceRegCode: string;
    isManMade: boolean;
    sdsFileId: string[];
    subCompositions: CustomSubstance[];
    isCustom?: boolean;
    customName?: string;
}

export type CustomSubstance = {
    isCustom?: boolean;
    customName?: string;
    consumption?: number;
} & (
    | ({ isCustom: true } & Partial<BaseSubstance>)
    | ({ isCustom: false } & BaseSubstance)
);

export const getTotalWeight = (
    product: ProductModel | PurchaseProductModel,
    fixedVersion?: number
) => {
    let latest = product?.versions?.[product?.versions?.length - 1];
    if (fixedVersion) {
        latest = product?.versions?.find((v) => v.version === fixedVersion);
    }

    if (!latest) return 0;

    return (latest?.billOfMaterials || [])
        .filter((bom) => bom.usageNature === UsageNature.PRODUCT)
        .reduce(
            (acc, curr) => acc + (curr.weight || 0) * (curr.consumption || 1),
            0
        );
};

export const getCumulativeWeight = (
    purchase: PurchaseModel | PurchaseOrderTraceModel,
    fixedVersion?: number
) => {
    let latest: PurchaseOrderVersionModel | undefined =
        purchase.versions?.[purchase.versions.length - 1];

    if (fixedVersion) {
        latest = purchase.versions?.find((v) => v.version === fixedVersion);
    }

    if (!latest) return 0;

    return latest.manifest.reduce((acc, curr) => {
        const substances = generateSubstanceFromProduct(curr.product);
        return (
            acc + substances.reduce((acc, curr) => acc + (curr.weight || 0), 0)
        );
    }, 0);
};

export const generateSubstanceFromComponents = (
    components: ProductBillOfMaterialEntry[],
    totalWeight: number
): CustomSubstance[] => {
    const result: CustomSubstance[] = [];

    processCompositions(components, result, totalWeight);

    return result;
};

export const processCompositions = (
    components: ProductBillOfMaterialEntry[],
    result: CustomSubstance[],
    totalWeight: number
) => {
    components.forEach((c) => {
        const substances: CustomSubstance[] = [];

        c?.material?.compositions?.forEach((comp) => {
            const name = comp.substanceName || comp.name;
            const supplier = comp.supplier as unknown as WorkspaceModel;
            const substanceWeight = comp.weight || 0;

            const newSubstance: CustomSubstance = {
                isCustom: false,
                substanceName: `${name} `,
                substanceCode: comp.substanceCode || '',
                weight: substanceWeight,
                consumption: c.consumption || 1,
                ratio:
                    (
                        (substanceWeight /
                            (c.material.specifications?.weight || 1)) *
                        100
                    ).toFixed(2) + '%',
                ratioNumber:
                    (substanceWeight /
                        (c.material.specifications?.weight || 1)) *
                    100,
                supplierId: supplier?._id || '',
                supplierName: supplier?.name || '',
                substanceRegCode: comp.substanceRegCode || '',
                isManMade: comp.isManMade || false,
                sdsFileId: comp.sdsFileId ? [comp.sdsFileId] : [],
                subCompositions: [],
            };

            substances.push(newSubstance);
        });

        result.push({
            isCustom: true,
            customName: c.material?.name,
            substanceName: c.material?.name,
            subCompositions: substances,
        });
    });
};

export const generateSubstanceFromProduct = (
    product: ProductModel | PurchaseProductModel,
    fixedVersion?: number
) => {
    let latest = product?.versions?.[product?.versions?.length - 1];

    if (fixedVersion) {
        latest = product?.versions?.find((v) => v.version === fixedVersion);
    }

    if (!latest) return [];

    const totalWeight = getTotalWeight(product, fixedVersion);

    const result: CustomSubstance[] = [];

    const substances = generateSubstanceFromComponents(
        latest.billOfMaterials?.filter(
            (x) => x.usageNature === UsageNature.PRODUCT
        ) || [],
        totalWeight
    );

    result.push(...substances);

    return result;
};

const useDownloadBundle = () => {
    const { mutateAsync: download } = useAttachmentDownload();

    const getAllSdsFileIds = (
        substance: CustomSubstance
    ): { substanceName: string; sdsFile: string }[] => {
        let fileIds: { substanceName: string; sdsFile: string }[] = [
            ...(substance.sdsFileId || []),
        ].map((id) => ({
            substanceName: substance.substanceName || '',
            sdsFile: id,
        }));

        if (substance.subCompositions && substance.subCompositions.length > 0) {
            substance.subCompositions.forEach((subComp) => {
                fileIds = [...fileIds, ...getAllSdsFileIds(subComp)];
            });
        }

        return [...new Set(fileIds)];
    };

    const downloadBundle = async (substance: CustomSubstance) => {
        const allFileIds = getAllSdsFileIds(substance);
        if (!allFileIds.length) return;

        const files = await Promise.all(
            allFileIds.map(async (id, index) => {
                const token = await download({ body: { id: id.sdsFile } });
                const fileResp = await axios.get(token.token, {
                    responseType: 'blob',
                });
                return {
                    url: fileResp.data,
                    name: `${id.substanceName} - ${token.attachment.originalName}`,
                };
            })
        );

        const zip = new JSZip();
        files.forEach((file) => zip.file(file.name, file.url));
        const zipFile = await zip.generateAsync({ type: 'blob' });

        saveAs(zipFile, `${substance.substanceName}.zip`);
    };

    return { download: downloadBundle };
};

export const SubstanceDownloadButton = ({
    substance,
}: {
    substance: CustomSubstance;
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const { download } = useDownloadBundle();

    const isEmpty = useMemo(() => {
        return !substance.sdsFileId?.length;
    }, [substance]);

    const handleDownload = async () => {
        if (!substance.sdsFileId?.length) return;
        setIsLoading(true);

        await download(substance);
        setIsLoading(false);
    };

    if (isLoading) return <Loader2 size={16} className='animate-spin' />;

    if (isEmpty) return <CloudOff size={16} />;

    return (
        <CloudDownload
            size={16}
            onClick={handleDownload}
            className='cursor-pointer hover:scale-125'
        />
    );
};

export const generateSubstanceColumns = (
    actions?: ColumnDefTemplate<CellContext<CustomSubstance, unknown>>,
    poQuantities?: number
): ColumnDef<CustomSubstance>[] => {
    const columns: ColumnDef<CustomSubstance>[] = [
        {
            id: 'substanceName',
            header: 'Substance Name',
            accessorFn: (row) => row.substanceName,
        },
        {
            id: 'substanceCode',
            header: 'CAS Number',
            accessorFn: (row) => row.substanceCode,
        },
        {
            id: 'weight',
            header: 'Weight',
            accessorFn: (row) => row.weight,
        },
        {
            id: 'consumption',
            header: 'Consumption',
            accessorFn: (row) => row.consumption,
        },
        {
            id: 'ratio',
            header: 'Ratio',
            cell: ({ row }) => {
                return row.original.ratio;
            },
        },
        {
            id: 'supplierName',
            header: 'Supplier',
            accessorFn: (row) => row.supplierName,
        },
        {
            id: 'reachCode',
            header: 'REG',
            accessorFn: (row) => row.substanceRegCode,
        },
        {
            id: 'isManMade',
            header: 'Man-Made',
            cell: ({ row }) => {
                return row.original.isManMade ? (
                    <RenderCircle color='completed' />
                ) : (
                    <RenderCircle color='empty' />
                );
            },
        },
        {
            id: 'sdsFileId',
            header: 'SDS',
            cell: ({ row }) => {
                return row.original.sdsFileId &&
                    row.original.sdsFileId.length > 0 ? (
                    <RenderCircle color='completed' />
                ) : (
                    <RenderCircle color='empty' />
                );
            },
        },
        {
            id: 'totalWeight',
            header: 'Total Weight',
            cell: ({ row }) => {
                if (row.original.isCustom) {
                    return '';
                }

                const weight = row.original.weight || 0;
                const consumption = row.original.consumption || 1;

                if (poQuantities) {
                    const total = weight * consumption * poQuantities;
                    return total.toFixed(2);
                } else {
                    return (weight * consumption).toFixed(2);
                }
            },
        },
    ];

    if (actions)
        columns.push({ id: 'actions', header: 'Actions', cell: actions });

    return columns;
};

/**
 * Calculates the substance weight in a consistent way for both SubstanceDetails and SubstanceReportTable
 * @param productVersion The product version containing bill of materials
 * @param substanceCode The substance code to match
 * @param substanceName The substance name to match
 * @param quantity The quantity multiplier
 * @returns The calculated weight
 */
export const calculateSubstanceWeight = (
    productVersion: any,
    substanceCode: string,
    substanceName?: string,
    quantity: number = 1
): number => {
    if (!productVersion?.billOfMaterials) return 0;

    let totalWeight = 0;

    productVersion.billOfMaterials
        .filter((bom: any) => bom.usageNature === UsageNature.PRODUCT)
        .forEach((bom: any) => {
            bom.material?.compositions?.forEach((composition: any) => {
                if (
                    composition.substanceCode === substanceCode ||
                    (substanceName &&
                        composition.substanceName === substanceName)
                ) {
                    totalWeight +=
                        (composition.weight || composition.actualWeight || 0) *
                        (bom.weight || bom.consumption || 1) *
                        quantity;
                }
            });
        });

    return totalWeight;
};

/**
 * Calculates the substance weight from a purchase order in a consistent way
 * @param purchase The purchase order
 * @param substanceCode The substance code to match
 * @param substanceName The substance name to match (optional)
 * @returns The calculated total weight
 */
export const calculatePurchaseSubstanceWeight = (
    purchase: any,
    substanceCode: string,
    substanceName?: string
): number => {
    if (!purchase?.versions || purchase.versions.length === 0) return 0;

    const latestVersion = purchase.versions[purchase.versions.length - 1];
    if (!latestVersion?.manifest) return 0;

    let totalWeight = 0;

    latestVersion.manifest.forEach((manifestItem: any) => {
        const product = manifestItem.product;
        const quantity = manifestItem.quantity || 1;

        if (!product?.versions || product.versions.length === 0) return;

        const latestProductVersion =
            product.versions[product.versions.length - 1];
        if (!latestProductVersion) return;

        totalWeight += calculateSubstanceWeight(
            latestProductVersion,
            substanceCode,
            substanceName,
            quantity
        );
    });

    return totalWeight;
};

/**
 * Converts weight between different units
 * @param weight The weight value to convert
 * @param fromUnit The source unit ('g', 'kg', 'ton')
 * @param toUnit The target unit ('g', 'kg', 'ton')
 * @returns The converted weight value
 */
export const convertWeight = (
    weight: number,
    fromUnit: 'g' | 'kg' | 'ton' = 'g',
    toUnit: 'g' | 'kg' | 'ton' = 'g'
): number => {
    if (fromUnit === toUnit) return weight;

    // Convert to grams first
    let weightInGrams = weight;
    if (fromUnit === 'kg') {
        weightInGrams = weight * 1000;
    } else if (fromUnit === 'ton') {
        weightInGrams = weight * 1000000; // Metric ton = 1,000,000 grams
    }

    // Convert from grams to target unit
    if (toUnit === 'g') {
        return weightInGrams;
    } else if (toUnit === 'kg') {
        return weightInGrams / 1000;
    } else if (toUnit === 'ton') {
        return weightInGrams / 1000000; // Metric ton = 1,000,000 grams
    }

    return weight;
};

export const getPurchaseOrderItemCategory = (
    order: PurchaseModel,
    supplyChainLoaderType: number
) => {
    if (order.versions) {
        const version = order.versions.find(
            (v) => (v.manifest || []).length > 0
        );
        if (version?.manifest) {
            const manifest = version.manifest[0];
            if (!manifest) {
                return '--';
            } else if (manifest.product) {
                const purchaseable = manifest.product;
                if (purchaseable.category) {
                    const categoryBase =
                        supplyChainLoaderType === 0
                            ? purchaseable.category.code?.split('::_-')?.pop()
                            : purchaseable.category.code?.split('::')?.shift();
                    if (categoryBase) {
                        return categoryBase
                            .split(' ')
                            .map((i) =>
                                i
                                    .split('_')
                                    .map(
                                        (j) =>
                                            j.slice(0, 1).toUpperCase() +
                                            j.slice(1).toLowerCase()
                                    )
                                    .join(' ')
                            )
                            .join(' ');
                    } else {
                        return '--';
                    }
                } else {
                    return '--';
                }
            } else {
                return '--';
            }
        }
    } else {
        return '--';
    }
};
