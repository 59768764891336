import * as React from 'react';
import { Command, CommandGroup, CommandInput, CommandItem } from './command';
import { X, CheckIcon } from 'lucide-react';
import { Badge } from './badge';
import { Button } from './button';
import { cn } from 'src/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Label } from './label';

export interface Option {
    label: string;
    value: string;
}

interface MultiSearchSelectProps {
    options: Option[];
    value: string[];
    onChange: (value: string[]) => void;
    placeholder?: string;
    className?: string;
    searchPlaceholder?: string;
    label?: string;
}

export function MultiSearchSelect({
    options,
    value,
    onChange,
    placeholder = 'Select items...',
    searchPlaceholder = 'Search...',
    className,
    label,
}: MultiSearchSelectProps) {
    const [open, setOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState('');

    const filteredOptions = React.useMemo(() => {
        return options.filter((option) =>
            option.label.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [options, searchQuery]);

    const selectedOptions = React.useMemo(() => {
        return options.filter((option) => value.includes(option.value));
    }, [options, value]);

    const handleSelect = (optionValue: string) => {
        if (value.includes(optionValue)) {
            onChange(value.filter((v) => v !== optionValue));
        } else {
            onChange([...value, optionValue]);
        }
    };

    const handleRemove = (optionValue: string) => {
        onChange(value.filter((v) => v !== optionValue));
    };

    const selectAll = () => {
        if (value.length === options.length) {
            onChange([]);
        } else {
            onChange(options.map((option) => option.value));
        }
    };

    const renderSelectedOptions = (values: string[]) => {
        if (values.length > 10) {
            const displayedOptions = values.slice(0, 9).map((value) => {
                const option = options.find((opt) => opt.value === value);
                return (
                    <Badge
                        key={value}
                        variant='secondary'
                        className='mb-1 mr-1'
                    >
                        <span className='truncate'>{option?.label}</span>
                        <button
                            className='ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2'
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRemove(value);
                            }}
                        >
                            <X className='h-3 w-3' />
                        </button>
                    </Badge>
                );
            });
            return (
                <>
                    {displayedOptions}
                    <Badge variant='secondary' className='mb-1 mr-1'>
                        +{values.length - 9} more...
                    </Badge>
                </>
            );
        }

        return values.map((value) => {
            const option = options.find((opt) => opt.value === value);
            return (
                <Badge key={value} variant='secondary' className='mb-1 mr-1'>
                    <span className='truncate'>{option?.label}</span>
                    <button
                        className='ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2'
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleRemove(value);
                        }}
                    >
                        <X className='h-3 w-3' />
                    </button>
                </Badge>
            );
        });
    };

    return (
        <div className='flex flex-col gap-2'>
            {label && <Label className='text-xs font-bold'>{label}</Label>}
            <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        variant='outline'
                        role='combobox'
                        className={cn(
                            'h-auto min-h-[36px] w-full justify-between',
                            !value.length && 'text-muted-foreground',
                            className
                        )}
                    >
                        <div className='flex flex-wrap gap-1 truncate'>
                            {value.length > 0 ? (
                                renderSelectedOptions(value)
                            ) : (
                                <span className='text-muted-foreground'>
                                    {placeholder}
                                </span>
                            )}
                        </div>
                        <X
                            className={cn(
                                'ml-2 h-4 w-4 shrink-0 opacity-50',
                                value.length === 0 && 'hidden'
                            )}
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange([]);
                            }}
                        />
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    className='w-[var(--radix-popover-trigger-width)] p-0'
                    align='start'
                >
                    <Command className='max-h-[300px]'>
                        <CommandInput
                            placeholder={searchPlaceholder}
                            value={searchQuery}
                            onValueChange={setSearchQuery}
                            className='h-9'
                        />
                        <CommandGroup className='max-h-[200px] overflow-y-auto'>
                            <CommandItem
                                onSelect={selectAll}
                                className='text-xs font-medium'
                            >
                                <CheckIcon
                                    className={cn(
                                        'mr-2 h-4 w-4',
                                        value.length === options.length
                                            ? 'opacity-100'
                                            : 'opacity-0'
                                    )}
                                />
                                {value.length === options.length
                                    ? 'Deselect All'
                                    : 'Select All'}
                            </CommandItem>
                            {filteredOptions.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    onSelect={() => handleSelect(option.value)}
                                    className='text-xs'
                                >
                                    <CheckIcon
                                        className={cn(
                                            'mr-2 h-4 w-4',
                                            value.includes(option.value)
                                                ? 'opacity-100'
                                                : 'opacity-0'
                                        )}
                                    />
                                    {option.label}
                                </CommandItem>
                            ))}
                            {filteredOptions.length === 0 && (
                                <p className='py-6 text-center text-sm text-muted-foreground'>
                                    No results found.
                                </p>
                            )}
                        </CommandGroup>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
}
