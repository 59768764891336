import { z } from 'zod';
// import { ProductBOMInterface } from './product-bill-of-materials';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useMaterials } from 'src/app/_api_adb2c/product/material/hooks/use-materials';
import { useComposeProduct } from 'src/app/_api_adb2c/product/product/hooks/use-componse-product';
import {
    ProductBillOfMaterialEntry,
    ProductModel,
    UsageNature,
    UsageNatureLabels,
} from 'src/app/_api_adb2c/product/product/models/product.model';
import { ProductBillOfMaterialDataObject } from 'src/app/_api_adb2c/product/product/requests/product-bill-of-material-data-object';
import { ProductUpdateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-update-data-object';
import { ProductVersionCreateDataObject } from 'src/app/_api_adb2c/product/product/requests/product-version-create-data-object';
import {
    ProductUom,
    ProductUomLabels,
} from 'src/app/_api_adb2c/purchase/purchase/enums/product-uom.enum';
import { DialogV2 } from 'src/app/components-v2/dialog-v2';
import { SelectInput } from 'src/app/components/Form/SelectInput';
import { TextInput } from 'src/app/components/Form/TextInput';

interface Props {
    open: boolean;
    data: ProductBillOfMaterialEntry;
    product: ProductModel;
    onClose: () => void;
}

const schema = z.object({
    material: z.string(),
    description: z.string().optional(),
    consumption: z.coerce.number(),
    unitOfMeasurement: z.string(),
    // weight: z.coerce.number(),
    name: z.string(),
    _id: z.string(),
    // wastage: z.coerce.number(),
    usageNature: z.string().optional(),
});

export const EditProductMaterial = ({
    open,
    data,
    onClose,
    product,
}: Props) => {
    const [searchParams] = useSearchParams();
    const delegateId = searchParams.get('delegateId') || '';

    const { data: materials } = useMaterials(delegateId);
    const { mutateAsync: update, isLoading } = useComposeProduct();

    const form = useForm<z.infer<typeof schema>>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            material: data.material._id,
            description: data.material.description,
            consumption: data.consumption,
            unitOfMeasurement: data.unitOfMeasurement,
            name: data.name,
            _id: data._id,
            usageNature: data.usageNature,
        },
    });

    const onSubmit = async (data: z.infer<typeof schema>) => {
        const versions = product.versions;
        const latestVersion = versions?.[versions.length - 1];

        if (!latestVersion) {
            toast.error('Product version not found');
            return;
        }

        const updatedCompositions: ProductBillOfMaterialDataObject[] = (
            latestVersion.billOfMaterials || []
        )?.map((x) => {
            const isEdited = x._id === data._id;
            const material = materials?.data?.find(
                (y) => y._id === data.material
            );

            return {
                materialCost: isEdited
                    ? material?.unitCost || 0
                    : x.materialCost || 0,
                source: x.source,
                unitOfMeasurement: isEdited
                    ? data.unitOfMeasurement
                    : x.unitOfMeasurement,
                consumption: isEdited ? data.consumption : x.consumption,
                resource: isEdited ? data.material : x.resource,
                name: isEdited ? material?.name || '' : x.name,
                _id: x._id,
                usageNature: isEdited ? data.usageNature : x.usageNature,
            };
        });

        const costOfMaterials = updatedCompositions.reduce(
            (acc, curr) => acc + (curr.materialCost || 0),
            0
        );

        const versionRequest: ProductVersionCreateDataObject = {
            version: (versions?.length || 0) + 1,
            billOfMaterials: updatedCompositions,
            costOfMaterials: costOfMaterials,
            specifications: {
                height: latestVersion?.specifications?.height || 0,
                width: latestVersion?.specifications?.width || 0,
                weight: latestVersion?.specifications?.weight || 0,
                depth: latestVersion?.specifications?.depth || 0,
            },
            releasedOn: new Date(),
        };

        const request: ProductUpdateDataObject = {
            versions: [versionRequest],
            name: product.name,
        };

        await update({
            id: product._id,
            body: request,
        });

        reset();
    };

    const reset = () => {
        onClose();
        form.reset();
    };

    return (
        <DialogV2
            open={open}
            title='Edit Product Material'
            onClose={reset}
            isLoading={isLoading}
            form={form}
            onSubmit={onSubmit}
            steps={[
                {
                    title: 'Edit Material',
                    description: 'Edit the material details',
                    content: (
                        <>
                            <SelectInput
                                name='material'
                                label='Material'
                                placeholder='Material'
                                options={(materials?.data || []).map((x) => ({
                                    label: x.name,
                                    value: x._id,
                                }))}
                            ></SelectInput>

                            <TextInput
                                inputName='description'
                                label='Description'
                                placeholder='Description'
                            ></TextInput>

                            <SelectInput
                                name='unitOfMeasurement'
                                label='Unit of Measurement'
                                placeholder='Unit of Measurement'
                                options={Object.values(ProductUom).map((x) => ({
                                    label: ProductUomLabels[x],
                                    value: x,
                                }))}
                            ></SelectInput>

                            <TextInput
                                inputName='consumption'
                                label='Consumption'
                                placeholder='Consumption'
                                type='number'
                            ></TextInput>

                            <SelectInput
                                name='usageNature'
                                label='Usage Nature'
                                options={Object.values(UsageNature).map(
                                    (x) => ({
                                        label: UsageNatureLabels[x],
                                        value: x,
                                    })
                                )}
                            />
                        </>
                    ),
                },
            ]}
            isStepDialog
        ></DialogV2>
    );
};
