import { ServiceConfigInterface } from 'src/domain/interfaces/config.context.interface';
import { DocumentCreateDataObject } from './document-create-data-object';
import { DocumentUpdateDataObject } from './document-update-data-object';
import { DocumentApiClient } from './document.client';
import { VerificationRequestUpdateDataObject } from './verification-request-update-data-object';
import { DocumentModel } from './document.model';
import { WorkspaceLibraryModel } from '../workspace/workspace.model';

export class DocumentService {
    private server: DocumentApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new DocumentApiClient(config, token);
    }

    create(
        workspace: string,
        document: DocumentCreateDataObject
    ): Promise<DocumentModel> {
        console.log(`service<company-doc>| create(): Enter`);
        console.log(
            `service<company-doc>| create(): $workspace = ${workspace}`
        );
        console.log(
            `service<company-doc>| create(): $document = ${JSON.stringify(
                document
            )}`
        );

        return this.server.create(workspace, document);
    }

    list(workspace: string): Promise<WorkspaceLibraryModel> {
        console.log(`service<company-doc>| list(): Enter`);
        console.log(`service<company-doc>| list(): $workspace = ${workspace}`);

        return this.server.list(workspace);
    }

    get(workspace: string, item: string): Promise<DocumentModel> {
        console.log(`service<company-doc>| get(): Enter`);
        console.log(`service<company-doc>| get(): $workspace = ${workspace}`);
        console.log(`service<company-doc>| get(): $item = ${item}`);

        return this.server.get(workspace, item);
    }

    update(
        workspace: string,
        item: string,
        document: DocumentUpdateDataObject
    ): Promise<DocumentModel> {
        console.log(`service<company-doc>| update(): Enter`);
        console.log(
            `service<company-doc>| update(): $workspace = ${workspace}`
        );
        console.log(`service<company-doc>| update(): $item = ${item}`);
        console.log(
            `service<company-doc>| update(): $document = ${JSON.stringify(
                document
            )}`
        );

        return this.server.update(workspace, item, document);
    }

    remove(workspace: string, item: string) {
        console.log(`service<company-doc>| remove(): Enter`);
        console.log(
            `service<company-doc>| remove(): $workspace = ${workspace}`
        );
        console.log(`service<company-doc>| remove(): $item = ${item}`);

        return this.server.remove(workspace, item);
    }

    extract(workspace: string, item: string) {
        console.log(`service<company-doc>| extract(): Enter`);
        console.log(
            `service<company-doc>| extract(): $workspace = ${workspace}`
        );
        console.log(`service<company-doc>| extract(): $item = ${item}`);

        return this.server.extract(workspace, item);
    }

    getPartnerDocuments(
        workspace: string,
        partner: string
    ): Promise<DocumentModel[]> {
        console.log(`service<company-doc>| getPartnerDocuments(): Enter`);
        console.log(
            `service<company-doc>| getPartnerDocuments(): $workspace = ${workspace}`
        );
        console.log(
            `service<company-doc>| getPartnerDocuments(): $partner = ${partner}`
        );

        return this.server.getPartnerDocuments(workspace, partner);
    }

    rejectVerificationRequest(workspace: string, item: string) {
        console.log(`service<company-doc>| rejectVerificationRequest(): Enter`);
        console.log(
            `service<company-doc>| rejectVerificationRequest(): $workspace = ${workspace}`
        );
        console.log(
            `service<company-doc>| rejectVerificationRequest(): $item = ${item}`
        );

        return this.server.rejectVerificationRequest(workspace, item);
    }

    approveVerificationRequest(
        workspace: string,
        item: string,
        body: VerificationRequestUpdateDataObject
    ) {
        console.log(
            `service<company-doc>| approveVerificationRequest(): Enter`
        );
        console.log(
            `service<company-doc>| approveVerificationRequest(): $workspace = ${workspace}`
        );
        console.log(
            `service<company-doc>| approveVerificationRequest(): $item = ${item}`
        );

        return this.server.verify(workspace, item, body);
    }
}
